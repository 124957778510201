import { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { StandardLayout } from 'components/StandardLayout'
import { Card } from 'components/basic/Card'
import { Routes } from 'routes'
import { useSession } from 'hooks/useSession'
import { StaticRouter } from 'hooks/useStaticRouter'

type ShortUrlProps = {
  slug: string
}

const SHORT_LINK_QUERY = gql`
  query GetShortLink($where: QueryShortLinkWhereInput!) {
    shortLink(where: $where) {
      slug
      redirect
      expires
      authorization {
        token
      }
    }
  }
`

export const ShortUrl = ({ slug }: ShortUrlProps) => {
  const { token, setToken } = useSession()
  const [ url, setUrl ] = useState<string | null | undefined>(undefined)

  const { loading, error, data } = useQuery(SHORT_LINK_QUERY, {
    variables: {
      where: {
        slug: slug
      }
    },
  })

  useEffect(() => {
    if (!data?.shortLink) return
    setToken(data.shortLink.authorization?.token || null)
  }, [data])

  // Ensures token is set before changing url
  // this really shouldn't matter, but ApolloLink
  // is being janky
  useEffect(() => {
    if (token === undefined) return
    if (loading) return

    let redirectUrl = data?.shortLink?.redirect

    if (!redirectUrl) {
      setUrl(null)
      return
    }

    if (window.location.hostname === 'localhost') {
      redirectUrl = data.shortLink.redirect.replace('https://m.cretesuite.com', window.location.origin)
      // TODO: remove
      redirectUrl = redirectUrl.replace('https://m.sgcpfl.com', window.location.origin)
    }

    if (window.location.hostname === 'm.cretesuite.com') {
      redirectUrl = redirectUrl.replace('https://m.sgcpfl.com', window.location.origin)
    }

    const isLocal = redirectUrl.startsWith(window.location.origin)

    if (!isLocal) {
      window.location.replace(redirectUrl)
      return
    }

    setUrl(redirectUrl)
  }, [loading, data, token])

  if (error) {
    console.log(error.message)

    return <StandardLayout>
      <Card>Sorry, an error occurred. Please refresh the page and try again.</Card>
    </StandardLayout>
  }

  if (loading || url === undefined) {
    return <StandardLayout>
      <Card>Loading...</Card>
    </StandardLayout>
  }

  if (url === null) {
    return <StandardLayout>
      <Card>Sorry, that link is not valid. Please contact us if you need assistance.</Card>
    </StandardLayout>
  }

  return <StaticRouter state={{url, setUrl}}>
    <Routes />
  </StaticRouter>
}
