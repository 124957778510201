import React, { useEffect } from 'react'
import { useOrganization } from 'hooks/useOrganization'

export const Theme: React.FC<{}> = ({ children }) => {
  const organization = useOrganization()
  const primaryColor = organization?.primaryColor || '#A41D36'
  const logo = organization?.logo
  const name = organization?.name

  useEffect(() => {
    document.body.style.setProperty('--vulcan-primary-color', primaryColor)
  }, [primaryColor])

  useEffect(() => {
    document.title = name || 'Order Information'
  }, [name])

  useEffect(() => {
    if (!logo) return

    let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");

    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }

    link.href = logo.replace(/\.[^/.]+$/, '.png')
  }, [logo])

  return <>
    {children}
  </>
}
