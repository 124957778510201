export const formatPhone = (value?: string | number | null, previousValue: string | number | null = null) => {
  // return nothing if no value
  if (!value) return value; 
  const valueStr = value.toString()
  const previousValueStr = previousValue === null ? null : previousValue.toString()

  // only allows 0-9 inputs
  const currentValue = valueStr.replace(/[^\d]/g, '');
  const cvLength = currentValue.length; 

  if (!previousValueStr || valueStr.length > previousValueStr.length) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue; 

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`; 

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`; 
  }
}
