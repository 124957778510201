import { gql } from '@apollo/client'

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
    id
    street
    street2
    city
    state
    zip
    lat
    lng
    timezone
  }
`
