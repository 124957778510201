import React from 'react'
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import { setContext } from '@apollo/client/link/context'
import { Token } from 'hooks/useSession'

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: Token ? `Bearer ${Token}` : "",
        }
      }
    }),
    new RetryLink({
      delay: {
        initial: 50,
        max: 2000,
        jitter: true
      },
      attempts: {
        max: 5,
        retryIf: (error, _operation) => !!error
      }
    }),
    new HttpLink({
      uri: process.env['REACT_APP_CRETESUITE_API'] || 'https://api.cretesuite.com',
    }),
  ]),
})
