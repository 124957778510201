import React from 'react'
import {
  RouteComponentProps,
  Switch,
  Route
} from 'react-router-dom'

import { ShortUrl } from 'pages/ShortUrl'

import * as analytics from 'helpers/analytics'
import { NotFoundPage } from 'pages/NotFoundPage'
import { OrderPage } from 'pages/OrderPage'
import { OrderChangeRequestPage } from 'pages/OrderChangeRequestPage'

const renderWithParams = (Component: React.FunctionComponent<any>) => (
  (props: RouteComponentProps<any>): React.ReactNode => {
    analytics.trackPageView({
      page_title: Component.name,
      page_location: `${window.location.origin}${props.location.pathname}`,
      page_path: props.location.pathname
    })
    return <Component {...props.match.params} />
  }
)

export const Routes = () => (
  <Switch>
    <Route path="/orders/:orderId/change" render={renderWithParams(OrderChangeRequestPage)} />
    <Route path="/orders/:orderId" render={renderWithParams(OrderPage)} />
    <Route path="/:slug" render={ (props) => <ShortUrl  {...props.match.params} /> } />
    <Route component={NotFoundPage} />
  </Switch>
)
