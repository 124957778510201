const ORGANIZATIONS = {
  grizzly: 424,
}

type ORGANIZATIONS_KEYS = keyof typeof ORGANIZATIONS
type ORGANIZATIONS_VALUES = typeof ORGANIZATIONS[ORGANIZATIONS_KEYS]

export const organizationsHideOperatorInfo: Record< ORGANIZATIONS_VALUES, boolean> =
  {
    [ORGANIZATIONS.grizzly]: true,
  }
