import React, { useState, useMemo, useEffect, Suspense, useRef } from 'react'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import { faCheck, faEdit, faCheckCircle, faHourglassHalf, faPhone, faComment, faStar, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Loading, useLoading } from 'contexts/Loading'
import { useQuery, useMutation, gql } from '@apollo/client'
import { ADDRESS_FIELDS } from 'gql/addresses'
import { StandardLayout } from 'components/StandardLayout'
import { useErrors } from 'hooks/useErrors'
import { Card } from 'components/basic/Card'
import { Button, PrimaryButton, SecondaryButton, TertiaryButton, ButtonIcon } from 'components/basic/Button'
import { ContactButtons } from 'components/contact/ContactButtons'
import { TermsOfServiceModal } from 'components/TermsOfServiceModal'

import {
  Row,
  Col,
  Collapse,
  Input,
  Label as RSLabel,
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as analytics from 'helpers/analytics'
import { OrderWhereUniqueInput, Query, OrderScheduleEntry, Order, RouteWaypointStatus, RouteWaypoint, InventoryItemVisibility } from 'schema'
import { timeWithZone } from 'helpers/datetime'
import { Link } from 'hooks/useStaticRouter'
import { useSession } from 'hooks/useSession'
import { formatPhone } from 'helpers/formatPhone'
import { useRelativeDateString } from 'hooks/useRelativeDateString'

import trackOrderIcon from 'assets/track-order.png'
import { generateSignature } from 'helpers/generateSignature'

import { AspectRatioBox } from 'components/AspectRatioBox'
import { shouldShowOperatorInfo } from 'helpers/operator'
import { useIsOrderChangeable } from 'hooks/useIsOrderChangeable'

const SiteLocationMap = React.lazy(
  () => import('components/mapping/SiteLocationMap').then(module => ({ default: module.SiteLocationMap }))
)
const Markdown = React.lazy(
  () => import('components/basic/Markdown').then(module => ({ default: module.Markdown }))
)

const Label = styled.label`
  font-family: Nunito Sans;
  font-weight: 600;
  font-size: 16px;
  color: #0C0C0C;
  letter-spacing: 0.46px;
  margin-bottom: 0;
`

const LabelColored = styled(Label)`
  color: var(--vulcan-primary-color);
  margin-bottom: 6px;
  font-weight: 700;
`

const Title = styled.h1`
  font-family: Nunito Sans;
  font-size: 16px;
  color: #010101;
  letter-spacing: 0.46px;
  line-height: 1.8;
`

const Site = styled.h1`
  font-family: Nunito Sans;
  font-size: 19px;
  color: #010101;
  letter-spacing: 0.46px;
  line-height: 1.4;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;
`

const DateSection = styled.h1`
  font-family: Nunito Sans;
  font-size: 19px;
  color: #010101;
  letter-spacing: 0.46px;
  line-height: 1.4;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0;
`

const ViewDetailsButton = styled(({ children, ...props}) => {
  return <div {...props}><span className="viewDetailsLink"><span className="viewDetailsInner">{children}</span></span></div>
})`
  text-align: center;

  > .viewDetailsLink {
    cursor: pointer;
    padding: 1px 7px;
    font-family: Nunito Sans;
    font-size: 14px;
    color: var(--vulcan-primary-color);
    letter-spacing: 0.46px;
    text-decoration: none;

    .viewDetailsInner {
      padding: 1px;
      border-bottom: 1px solid var(--vulcan-primary-color);
    }

    svg {
      font-size: 12px;
      vertical-align: baseline;
      margin-left: 0.2rem;
    }
  }
`

const PourDetails = styled(Collapse)`
  padding-top: 10px;
`

const Item = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ECECEC;
`

const ItemTitle = styled.div`
  font-family: Nunito Sans;
  font-size: 14px;
  color: var(--vulcan-primary-color);
  letter-spacing: 0.17px;
  padding-bottom: 4px;
  margin-bottom: 0;
  font-weight: 700;
`

const ItemSubtitle = styled.div`
  font-family: Nunito Sans;
  font-size: 16px;
  color: #010101;
  letter-spacing: 0.22px;
  padding-bottom: 0;
  margin-bottom: 0;
  font-weight: 500;
  white-space: pre-wrap;
`

const Cancelled = styled.span`
  color: #A41D36;
  font-weight: bold;
`

const ConfirmedWrap = styled((props: any) => (<Row> <Col xs="auto" {...props} /> </Row>))`
  margin: 0 auto;
  padding-top: 5px;
  padding-bottom: 25px;
`

const ConfirmedIcon = styled((props: any) => (
  <FontAwesomeIcon {...props} icon={faCheckCircle} />
))`
  font-size: 40px;
  line-height: 40px;
  color: #168a15;
  margin-right: 10px;
  margin-left: -3px;
`

const ConfirmedText = styled.span`
  padding-right: 3px;
  color: #168a15;
  font-size: 25px;
  line-height: 40px;
  font-weight: 800;
  vertical-align: top;
  text-transform: uppercase;
  text-align:justify;
`

const PendingInspectionIcon = styled((props: any) => (
  <FontAwesomeIcon {...props} icon={faHourglassHalf} />
))`
  font-size: 24px;
  line-height: 40px;
  color: #da6806;
  margin-right: 10px;
  margin-left: -3px;
`

const PendingInspectionText = styled.span`
  padding-right: 5px;
  color: #da6806;
  font-size: 24px;
  line-height: 25px;
  font-weight: 800;
  vertical-align: top;
  text-transform: uppercase;
  text-align: justify;
`
const OPERATOR_LINE_GUTTER = 5

const OperatorInfoSection = styled.div`
  margin-top: 25px;
  font-size: 15px;
  line-height: 1.5;

  .row {
    margin-right: -${OPERATOR_LINE_GUTTER}px;
    margin-left: -${OPERATOR_LINE_GUTTER}px;

    & > * {
      border-left: 1px solid #ECECEC;
      text-align: center;
      padding-left: ${OPERATOR_LINE_GUTTER}px;
      padding-right: ${OPERATOR_LINE_GUTTER}px;

      &:first-child {
        border-left: none;
        text-align: left;
      }

      a {
        color: inherit;
      }

      .fa-phone, .fa-comment {
        font-size: 12px;
        margin-left: 7px;
        color: #4a4a4a;
        vertical-align: baseline;
      }
    }
  }
`

const OperatorContactLink = styled.a`
  text-decoration: none !important;
  padding-bottom: 1px;

  &:hover, &:active, &:focus {
    border-bottom: 1px solid #4a4a4a;
  }
`

const LiveShareWrapper = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ECECEC;
  font-family: Nunito Sans;
  font-size: 16px;
  line-height: 1.8;
`

const TosError = styled.div.attrs((props) => ({
  ...props,
  children: (<>
    <FontAwesomeIcon icon={faExclamationTriangle} />
    { props.children }
  </>) as any,
}))`
  color: #e53838;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  padding: 20px 10px 7px;

  .fa-exclamation-triangle {
    font-size: 22px;
    margin-right: 10px;
  }
`

const TosItem = styled(Item).attrs((props) => ({
  error: false,
  ...props
}))`
  // position: relative;
  // left: -10px;
  margin: 0px -15px 0 -15px;
  padding: 10px 15px 10px 15px;
  border-radius: 6px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ error }) => error ? '#e53838' : 'transparent'}
`

const InlineBlock = styled.span`
  display: inline-block;
`

const SiteConfirmationCalloutWrap = styled.div`
  margin: 0 auto;
  padding: 8px 0;
  max-width: 90%;
  text-align: center;
  font-size: 0.9em;
  font-weight: bold;
`

const MapSizingWrapper = styled(AspectRatioBox)`
  @media (min-width: 470px) {
    padding-top: 325px !important;
  }
`

const onClickTracker = (props: object) => {
  return (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    analytics.trackClick(props)
    event.preventDefault()
    const href = (event.currentTarget as any).href
    if (href) {
      window.location.href=href
    }
  }
}

type LiveShareButtonProps = Parameters<typeof Button>[0] & {
  url: string
}

const LiveShareButton = styled((props: LiveShareButtonProps) => {
  const { url, ...args } = props

  return <a
    href={url}
    style={{ textDecoration: 'none' }}
    onClick={onClickTracker({ name: 'TrackPump' })}
  >
    <Button {...args} />
  </a>
})`
  background-color: var(--vulcan-primary-color);
  border: none !important;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  padding-top: 14px;
  padding-bottom: 14px;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 580px;

  &:hover, &:active, &:focus {
    background-color: var(--vulcan-primary-color) !important;
    opacity: 0.6;
  }

  img {
    width: 30px;
    margin-left: -15px;
    margin-right: 15px;
  }
`

type OrderPageProps = {
  orderId: string
}

const SET_ORDER_STATUS = gql`
  mutation SetOrderStatus($id: Int!, $revision: Int!, $status: String!) {
    updateOrder(
      data: {
        revision: $revision
        status: $status
      }
      where: {
        id: $id
      }
    ) {
      id
      status
      revision
      site {
        id
        verified
      }
    }
  }
`

const COLLECT_ORDER_SIGNATURE = gql`
  mutation CollectOrderSignature($data: CollectSignatureInput!, $id: Int!) {
    collectOrderSignature(
      data: $data
      where: {
        id: $id
      }
    ) {
      id
    }
  }
`

const prettyTime = (date: DateTime | string | null | undefined, timezone?: string | null) => {
  if (!date) return 'TBD'
  return (DateTime.isDateTime(date) ? date : timeWithZone(date, timezone))?.toFormat('h:mma')
}

const sortSchedule = (schedule: OrderScheduleEntry[] | null | undefined) => (
  [...(schedule || [])].sort((a, b) => {
    if (a.startTime == b.startTime) {
      return a.scheduleIndex < b.scheduleIndex ? -1 : 1
    }
    if (!b.startTime) return -1
    if (!a.startTime) return 1
    return a.startTime < b.startTime ? -1 : 1
  })
)

const LiveShareSection = ({ waypoint, text }: { text: string, waypoint?: RouteWaypoint }) => {
  const liveShareUrl = waypoint?.metadata?.find(({key}) => key === 'samsaraLiveSharingUrl')?.value
  if (!liveShareUrl) return null

  return <LiveShareWrapper>
    <FontAwesomeIcon icon={faStar} style={{ fontSize: '0.8em', verticalAlign: 'baseline' }} /> { text }
    <LiveShareButton url={liveShareUrl}>
      <img src={trackOrderIcon} />Track Your Equipment
    </LiveShareButton>
  </LiveShareWrapper>
}

export const OrderInfoSection = ({ order, minified, withCollapse }: { order: Order, minified?: boolean, withCollapse?: boolean }): JSX.Element => {
  const { user } = useSession()
  const organization = user?.organization
  const isApproximateTime = order.approximateTime

  const [ detailsCollapsed, setDetailsCollapsed ] = useState(withCollapse || false)
  const toggleDetailsCollapsed = () => { setDetailsCollapsed((last) => !last) }

  const isUnconfirmedStatus = () => ['backup', 'pending', 'inspection_pending'].includes(order?.status!)

  type detailsType = { title: string, subtitle?: React.ReactNode, minified?: boolean }
  let details: Array<detailsType> = []

  const planned = order.planned
  if (!planned) {
    return <Card>Order not found</Card>
  }

  const fieldContact = order.contacts.find((contact) => contact.role?.slug === 'field')?.contact

  const scheduleSorted = sortSchedule(planned.schedule)
  const getSteps = (step: string) => scheduleSorted.filter((entry) => entry.step === step)
  const getStep = (step: string) => getSteps(step)[0]

  const pours = getSteps('pour')
  const pourNames = pours.map((entry) => entry?.pour?.name).filter(Boolean)

  if (pourNames.length > 0) {
    details.push({
      title: pourNames.length > 1 ? 'Lots' : 'Lot',
      subtitle: pourNames.join(', '),
      minified: true
    })
  }

  const site = order?.site
  const siteTimezone = site?.address?.timezone

  const address = Object.assign({}, site?.address)
  if (address.lat === -42) {
    address.lat = undefined
  }
  if (address.lng === -42) {
    address.lng = undefined
  }

  details.push({
    minified: true,
    title: 'Address',
    subtitle: <>
      { address.street ? <>
        <InlineBlock>{ address.street }, </InlineBlock>
        <InlineBlock>{ [address.city ? `${address.city},` : undefined , address.state, address.zip].filter(Boolean).join(' ') }</InlineBlock>
      </> : 'Address Needed' }

    { isUnconfirmedStatus() && site && !site.verified && address.lat && address.lng &&
        <>
          <SiteConfirmationCalloutWrap>
            <InlineBlock>Please verify this is the approximate </InlineBlock>
            <InlineBlock>location of your job site.</InlineBlock>
          </SiteConfirmationCalloutWrap>

          <MapSizingWrapper ratio={1}>
            <Suspense fallback={ <Loading /> }>
              <SiteLocationMap coordinates={{ latitude: address.lat, longitude: address.lng }}  />
            </Suspense>
          </MapSizingWrapper>
        </>
      }
    </>
  })

  if (order.bulletinToCustomer) {
    details.push({
      title: 'Additional Details',
      subtitle: <Suspense fallback={ order.bulletinToCustomer }>
        <Markdown children={ order.bulletinToCustomer } />
      </Suspense>,
      minified: true
    })
  }

  if (!isApproximateTime) {
    details.push({ title: 'Onsite Time', subtitle: prettyTime(getStep('prep')?.startTime, getStep('prep')?.startTimezone || siteTimezone) })

    if (pours.length === 1) {
      details.push({ title: 'Pour Time', subtitle: prettyTime(pours?.[0]?.startTime, pours?.[0]?.startTimezone || siteTimezone) })
    } else {
      pours.forEach((pour, index) => {
        details.push({ title: `Pour #${index + 1} Time`, subtitle: prettyTime(pour?.startTime, pour?.startTimezone || siteTimezone) })
      })
    }
  }

  if (fieldContact && user && fieldContact.id !== user.id) {
    const contactPhone = (
      (fieldContact.phones || []).find((phone) => phone.type === 'primary') || fieldContact.phones[0]
    )?.number

    details.push({
      title: 'Field Contact',
      subtitle: [
        [fieldContact.firstName, fieldContact.lastName].filter(Boolean).join(' '),
        contactPhone ? formatPhone(contactPhone) : null
      ].filter(Boolean).join("\n"),
      minified: true
    })
  }

  const service = order.service?.name
  if (service) {
    details.push({ title: 'Equipment', subtitle: service })
  }

  const inventory = (planned.inventory || []).slice()
    .filter(({ item }) => (
      item.visibility.customerConfirmation !== InventoryItemVisibility['Hidden']
    ))
    .sort((a, b) => {
      if (a?.item?.category === 'system') return -1
      if (b?.item?.category === 'system') return -1
      return a.item?.name?.toLowerCase() < b.item?.name?.toLowerCase() ? -1 : 1
    })

  if (inventory.length > 0) {
    details.push({
      title: 'System',
      subtitle: inventory.map((orderItem) => (
        [
          orderItem.item.name,
          orderItem.quantity ?
            `${orderItem.quantity}${orderItem.item.displayUnit || 'x'}` :
            undefined
        ].filter(Boolean).join('  -  ')
      )).join("\n")
    })
  }

  details.push({ title: 'Order ID', subtitle: order.name, minified: true })

  const planned_start_pour = timeWithZone(pours[0]?.startTime, pours[0]?.startTimezone || siteTimezone)

  if (minified) {
    details = details.filter((detail) => detail.minified)
  }

  return <>
    <Site> {order.site?.name || 'No Site Yet'} </Site>
    <DateSection>
      {planned_start_pour?.toFormat('cccc')}, {planned_start_pour?.toFormat('MM/dd/yyyy')} <br />
      {!isApproximateTime && prettyTime(planned_start_pour?.toISO(), planned_start_pour?.zoneName)}
    </DateSection>

    <PourDetails isOpen={!detailsCollapsed}>
      {details.map((detail, index) => {
        return (
          <Item key={index}>
            <ItemTitle>{detail.title}</ItemTitle>
            <ItemSubtitle>{detail.subtitle}</ItemSubtitle>
          </Item>
        )
      })}

      { organization?.id === 14 &&
        <PKSHack order={order} />
      }
      {
        organization?.isDemo &&
          <DemoHack order={order} />
      }

    </PourDetails>

    {
      withCollapse &&
      <ViewDetailsButton onClick={ toggleDetailsCollapsed }>
        { detailsCollapsed ? 'Show' : 'Hide' } Pour Details
      </ViewDetailsButton>
    }
  </>
}

const DemoHack = ({ order }: { order: Order }) => {
  return <Item>
    <ItemTitle>Site Conditions</ItemTitle>
    <ItemSubtitle>
      <ul style={{ paddingLeft: 0, listStylePosition: 'inside' }}>
        <li>
          {order?.linesOnSite ? 'Confirmed minimum clearance from Power Lines' : 'No Power Lines'}
        </li>
        <li>
          Site provides minimum compressive strength of 145 psi
        </li>
      </ul>
    </ItemSubtitle>
  </Item>
}

const PKSHack = ({ order }: { order: Order }) => {
  const hasFlag = (slug: string) => (
    order?.tags?.find((tag) => (
      tag.path === '/orders/flags/' && tag.slug === slug
    ))
  )

  return <Item>
    <ItemTitle>Job Attributes</ItemTitle>
    <ItemSubtitle>
      <ul style={{ paddingLeft: 0, listStylePosition: 'inside' }}>
        <li>
          { hasFlag('poor_site') ? 'Site Survey Needed' : 'Pump setup area with firm ground conditions and good ingress/egress' }
        </li>
        <li>
          {hasFlag('calcium_2') ? '2% Accelerant' : hasFlag('calcium_1') ? '1% Accelerant' : 'No Accelerants' }
        </li>
        <li>
          { (hasFlag('lines_on_site') || order.linesOnSite) ? 'Power Lines on Site' : 'No Power Lines' }
        </li>
        { hasFlag('2500_mix') && <li>Customer assumes responsibility for all equipment damage and/or loss of concrete when using 2500 mix</li> }
      </ul>
    </ItemSubtitle>
  </Item>
}

export const OrderPage = (props: OrderPageProps) => {
  return <StandardLayout>
    <OrderPageWrapped {...props} />
  </StandardLayout>
}

const OrderPageWrapped = ({ orderId }: OrderPageProps) => {
  const { withLoading } = useLoading()
  const { showError } = useErrors()
  const { user } = useSession()
  const organization = user?.organization
  const id = parseInt(orderId, 10)

  const [ tosChecked, setTosChecked ] = useState<boolean | undefined>(undefined)
  const [ tosModal, setTosModal] = React.useState(false)
  const toggleTosModal = () => setTosModal(!tosModal)

  const pageTopRef = useRef<HTMLDivElement>(null)
  const scrollToTop = () => (
    pageTopRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  )

  const { loading, error, data } = useQuery<Query, OrderWhereUniqueInput>(ORDER_QUERY, {
    variables: { id }
  })

  const [setOrderStatus] = useMutation(SET_ORDER_STATUS)
  const [collectOrderSignature] = useMutation(COLLECT_ORDER_SIGNATURE, { refetchQueries: ["GetOrder"] })

  const order = data?.order
  const isApproximateTime = order?.approximateTime
  const termsNeedsAcceptance = order?.terms?.needsAcceptance
  const termsAccepted = order?.terms?.accepted
  const showTerms = termsNeedsAcceptance

  const termsQuery = useQuery<Query, OrderWhereUniqueInput>(ORDER_TERMS_QUERY, {
    skip: !(order && showTerms),
    variables: { id }
  })

  const terms = (termsQuery.data?.orders?.[0] || order)?.terms?.content || {
    title: 'Terms and Conditions'
  }

  useEffect(() => {
    if (termsAccepted) {
      setTosChecked(termsAccepted)
    }
  }, [termsAccepted])

  const waypointsSorted = useMemo(() => {
    return (order?.route?.waypoints || []).slice().sort((a, b) => {
      const aTime = a.scheduledArrivalTime || a.scheduledDepartureTime
      const bTime = b.scheduledArrivalTime || b.scheduledDepartureTime
      if (!aTime) return 1
      if (!bTime) return -1
      if (aTime === bTime) return 0
      return bTime < aTime ? 1 : -1
    })
  }, [order])

  const onSiteWaypoints = useMemo(() => {
    if (!order) return
    const waypointIndex = waypointsSorted.findIndex((waypoint) => waypoint.order?.id === order.id)
    if (waypointIndex === -1) return null

    const previousWaypoints = waypointsSorted.slice(0, waypointIndex).reverse()

    let startOffset = 0
    for (const waypoint of previousWaypoints) {
      if (waypoint?.order?.siteId && waypoint.order.siteId === order.site?.id) {
        startOffset++
      } else {
        break
      }
    }

    const nextWaypoints = waypointsSorted.slice(waypointIndex)

    let endOffset = 0
    for (const waypoint of nextWaypoints) {
      if (waypoint?.order?.siteId && waypoint.order.siteId === order.site?.id) {
        endOffset++
      } else {
        break
      }
    }

    return waypointsSorted.slice(waypointIndex - startOffset, waypointIndex + endOffset)
  }, [waypointsSorted])

  const firstOnSiteWaypoint = useMemo(() => {
    return onSiteWaypoints?.[0]
  }, [onSiteWaypoints])

  const isOnSite = useMemo(() => {
    const inProgress = onSiteWaypoints?.some((waypoint) => waypoint.status === RouteWaypointStatus.InProgress)
    if (inProgress) return true

    return (
      onSiteWaypoints?.some((waypoint) => waypoint.status === RouteWaypointStatus.Completed) &&
      onSiteWaypoints?.some((waypoint) => waypoint.status === RouteWaypointStatus.Scheduled)
    )
  }, [onSiteWaypoints])

  const status = useMemo(() => {
    if (order?.status === 'confirmed') {
      if (isOnSite) {
        return 'onsite'
      }
      if (firstOnSiteWaypoint?.status === 'enRoute') {
        return 'enRoute'
      }
      if (order?.route?.dispatched) {
        return 'dispatched'
      }
    }

    return order?.status
  }, [order])

  const isOrderChangeable = useIsOrderChangeable(order?.status)

  const orderInfoSection = useMemo(() => order ? <OrderInfoSection order={order} /> : null, [order])

  if (loading) {
    return <Card>Loading...</Card>
  }

  const mainlinePhone = order?.branch?.mainlinePhone

  const contactJSX = mainlinePhone ?
    <ContactButtons phone={mainlinePhone} message={order?.name ? `Regarding ${order.name}:\n\n`: undefined} />
  : null

  const ErrorMessage: React.FC<{}> = ({ children }) => <>
    <Card>
      <Title>{ children }</Title>
    </Card>
    { contactJSX }
  </>

  if (error) {
    const errorCodes = (error?.graphQLErrors || []).map((err) => err?.extensions?.code).filter(Boolean)
    const notAuthorized = errorCodes.includes('FORBIDDEN')

    return <ErrorMessage>
      { notAuthorized ? (
        'Sorry this link has expired. Please contact us to receive a new link.'
      ) : (
        'Sorry, an error occurred. Please refresh the page and try again.'
      )}
    </ErrorMessage>
  }

  const planned = order?.planned

  if (!order || !planned) {
    return <ErrorMessage>Order not found</ErrorMessage>
  }

  const confirmOrder = withLoading(async () => {
    if (showTerms && !tosChecked) {
      setTosChecked(false)
      return
    }

    analytics.trackClick({ name: 'ConfirmOrder' })
    try {
      let revision = order.revision

      if (showTerms) {
        const signature = await generateSignature(user)

        await collectOrderSignature({
          variables: {
            id: order.id,
            data: signature
          }
        })

        revision += 1
      }

      await setOrderStatus({
        variables: {
          id: order.id,
          status: 'confirmed',
          revision
        }
      })

      scrollToTop()
    } catch (err) {
      showError(`An error occured, please try again: ${err.message}`)
    }
  })

  const setInspectionPending = withLoading(async () => {
    analytics.trackClick({ name: 'AwaitingInspection' })
    try {
      await setOrderStatus({
        variables: {
          id: order.id,
          revision: order.revision,
          status: 'inspection_pending'
        }
      })

      scrollToTop()
    } catch (err) {
      showError(`An error occured, please try again: ${err.message}`)
    }
  })

  const termsError = showTerms && tosChecked === false && <TosError>
    Please accept the { terms.title } <br />above to Confirm your Order.
  </TosError>

  const termsJSX = showTerms && <RSLabel check style={{ display: 'block' }}>
    <TosItem error={tosChecked === false}>
      <ItemTitle>{ terms.title }</ItemTitle>
      <ItemSubtitle style={{ marginLeft: 20 }}>
        <Input type="checkbox" checked={tosChecked} onChange={() => setTosChecked(true) } />
        <span style={{ display: 'inline-block', paddingLeft: 5 }}>
          I have read and agree to the <a href="#" onClick={(evt) => {
            evt.preventDefault()
            toggleTosModal()
          }}>{ terms.title }</a>.
        </span>
      </ItemSubtitle>
    </TosItem>
  </RSLabel>

  const dayOfSection = () => {
    const operator = (order?.route?.assignments || []).slice().sort((a, b) => {
      if (a.role === 'lead') return -1
      if (b.role === 'lead') return 1
      return 0
    })[0]?.operator
    const operatorPhone = (operator?.phones || []).slice().sort((a, b) => {
      if (a.type === 'primary') return -1
      if (b.type === 'primary') return 1
      return 0
    })[0]?.number

    const showOperatorInfo = shouldShowOperatorInfo(organization?.id || 0)

    return <>
      <OrderInfoSection order={order} withCollapse />

      { operator &&
        <OperatorInfoSection>
          <LabelColored>Operator Info</LabelColored>
          <Row>
            <Col xs="6">
              {operator.firstName} {operator.lastName}
            </Col>
          {operatorPhone && showOperatorInfo && <>
              <Col xs="3">
                <OperatorContactLink
                  href={ `tel:${operatorPhone}` }
                  onClick={onClickTracker({ name: 'CallOperator' })}
                >
                  Call <FontAwesomeIcon icon={faPhone} />
                </OperatorContactLink>
              </Col>
              <Col xs="3">
                <OperatorContactLink
                  href={ `sms:${operatorPhone}` }
                  onClick={onClickTracker({ name: 'TextOperator' })}
                >
                  Text <FontAwesomeIcon icon={faComment} />
                </OperatorContactLink>
              </Col>
            </> }
          </Row>
        </OperatorInfoSection>
      }
    </>
  }

  const dispatchedJSX = () => {
    const siteTimezone = order?.site?.address?.timezone
    const scheduleSorted = sortSchedule(planned.schedule || [])
    const pours = scheduleSorted.filter((entry) => entry.step === 'pour')
    const timezone = pours[0]?.startTimezone || siteTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone
    const plannedArrival = timeWithZone(scheduleSorted[0]?.startTime, timezone)
    const plannedStartPour = timeWithZone(pours[0]?.startTime, timezone)
    const operator = (order?.route?.assignments || []).slice().sort((a, b) => {
      if (a.role === 'lead') return -1
      if (b.role === 'lead') return 1
      return 0
    })[0]?.operator

    return <>
      <Card>
        <LabelColored>Hi {user?.firstName}</LabelColored>
        <Title>
          { isApproximateTime ? <>
            Your order <RelativeDate date={plannedStartPour} /> has been confirmed. <br />If you have any questions or need assistance, please contact our team at the bottom of this page.
          </> : <>
            Your pour <RelativeDate date={plannedStartPour} /> is all set. Your operator will be { operator && `${operator.firstName} and they’ll be ` }on site at {prettyTime(plannedArrival)} ready for {prettyTime(plannedStartPour)} concrete.
          </>}
        </Title>

        { dayOfSection() }
        <LiveShareSection waypoint={firstOnSiteWaypoint} text="Click below to view your operator's route &amp; ETA." />
      </Card>
      { contactJSX}
    </>
  }

  const onsiteJSX = () => {
    const operator = (order?.route?.assignments || []).slice().sort((a, b) => {
      if (a.role === 'lead') return -1
      if (b.role === 'lead') return 1
      return 0
    })[0]?.operator

    return <>
      <Card>
        <LabelColored>Hi {user?.firstName}</LabelColored>

        <Title>
          { organization?.name || 'Your equipment'} is on site{operator?.firstName && ` and is being operated by ${operator.firstName}`}. If you need to reach {operator?.firstName ? 'them' : 'us'}, click Call or Text below.
        </Title>

        { dayOfSection() }

        <LiveShareSection waypoint={firstOnSiteWaypoint} text="Click below to view your operator's location on site." />
      </Card>
      { contactJSX}
    </>
  }

  const enRouteJSX = () => {
    const siteTimezone = order?.site?.address?.timezone
    const eta = firstOnSiteWaypoint?.estimatedArrivalTime ? timeWithZone(firstOnSiteWaypoint?.estimatedArrivalTime, siteTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone) : null
    const etaRounded = eta && eta.plus({ minutes: 5 - (eta.minute % 5) })

    const operator = (order?.route?.assignments || []).slice().sort((a, b) => {
      if (a.role === 'lead') return -1
      if (b.role === 'lead') return 1
      return 0
    })[0]?.operator

    return <>
      <Card>
        <LabelColored>Hi {user?.firstName}</LabelColored>

        <Title>
          { organization?.name || 'Your equipment'} is en route to you now{eta && <>
            , estimated arrival time is <strong>{etaRounded?.toLocaleString(DateTime.TIME_SIMPLE)}</strong>
          </>}. {operator && <>Your operator will be {operator.firstName}, if you need to reach them, just click Call or Text below.</>}
        </Title>

        { dayOfSection() }

        <LiveShareSection waypoint={firstOnSiteWaypoint} text="Click below to view your operator's current location." />
      </Card>
      { contactJSX}
    </>
  }


  const completedJSX = () => <>
    <Card>
      <LabelColored>Hi {user?.firstName}</LabelColored>
      <Title>The following pour has already been completed. If you have any questions or need assistance, please contact our team below.</Title>
      <OrderInfoSection order={order} />
    </Card>
    { contactJSX}
  </>

  const cancelledJSX = () => <>
    <Card>
      <LabelColored>Hi {user?.firstName}</LabelColored>
      <Title>The following pour has been <Cancelled>cancelled</Cancelled>. If you need assistance, please contact our team below.</Title>
      <OrderInfoSection order={order} minified />
    </Card>
    { contactJSX}
  </>

  return <>
    <div ref={pageTopRef} />
    { (status === 'backup' || status === 'pending') ? <>
      <Card>
        <LabelColored>Hi {user?.firstName}</LabelColored>
        <Title>Please confirm your upcoming pour { organization?.name && `with ${organization.name}`} </Title>
        { orderInfoSection }

        { termsJSX }
      </Card>

      { termsError }
      <PrimaryButton onClick={confirmOrder}><ButtonIcon icon={faCheck} />Confirm Order</PrimaryButton>
      <TertiaryButton onClick={setInspectionPending}><ButtonIcon icon={faHourglassHalf} />Waiting on Inspection</TertiaryButton>
    </> : status === 'inspection_pending' ? <>
      <Card>
        <ConfirmedWrap>
          <PendingInspectionIcon />
          <PendingInspectionText> Awaiting Inspection </PendingInspectionText>
        </ConfirmedWrap>

        <LabelColored>Hi {user?.firstName}</LabelColored>
        <Title><strong>This pour still needs to be confirmed.</strong> <br /> Please return to this page after the inspection is complete to confirm your order.</Title>
        { orderInfoSection }

        { termsJSX }
      </Card>

      { termsError }
      <PrimaryButton onClick={confirmOrder}><ButtonIcon icon={faCheck} />Confirm Order</PrimaryButton>
    </> : status === 'confirmed' ? <>
      <Card>
        <ConfirmedWrap>
          <ConfirmedIcon />
          <ConfirmedText> Order Confirmed </ConfirmedText>
        </ConfirmedWrap>

        <Title>
          Thanks {user?.firstName}, your pour is <strong>confirmed</strong>. If you have any questions or need assistance, please contact our team at the bottom of this page.
        </Title>
        { orderInfoSection }
      </Card>
      { contactJSX}
    </> : status === 'dispatched' ? <>
      { dispatchedJSX() }
    </> : status === 'onsite' ? <>
      { onsiteJSX() }
    </> : status === 'enRoute' ? <>
      { enRouteJSX() }
    </> : status === 'cancelled' ? <>
      { cancelledJSX() }
    </> : <>
      { completedJSX() }
    </>}

    { isOrderChangeable &&
      <Link to={`/orders/${order.id}/change`}>
        <SecondaryButton><ButtonIcon icon={faEdit} />Request Changes</SecondaryButton>
      </Link>
    }

    <TermsOfServiceModal
      fullscreen
      terms={terms}
      isOpen={tosModal}
      toggle={toggleTosModal}
    />
  </>
}

const RelativeDate = ({ date }: {date: DateTime | undefined}) => {
  if (!date) return null
  const relative = useRelativeDateString(date)
  return <>for {relative}</>
}

const ORDER_TERMS_QUERY = gql`
  query GetOrderTermsFull(
    $id: Int!
  ) {
    orders(
      where: {
        id: {
          equals: $id
        }
      }
    ) {
      terms {
        id
        accepted
        needsAcceptance
        content {
          id
          title
          body
        }
      }
    }
  }
`

export const ORDER_QUERY = gql`
  query GetOrder(
    $id: Int!
  ) {
    order(id: $id) {
      id
      revision
      status
      bulletinToCustomer
      linesOnSite
      approximateTime
      name
      branch {
        id
        name
        mainlinePhone
      }
      service {
        id
        name
      }
      planned {
        equipment {
          id
          name
          displayName
        }
        inventory {
          quantity
          itemId
          item {
            name
            category
            displayUnit
            visibility {
              customerConfirmation
            }
          }
        }
        schedule {
          scheduleIndex
          startTime
          startTimezone
          endTime
          endTimezone
          duration
          address {
            ...AddressFields
          }
          pour {
            name
            type {
              name
            }
          }
          step
          metrics {
            key
            value
          }
        }
      }
      tags {
        path
        slug
        name
      }
      contacts {
        id
        contact {
          firstName
          lastName
          phones {
            number
          }
        }
        role {
          name
          slug
        }
      }
      site {
        id
        name
        verified
        address {
          ...AddressFields
        }
      }
      terms {
        id
        accepted
        needsAcceptance
        content {
          id
          title
        }
      }
      route {
        id
        dispatched
        assignments {
          id
          role
          operator {
            id
            firstName
            lastName
            phones {
              id
              number
              type
            }
          }
        }
        waypoints {
          id
          status
          estimatedArrivalTime
          scheduledArrivalTime
          scheduledDepartureTime
          metadata {
            key
            value
          }
          order {
            id
            siteId
          }
        }
      }
    }
  }

  ${ADDRESS_FIELDS}
`
