import React from 'react'
import { 
  Col,
  Row,
  RowProps,
} from 'reactstrap'

export const StandardLayout: React.FC<RowProps> = ({ children, className, ...rowProps }) => (
  <Row className={"justify-content-center " + className} {...rowProps}>
    <Col xs={15} sm={12} md={9}>
      { children }
    </Col>
  </Row>
)
