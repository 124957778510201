import styled from 'styled-components'
import { StandardLayout } from 'components/StandardLayout'
import { 
  Col,
  Row,
} from 'reactstrap'
import { useSession } from 'hooks/useSession'

const HeaderLayout = styled(StandardLayout)`
  margin-top: 10px;
  margin-bottom: 10px;
`

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  padding: 10px 0;
  max-width: 300px;
  min-height: 60px;
  max-height: 120px;
`

const OGLOGO = 'http://res.cloudinary.com/vulcan/image/upload/v1641412826/logos/dic2u9eeeqnplq5haevh.svg'

export const Header = () => {
  const { user } = useSession()

  const logo = user?.organization?.id === 1 ? OGLOGO : user?.organization?.logo

  return <HeaderLayout>
    <Row className="align-items-center">
      <Col xs={ 12 } style={{ minHeight: '60px' }}>
        { logo &&
          <Logo src={logo} alt={ user?.organization?.name }/>
        }
      </Col>
    </Row>
  </HeaderLayout>
}
