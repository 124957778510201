import React, {
  useState, useContext
} from 'react'
import {
  StaticRouter as ReactStaticRouter,
  Link as ReactLink, 
  Redirect as ReactRedirect, 
  LinkProps
} from 'react-router-dom'

type UrlType = string | null | undefined

export interface StaticRouterState {
  url: UrlType
  setUrl: (_value: React.SetStateAction<UrlType>) => void
}

const initialContext: StaticRouterState = {
  url: undefined,
  setUrl: (_) => {},
}

export const StaticRouterContext = React.createContext(initialContext)

export const useStaticRouter = () => useContext(StaticRouterContext)

export interface StaticRouterProviderProps {
  state: StaticRouterState
  children?: React.ReactNode
}

export const Link: typeof ReactLink = (props) => {
  const router = useStaticRouter()

  const { onClick, ...rest } = props

  const newOnClick: LinkProps['onClick'] = (evt) => {
    router.setUrl(props.to as string)
  }

  return <ReactLink {...rest} onClick={newOnClick} />
}

export const Redirect = ({ to }: {to: string }) => {
  const router = useStaticRouter()
  router.setUrl(to)
  return null 
}

export const StaticRouter: React.FC<{ state: StaticRouterState}> = ({ state, children }) => {
  const path = state.url?.replace(window.location.origin, '')

  return (
    <StaticRouterContext.Provider value={state}>
      <React.Fragment key={path}>
        <ReactStaticRouter location={path}>
          { children }
        </ReactStaticRouter>
      </React.Fragment>
    </StaticRouterContext.Provider>
  )
}
