import React from 'react'
import {
  Col, Row, Spinner, SpinnerProps,
} from 'reactstrap'
import styled from 'styled-components'

const Title = styled.label`
  font-family: Nunito Sans;
  font-weight: 600;
  font-size: 18px;
  color: #010101;
  letter-spacing: 0;
  text-align: center;
  display: block;
  margin-top: 10px;
`

type LoaderProps = SpinnerProps & {
  title?: string,
}

export const Loader = (props: LoaderProps = {
  type: 'grow',
  size: 'lg',
  color: 'primary',
}) => (
  <Row style={{ textAlign: 'center', margin: 'auto', justifyContent: 'center' }} className="flex-center py-5">
    <Col xs="auto">
      <Spinner {...props} />
      {props.title && <Title>{props.title}</Title>}
    </Col>
  </Row>
)

const CenteredLoaderWrap = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const CenteredLoaderPositioner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const CenteredLoader = (props: SpinnerProps = {
  type: 'grow',
  size: 'lg',
  color: 'primary',
}) => (
  <CenteredLoaderWrap>
    <CenteredLoaderPositioner>
      <Spinner {...props} />
    </CenteredLoaderPositioner>
  </CenteredLoaderWrap>
)

export default Loader
