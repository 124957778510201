import { useEffect, useState } from "react";
import { DateTime } from "luxon";

export const useRelativeDateString = (date: DateTime | undefined, format: string = 'cccc, MM/dd') => {
  const generateDateLabel = () => {
    if (!date) return null

    const now = DateTime.local().setZone(date.zone)

    if (now.toISODate() === date?.toISODate()) {
      return 'today'
    }
    if (now.plus({ day: 1 }).toISODate() === date?.toISODate()) {
      return 'tomorrow'
    }
    return date.toFormat(format)
  }

  const [ dateLabel, setDateLabel ] = useState(generateDateLabel())

  useEffect(() => {
    if (!date) return

    const now = DateTime.local().setZone(date.zone)
    const msTillNextDay = now.endOf('day').diffNow('milliseconds').milliseconds + 100

    let interval: number | null;
    const timeout = setTimeout(() => {
      setDateLabel(generateDateLabel())
      interval = window.setInterval(() => {
        setDateLabel(generateDateLabel())
      }, 24 * 60 * 60 * 1000)
    }, msTillNextDay)

    return () => {
      if (timeout) clearTimeout(timeout)
      if (interval) clearInterval(interval)
    }
  }, [date])

  return dateLabel
}
