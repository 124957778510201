import React from 'react'
import styled from 'styled-components'

export interface AspectRatioBoxProps {
  ratio: number
  style?: React.CSSProperties
  className?: string;
}

const Wrapper = styled.div`
  height: 0;
  overflow: hidden;
  position: relative;
`

const Body = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:empty {
    display: none;
  }
`

export const AspectRatioBox: React.FC<AspectRatioBoxProps> = ({ className, ratio, style, children}) => (
  <Wrapper className={className} style={{ ...style, paddingTop: `${ratio * 100}%`, minWidth: '100%' }}>
    <Body>
      {children}
    </Body>
  </Wrapper>
)
