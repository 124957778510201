
import styled from 'styled-components'

export const Card = styled.div`
  background: white;
  box-shadow: 0 0 55px 0 rgba(0,0,0,0.05);
  border-radius: 16px;
  padding: 30px 25px;
  margin-bottom: 5px;
`

export default Card
