import { Terms } from "schema";
import { PrimaryButton } from "./basic/Button";
import { Modal, ModalProps, ModalHeader, ModalBody } from "./Modal";

type TermsOfServiceModalProps = Omit<ModalProps, 'children'> & { terms: Partial<Terms> }

export const TermsOfServiceModal = (props: TermsOfServiceModalProps) => {
  const { terms, ...modalProps } = props

  return <Modal
    {...modalProps}
  >
    <ModalHeader toggle={props.toggle}>
      { terms.title }
    </ModalHeader>
    <ModalBody
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div style={{ flex: '1 1 auto', overflow: 'scroll' }}>
        { terms.body }
      </div>
      <div style={{ flex: '0 0 auto', minHeight: 130}}>
        <PrimaryButton onClick={ props.toggle }>Close</PrimaryButton>
      </div>
    </ModalBody>
  </Modal>
}
