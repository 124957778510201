
import { ComponentProps } from 'react'
import { Modal as RSModal } from 'reactstrap'
import styled from 'styled-components'

export { ModalHeader, ModalBody } from 'reactstrap'

const StyledModal = styled(RSModal)`
  &.modal-dialog.fullscreen {
    padding: 0;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .modal-content {
      height: 100%;
    }
  }
`

export interface ModalProps extends ComponentProps<typeof RSModal> {
  fullscreen?: boolean
}

export const Modal = (props: ModalProps) => {
  return <StyledModal
    {...props}
    className={[ props.className, props.fullscreen && 'fullscreen' ].filter(Boolean).join(' ')}
  />
}
