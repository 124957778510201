import { useOrganization } from './useOrganization'

type ValueOf<ObjectType, ValueType extends keyof ObjectType = keyof ObjectType> = ObjectType[ValueType];

const TENANTS = {
  western: 687,
  hillcrest: 852,
}

const DEFAULT_FEATURES = {
  'order.allowChangeRequestIfConfirmed': false,
  'support.hideSMS': false,
} as const

type Features = Partial<Record<keyof typeof DEFAULT_FEATURES, boolean>>
type Feature = keyof Features

type TENANT_ID = ValueOf<typeof TENANTS>

const TENANT_FEATURES: Record<TENANT_ID, Features | undefined> = {
  [TENANTS.hillcrest]: {
    'order.allowChangeRequestIfConfirmed': true,
  },
  [TENANTS.western]: {
    'support.hideSMS': true,
  },
}

type FeatureMetadata = {
  loading: boolean
}

export function useFeatures<
  T extends keyof Features,
  R extends { [K in T]: boolean }
>(
  ...names: T[]
): [R, FeatureMetadata] {
  const organization = useOrganization()

  const features = Object.fromEntries(names.map((k) => [k, false])) as R

  const assignFeatures = (toSet: Features) => {
    Object.assign(features, toSet)
  }

  assignFeatures(DEFAULT_FEATURES)

  const tenantFeatures = TENANT_FEATURES[organization?.id || -1]
  if (tenantFeatures) {
    assignFeatures(tenantFeatures)
  }

  return [features, { loading: !organization }]
}

export function useFeature(name: Feature): [boolean, FeatureMetadata] {
  const [features, metadata] = useFeatures(name)
  return [features[name], metadata]
}
