import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import { DateTime } from 'luxon';

const cookies = new Cookies();

const COOKIE_NAME = 'cs_did'

export const getDeviceId = (): string => {
  const found = cookies.get(COOKIE_NAME)
  if (found) return found
  const generated = uuidv4()
  // 32bit max expiry
  const expires = DateTime.fromSeconds(2147483647).toJSDate()
  cookies.set(COOKIE_NAME, generated, { expires })
  return generated
}
