import React, {
  FunctionComponent, useState, useContext, useRef, useEffect,
} from 'react'
import { CenteredLoader } from 'components/Loader'
import styled from 'styled-components'

const LoadingWrap = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 99999;
`

const ContentWrap = styled.div<{ loading?: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;

  ${(props) => props.loading && `
    pointer-events: none;
 `}
`

export function Loading() {
  return (
    <LoadingWrap>
      <CenteredLoader />
    </LoadingWrap>
  )
}

interface LoadingMap {
  [id: string]: boolean
}

interface LoadingState {
  loadingMap?: LoadingMap
  isLoading: boolean
  showLoading: (id: string) => void
  hideLoading: (id: string) => void
}

export const LoadingContext: React.Context<LoadingState> = React.createContext({
  loadingMap: {},
  isLoading: false,
  showLoading: (_) => {},
  hideLoading: (_) => {},
} as LoadingState)

export const LoadingConsumer = LoadingContext.Consumer

export const LoadingProvider: FunctionComponent = (props) => {
  const [loading, setLoading] = useState<LoadingMap>({})

  const showLoading = (id: string) => {
    setLoading((previousLoading: LoadingMap) => {
      if (previousLoading[id] === true) {
        return previousLoading
      }
      // copy to new object
      const newLoading: LoadingMap = { ...previousLoading }
      newLoading[id] = true
      return newLoading
    })
  }

  const hideLoading = (id: string) => {
    setLoading((previousLoading: LoadingMap) => {
      if (previousLoading[id] !== true) {
        return previousLoading
      }
      // copy to new object
      const newLoading: LoadingMap = { ...previousLoading }
      delete newLoading[id]
      return newLoading
    })
  }

  const state: LoadingState = {
    loadingMap: loading,
    hideLoading,
    showLoading,
    isLoading: Object.values(loading).some((v: boolean) => v),
  }

  return (
    <LoadingContext.Provider value={state}>
      <ContentWrap loading={ state.isLoading }>{ props.children }</ContentWrap>
      {state.isLoading && <Loading />}
    </LoadingContext.Provider>
  )
}

export function useLoading() {
  const componentId = useRef(Math.random().toString(20).substring(2))
  const loadingContext = useContext(LoadingContext)

  const showLoading = () => {
    loadingContext.showLoading(componentId.current)
  }

  const hideLoading = () => {
    loadingContext.hideLoading(componentId.current)
  }

  const withLoading = <T extends Array<any>, U>(fn: (...args: T) => Promise<U>) => {
    return async (...args: T): Promise<U> => {
      showLoading()
      const toReturn = await fn(...args)
      hideLoading()
      return toReturn
    }
  }

  useEffect(() => hideLoading, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { showLoading, hideLoading, withLoading }
}
