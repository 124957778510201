import React, {
  ReactChild, useState, useContext, useEffect,
} from 'react'
import { gql, useLazyQuery } from '@apollo/client';
import { Person, Query } from 'schema';
import { toast } from 'react-toastify';
import * as analytics from 'helpers/analytics';

export let Token: string | null = null;

export interface Session {
  token?: String | null
  setToken: (value: React.SetStateAction<string | null>) => void
  user?: Person
}

const initialContext: Session = {
  setToken: (_value: React.SetStateAction<string | null>) => {},
}

export const SessionContext = React.createContext(initialContext)

export const useSession = () => useContext(SessionContext)

export interface SessionProviderProps {
  children?: ReactChild
}

const CURRENT_USER = gql`
  {
    me {
      id
      firstName
      lastName
      organization {
        id
        name
        logo
        primaryColor
        isDemo
        phones {
          number
        }
      }
    }
  }
`

export const SessionProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState(Token)
  const [loadUser, { data: userQueryResponse }] = useLazyQuery<Query>(CURRENT_USER)
  const user = userQueryResponse?.me || undefined

  Token = token

  useEffect(() => {
    if (token) {
      try {
        loadUser()
      } catch(err: any) {
        toast.error(`ERROR Authenticating: ${err.message}`)
        setToken(null)
      }
    }
  }, [token])

  useEffect(() => {
    if (!user) return
    analytics.trackUser(`${user.id}`)
  }, [user])

  const session: Session = { token, setToken, user }

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  )
}
