import { DateTime } from "luxon"
import { Person, CollectSignatureInput } from "schema"
import { getDeviceId } from "./getDeviceId"

export const generateSignature = async (person?: Partial<Person>): Promise<CollectSignatureInput> => {
  const ip = await getIp()
  const deviceId = getDeviceId()
  
  return {
    image: await generateAttestationImage({ person, ip, deviceId }),
    personId: person?.id,
    metadata: {
      create: [{
        key: 'deviceId',
        value: deviceId
      }, {
        key: 'ip',
        value: ip
      }]
    }
  }
}

interface GenerateAttestationImageProps {
  person: Partial<Person> | undefined
  ip: string | null
  deviceId: string
}

const generateAttestationImage = async (props: GenerateAttestationImageProps): Promise<string> => {
  const { person, ip, deviceId } = props

  const canvas = document.createElement('canvas')
  canvas.height = 110
  canvas.width = 500

  const ctx = canvas.getContext('2d')
  if (!ctx) return ''

  const name = [  person?.firstName, person?.lastName ].filter(Boolean).join(' ')
  const id = person?.id ? `#${person.id}` : null
  const padding = 15
  const titleHeight = 18
  const subtitleHeight = 10
  const maxWidth = canvas.width - (padding * 2)

  ctx.textBaseline = 'top'
  ctx.font = `${titleHeight}px sans-serif`
  ctx.fillText(`Digital attestation by ${name || id}`, padding, padding, maxWidth)

  const ua = window.navigator.userAgent.split(' ')
  const ua1 = ua.slice(0, Math.ceil(ua.length / 2)).join(' ')
  const ua2 = ua.slice(Math.ceil(ua.length / 2)).join(' ')

  const subtitles = [
    [DateTime.now().toISO(), ip, id].filter(Boolean).join('   |   '),
    deviceId,
    ua1,
    ua2
  ]

  ctx.font = `${subtitleHeight}px sans-serif`
  subtitles.forEach((text, line) => {
    ctx.fillText(text, padding, padding + (titleHeight * 1.5) + (line * subtitleHeight * 1.3), maxWidth)
  })


  ctx.globalCompositeOperation = 'destination-over'
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  return canvas.toDataURL()
}

const getIp = async () => {
  try {
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), 2000)
    const response = await fetch("https://api.ipify.org?format=json", { signal: controller.signal })
    clearTimeout(id)
    const body = await response.json()
    return body?.ip
  } catch {
    return null
  }
}
