/// <reference types="@types/gtag.js" />

(window as any).gtag = ((window as any).gtag as Gtag.Gtag)

export const trackUser = (user_id: string) => {
  gtag('set', {'user_id': user_id })
}

type TrackPageViewProps = {
  page_title?: string
  page_location?: string
  page_path?: string
}

export const trackPageView = (props: TrackPageViewProps) => {
  gtag('event', 'page_view', props)
}

export const trackClick = (props: object) => {
  return trackEvent('click', props)
}

export const trackEvent = (eventName: string, props: object) => {
  gtag('event', eventName, props)
}
