import { DateTime, Zone } from 'luxon'

export function timeWithZone(time: null | undefined, timezone: string | null | undefined): undefined
export function timeWithZone(time: string, timezone: string | null | undefined) : DateTime
export function timeWithZone(time: null | undefined | string, timezone: string | null | undefined): DateTime | undefined
export function timeWithZone(time: null | undefined | string, timezone: string | null | undefined): DateTime | undefined {
  if (!time) return

  if (timezone) {
    return DateTime.fromISO(time as string).setZone(timezone)
  } else {
    return DateTime.fromISO(time as string)
  }
}

export const isToday = (date: DateTime) => {
  const today = DateTime.utc().setZone(date.zone).toISODate()
  const dateSlug = date.toISODate()
  return today === dateSlug 
}

export const todayInZone = (timezone: string | Zone) => DateTime.utc().setZone(timezone).startOf('day')

export const formatRelativeDateTime = (displayDate: DateTime, relativeDate: DateTime) => {
  const timePart = formatRelativeTime(displayDate, relativeDate)

  if (displayDate.toISODate() !== relativeDate.toISODate()) {
    return `${displayDate.toFormat('M/d')} ${timePart}`
  } else {
    return timePart
  }
}

export const formatRelativeTime = (displayDate: DateTime, relativeDate: DateTime) => {
  let format = 'h:mma'

  if (displayDate.offset !== relativeDate.offset) {
    format = `${format} ZZZZ`
  }

  return displayDate.toFormat(format)
}
