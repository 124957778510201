export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string ignorant of time and zone, such as 2007-12-03, compliant with the `date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates using the Gregorian calendar. */
  Date: string;
  /** A date string ignorant of time and zone, such as 2007-12-03, compliant with the `date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates using the Gregorian calendar.. DateLike allows full ISO 8601 DateTime stamps, trimming out the date during parsing. Mainly used for backward compatibility */
  DateLike: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /** An arbitrary-precision Decimal type. */
  Decimal: number;
  Json: any;
  /** Float/String for OrderMetric.value */
  MetricValue: number | string;
};

export type AccountsReceivableReport = {
  __typename?: 'AccountsReceivableReport';
  accounts: Array<AccountsReceivableReportRow>;
};

export type AccountsReceivableReportBucket = {
  __typename?: 'AccountsReceivableReportBucket';
  amount: Scalars['Decimal'];
  key: Scalars['String'];
  label: Scalars['String'];
};

export type AccountsReceivableReportRow = {
  __typename?: 'AccountsReceivableReportRow';
  buckets: Array<AccountsReceivableReportBucket>;
  customer: Customer;
  overdue: Scalars['Decimal'];
  total: Scalars['Decimal'];
};

export type AccountsReceivableReportWhere = {
  date?: InputMaybe<Scalars['DateLike']>;
};

export type AcknowledgeAssignmentInput = {
  id: Scalars['Int'];
};

export type AcknowledgeMessageInput = {
  id: Scalars['Int'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  estimateTravelFrom?: Maybe<Array<TravelEstimate>>;
  estimateTravelTo?: Maybe<Array<TravelEstimate>>;
  id: Scalars['Int'];
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zip?: Maybe<Scalars['String']>;
};


export type AddressEstimateTravelFromArgs = {
  addressIds: Array<Scalars['Int']>;
};


export type AddressEstimateTravelToArgs = {
  addressIds: Array<Scalars['Int']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type Aircall = {
  __typename?: 'Aircall';
  settings?: Maybe<AircallSettings>;
  token?: Maybe<IntegrationToken>;
};

export type AircallSettings = {
  __typename?: 'AircallSettings';
  enabled: Scalars['Boolean'];
};

export type ApplyCreditMemoInput = {
  customerId: Scalars['Int'];
  distributions: Array<DistributedCreditBalanceInput>;
};

export type Assignment = {
  __typename?: 'Assignment';
  acknowledged?: Maybe<Scalars['Boolean']>;
  acknowledgedAt?: Maybe<Scalars['DateTime']>;
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  operator: Operator;
  operatorId: Scalars['Int'];
  role: Scalars['String'];
  roleDetails: AssignmentRole;
  route?: Maybe<Route>;
  updatedAt: Scalars['DateTime'];
};

export type AssignmentInput = {
  operatorId: Scalars['Int'];
  role?: InputMaybe<Scalars['String']>;
};

export type AssignmentRole = {
  __typename?: 'AssignmentRole';
  active: Scalars['Boolean'];
  billable: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type AssignmentsInput = {
  set?: InputMaybe<Array<AssignmentInput>>;
};

export type Attachment = {
  __typename?: 'Attachment';
  bytes?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  metadata?: Maybe<Array<KeyValue>>;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type AttachmentCreateInput = {
  bytes?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<KeyValuesInput>;
  mimeType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type AttachmentWhereUniqueInput = {
  id: Scalars['String'];
};

export type AttachmentsInput = {
  create?: InputMaybe<Array<AttachmentCreateInput>>;
  delete?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
};

export type AuthMutations = {
  __typename?: 'AuthMutations';
  login?: Maybe<AuthMutationsLoginResponse>;
  refresh?: Maybe<AuthMutationsRefreshResponse>;
};


export type AuthMutationsLoginArgs = {
  data: AuthMutationsLoginInput;
};


export type AuthMutationsRefreshArgs = {
  data?: InputMaybe<AuthMutationsRefreshInput>;
};

export type AuthMutationsLoginInput = {
  clientId: Scalars['String'];
  /** auth.cretesuite.com identity jwt */
  jwt: Scalars['String'];
};

export type AuthMutationsLoginResponse = {
  __typename?: 'AuthMutationsLoginResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type AuthMutationsRefreshInput = {
  /** refreshToken returned from the auth.login Mutation */
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type AuthMutationsRefreshResponse = {
  __typename?: 'AuthMutationsRefreshResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Authorization = {
  __typename?: 'Authorization';
  /** jwt token granting access to api */
  token?: Maybe<Scalars['String']>;
};

export type AvailableTeamWhereInput = {
  branchId?: InputMaybe<IntFilter>;
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type BillingProfile = {
  __typename?: 'BillingProfile';
  address: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  extra: Array<Scalars['String']>;
  footer: Array<Scalars['String']>;
  header: Array<Scalars['String']>;
  id: Scalars['Int'];
  logo?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BillingProfileInput = {
  email?: InputMaybe<Scalars['String']>;
  footer?: InputMaybe<Array<Scalars['String']>>;
  header?: InputMaybe<Array<Scalars['String']>>;
  memo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type Branch = {
  __typename?: 'Branch';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  /** @deprecated DEPRECATED: use yards instead */
  address: Address;
  /** @deprecated DEPRECATED: use yards instead */
  addressId: Scalars['Int'];
  alertPhone?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  defaultPriceList?: Maybe<PriceList>;
  equipment: Array<Equipment>;
  id: Scalars['Int'];
  integrations?: Maybe<BranchIntegrations>;
  inventory: Array<InventoryItem>;
  mainlinePhone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operators: Array<Operator>;
  organization: Organization;
  phones: Array<Phone>;
  priceLists: Array<PriceList>;
  suppliers: Array<Supplier>;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  yards: Array<Yard>;
};

export type BranchInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  address?: InputMaybe<AddressInput>;
  alertPhone?: InputMaybe<Scalars['String']>;
  defaultPriceList?: InputMaybe<PriceListWhereUniqueInput>;
  mainlinePhone?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<KeyValuesInput>;
  name?: InputMaybe<Scalars['String']>;
  priceLists?: InputMaybe<Array<PriceListWhereUniqueInput>>;
  yards?: InputMaybe<YardsInput>;
};

export type BranchIntegrations = {
  __typename?: 'BranchIntegrations';
  all: Array<KeyValue>;
};

export type BranchUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  address?: InputMaybe<AddressInput>;
  alertPhone?: InputMaybe<Scalars['String']>;
  defaultPriceList?: InputMaybe<PriceListWhereUniqueInput>;
  mainlinePhone?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<KeyValuesInput>;
  name?: InputMaybe<Scalars['String']>;
  priceLists?: InputMaybe<Array<PriceListWhereUniqueInput>>;
  yards?: InputMaybe<YardsInput>;
};

export type BranchWhereUniqueInput = {
  id: Scalars['Int'];
};

export type Changelog = {
  __typename?: 'Changelog';
  entries: Array<ChangelogEntry>;
};

export type ChangelogEntry = {
  __typename?: 'ChangelogEntry';
  change: Scalars['Json'];
  diff: Scalars['Json'];
  event: Scalars['String'];
  person?: Maybe<Person>;
};

export type ChangelogWhereInput = {
  id: Scalars['String'];
  namespace: Scalars['String'];
};

export type CheckOrdersFitInRouteInput = {
  orderIds: Array<Scalars['Int']>;
  routeId: Scalars['Int'];
};

export type CheckWaypointsFitInRouteAddressInput = {
  id?: InputMaybe<Scalars['Int']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
};

export type CheckWaypointsFitInRouteInput = {
  routeId: Scalars['Int'];
  waypoints: Array<CheckWaypointsFitInRouteWaypointInput>;
};

export type CheckWaypointsFitInRouteWaypointInput = {
  address: CheckWaypointsFitInRouteAddressInput;
  arrivalTime: Scalars['DateTime'];
  departureTime: Scalars['DateTime'];
};

export type CollectSignatureInput = {
  image: Scalars['String'];
  metadata?: InputMaybe<KeyValuesInput>;
  personId?: InputMaybe<Scalars['Int']>;
};

export type Comment = {
  __typename?: 'Comment';
  active: Scalars['Boolean'];
  additionalIds: Array<KeyValue>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  metadata: Array<KeyValue>;
  person?: Maybe<Person>;
  personId?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CommentInput = {
  additionalIds?: InputMaybe<KeyValuesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  metadata?: InputMaybe<KeyValuesInput>;
  personId?: InputMaybe<Scalars['Int']>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CommentUpdateArgs = {
  data: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateInput = {
  additionalIds?: InputMaybe<KeyValuesInput>;
  metadata?: InputMaybe<KeyValuesInput>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CommentWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type CommentsInput = {
  add?: InputMaybe<Array<CommentInput>>;
  connect?: InputMaybe<Array<Scalars['Int']>>;
  create?: InputMaybe<Array<CommentInput>>;
  update?: InputMaybe<Array<CommentUpdateArgs>>;
};

export type Contact = PersonInterface & {
  __typename?: 'Contact';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  customers: Array<Customer>;
  emails: Array<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  notes?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Order>>;
  organization: Organization;
  phones: Array<Phone>;
  preferences: ContactPreferences;
  roles: Array<ContactRole>;
  tags: Array<Tag>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type ContactOrdersArgs = {
  orderBy?: InputMaybe<Array<QueryOrdersOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryOrdersWhereInput>;
};

export type ContactInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  customerIds?: InputMaybe<Array<Scalars['Int']>>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  preferences?: InputMaybe<ContactPreferencesInput>;
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  tags?: InputMaybe<TagsInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type ContactMutations = {
  __typename?: 'ContactMutations';
  create?: Maybe<ContactMutationsCreateResponse>;
  update?: Maybe<ContactMutationsUpdateResponse>;
};


export type ContactMutationsCreateArgs = {
  data: ContactInput;
};


export type ContactMutationsUpdateArgs = {
  data: ContactUpdateInput;
  where: ContactWhereUniqueInput;
};

export type ContactMutationsCreateResponse = {
  __typename?: 'ContactMutationsCreateResponse';
  contact: Contact;
  warnings?: Maybe<ContactMutationsResponseWarnings>;
};

export type ContactMutationsResponseWarnings = {
  __typename?: 'ContactMutationsResponseWarnings';
  invalidPhones: Array<Scalars['String']>;
  landlinePhones: Array<Scalars['String']>;
};

export type ContactMutationsUpdateResponse = {
  __typename?: 'ContactMutationsUpdateResponse';
  contact: Contact;
  warnings?: Maybe<ContactMutationsResponseWarnings>;
};

export type ContactPreferences = {
  __typename?: 'ContactPreferences';
  messages: Array<MessagePreference>;
};

export type ContactPreferencesInput = {
  messages?: InputMaybe<MessagePreferencesInput>;
};

export type ContactRole = {
  __typename?: 'ContactRole';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type ContactUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  customerIds?: InputMaybe<Array<Scalars['Int']>>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  preferences?: InputMaybe<ContactPreferencesInput>;
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  tags?: InputMaybe<TagsInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type ContactWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type CreateCreditMemoInput = {
  amount: Scalars['Decimal'];
  customerId: Scalars['Int'];
  date: Scalars['DateLike'];
  memo?: InputMaybe<Scalars['String']>;
  txId?: InputMaybe<Scalars['String']>;
};

export type Credential = {
  __typename?: 'Credential';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  typeDetails: CredentialType;
  updatedAt: Scalars['DateTime'];
};

export type CredentialInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  number?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  typeName?: InputMaybe<Scalars['String']>;
};

export type CredentialType = {
  __typename?: 'CredentialType';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CredentialUpsertInput = {
  data: CredentialInput;
  where?: InputMaybe<CredentialWhereUniqueInput>;
};

export type CredentialWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type CredentialsInput = {
  delete?: InputMaybe<Array<CredentialWhereUniqueInput>>;
  upsert?: InputMaybe<Array<CredentialUpsertInput>>;
};

export type CreditMemo = {
  __typename?: 'CreditMemo';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  customer: Customer;
  date: Scalars['DateTime'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  processedDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreditMemoMutations = {
  __typename?: 'CreditMemoMutations';
  create: CreditMemoMutationsCreateResponse;
  useCreditBalance: UseCreditBalanceResponse;
};


export type CreditMemoMutationsCreateArgs = {
  data: CreateCreditMemoInput;
};


export type CreditMemoMutationsUseCreditBalanceArgs = {
  data: ApplyCreditMemoInput;
};

export type CreditMemoMutationsCreateResponse = {
  __typename?: 'CreditMemoMutationsCreateResponse';
  creditMemo: CreditMemo;
};

export type Customer = {
  __typename?: 'Customer';
  /** @deprecated use nickname instead */
  abbreviation: Scalars['String'];
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  balance: Scalars['Decimal'];
  billingAddress?: Maybe<Address>;
  billingAddressId?: Maybe<Scalars['Int']>;
  billingSameAsPhysical?: Maybe<Scalars['Boolean']>;
  changelog?: Maybe<Changelog>;
  contacts: Array<Contact>;
  createdAt: Scalars['DateTime'];
  creditBalance: Scalars['Decimal'];
  defaultPriceList?: Maybe<PriceList>;
  emails: Array<Scalars['String']>;
  id: Scalars['Int'];
  includeWorkTicketWithInvoice: Scalars['Boolean'];
  integrations?: Maybe<CustomerIntegrations>;
  name: Scalars['String'];
  name2?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  overdueBalance: Scalars['Decimal'];
  phones: Array<Phone>;
  physicalAddress?: Maybe<Address>;
  physicalAddressId?: Maybe<Scalars['Int']>;
  priceLists: Array<PriceList>;
  sites: Array<Site>;
  tags: Array<Tag>;
  updatedAt: Scalars['DateTime'];
};


export type CustomerContactsArgs = {
  where?: InputMaybe<CustomerContactsWhereInput>;
};


export type CustomerSitesArgs = {
  where?: InputMaybe<CustomerSitesWhereInput>;
};

export type CustomerContactsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
};

export type CustomerInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  billingAddress?: InputMaybe<AddressInput>;
  billingSameAsPhysical?: InputMaybe<Scalars['Boolean']>;
  defaultPriceList?: InputMaybe<PriceListWhereUniqueInput>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  includeWorkTicketWithInvoice?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  name2?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  physicalAddress?: InputMaybe<AddressInput>;
  priceLists?: InputMaybe<Array<PriceListWhereUniqueInput>>;
  tags?: InputMaybe<TagsInput>;
};

export type CustomerIntegrations = {
  __typename?: 'CustomerIntegrations';
  all: Array<KeyValue>;
  quickbooks?: Maybe<CustomerQuickbooksIntegration>;
};

export type CustomerQuickbooksIntegration = {
  __typename?: 'CustomerQuickbooksIntegration';
  customer?: Maybe<QuickbooksCustomer>;
};

export type CustomerSitesWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
};

export type CustomerUpdateInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  billingAddress?: InputMaybe<AddressInput>;
  billingSameAsPhysical?: InputMaybe<Scalars['Boolean']>;
  defaultPriceList?: InputMaybe<PriceListWhereUniqueInput>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  includeWorkTicketWithInvoice?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  name2?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  physicalAddress?: InputMaybe<AddressInput>;
  priceLists?: InputMaybe<Array<PriceListWhereUniqueInput>>;
  tags?: InputMaybe<TagsInput>;
};

export type CustomerWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type Dialpad = {
  __typename?: 'Dialpad';
  settings?: Maybe<DialpadSettings>;
  token?: Maybe<IntegrationToken>;
};

export type DialpadSettings = {
  __typename?: 'DialpadSettings';
  enabled: Scalars['Boolean'];
};

export type DistributedCreditBalanceInput = {
  amount: Scalars['Decimal'];
  invoiceId: Scalars['String'];
};

export type DistributedPaymentInput = {
  amount: Scalars['Decimal'];
  invoiceId: Scalars['String'];
};

export type Document = {
  __typename?: 'Document';
  active: Scalars['Boolean'];
  attachments?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['Date']>;
  fields: Array<KeyValue>;
  id: Scalars['Int'];
  pinned: Scalars['Boolean'];
  type: Scalars['String'];
  typeDetails: DocumentType;
  updatedAt: Scalars['DateTime'];
};

export type DocumentInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  attachments?: InputMaybe<Scalars['Json']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
  fields?: InputMaybe<Array<KeyValueInput>>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  type: Scalars['String'];
  typeName?: InputMaybe<Scalars['String']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum DocumentTypeScope {
  Equipment = 'equipment'
}

export type DocumentTypesWhereInput = {
  active?: InputMaybe<BoolFilter>;
  scope?: InputMaybe<DocumentTypeScope>;
};

export type DocumentUpsertInput = {
  data: DocumentInput;
  where?: InputMaybe<DocumentWhereUniqueInput>;
};

export type DocumentWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DocumentsInput = {
  delete?: InputMaybe<Array<DocumentWhereUniqueInput>>;
  upsert?: InputMaybe<Array<DocumentUpsertInput>>;
};

export type EnumPersonAvailabilityStatusFilter = {
  equals?: InputMaybe<PersonAvailabilityStatus>;
  in?: InputMaybe<Array<PersonAvailabilityStatus>>;
  not?: InputMaybe<NestedEnumPersonAvailabilityStatusFilter>;
  notIn?: InputMaybe<Array<PersonAvailabilityStatus>>;
};

export type EnumTimeClockTaskScopeNullableListFilter = {
  equals?: InputMaybe<Array<TimeClockTaskScope>>;
  has?: InputMaybe<TimeClockTaskScope>;
  hasEvery?: InputMaybe<Array<TimeClockTaskScope>>;
  hasSome?: InputMaybe<Array<TimeClockTaskScope>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Equipment = {
  __typename?: 'Equipment';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  branch: Branch;
  branchId: Scalars['Int'];
  cleanTime?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  defaultOperator?: Maybe<Operator>;
  defaultOperatorId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  documents: Array<Document>;
  group?: Maybe<EquipmentGroup>;
  groupId?: Maybe<Scalars['Int']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  integrations?: Maybe<EquipmentIntegrations>;
  /** @deprecated use service instead */
  invoiceTemplate?: Maybe<Service>;
  name: Scalars['String'];
  prepTime?: Maybe<Scalars['Int']>;
  service?: Maybe<Service>;
  /** @deprecated use service instead */
  serviceCategory?: Maybe<Service>;
  size?: Maybe<Scalars['Float']>;
  sort: Scalars['Int'];
  telematics: EquipmentTelematics;
  travelRate?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  typeDetails: EquipmentType;
  updatedAt: Scalars['DateTime'];
  yardageRate?: Maybe<Scalars['Float']>;
};


export type EquipmentDocumentsArgs = {
  where?: InputMaybe<EquipmentDocumentsWhereInput>;
};

export type EquipmentAvailability = {
  __typename?: 'EquipmentAvailability';
  address: Address;
  branch: Branch;
  createdAt: Scalars['DateTime'];
  equipment: Equipment;
  id: Scalars['Int'];
  status: EquipmentAvailabilityStatus;
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
};

export type EquipmentAvailabilitySnapshot = {
  __typename?: 'EquipmentAvailabilitySnapshot';
  availability: Array<EquipmentAvailability>;
  equipment: Equipment;
  id: Scalars['String'];
};

export enum EquipmentAvailabilityStatus {
  Available = 'available',
  NotAvailable = 'not_available'
}

export type EquipmentCancelAvailabilityDataInput = {
  /** Indicates what timeslot will merge with the gap being created by canceling the equipment schedule */
  fillGap?: InputMaybe<EquipmentCancelAvailabilityFillGapEnum>;
};

export enum EquipmentCancelAvailabilityFillGapEnum {
  Next = 'next',
  Previous = 'previous'
}

export type EquipmentCancelAvailabilityResponse = {
  __typename?: 'EquipmentCancelAvailabilityResponse';
  deleted: EquipmentAvailability;
  updated?: Maybe<EquipmentAvailability>;
};

export type EquipmentCancelAvailabilityWhereInput = {
  id: Scalars['Int'];
};

export type EquipmentDocumentsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  expirationDate?: InputMaybe<StringNullableFilter>;
  pinned?: InputMaybe<BoolFilter>;
};

export type EquipmentGeotabIntegration = {
  __typename?: 'EquipmentGeotabIntegration';
  vehicle?: Maybe<GeotabVehicle>;
};

export type EquipmentGroup = {
  __typename?: 'EquipmentGroup';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  locationAware: Scalars['Boolean'];
  locationSort?: Maybe<Array<Scalars['Int']>>;
  name: Scalars['String'];
  /** @deprecated use locationAware instead */
  prefixWithYard?: Maybe<Scalars['Boolean']>;
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type EquipmentGroupDataCreateInput = {
  locationAware: Scalars['Boolean'];
  name: Scalars['String'];
  sort: Scalars['Int'];
};

export type EquipmentGroupDataUpdateInput = {
  locationAware?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

export type EquipmentGroupMutations = {
  __typename?: 'EquipmentGroupMutations';
  create: EquipmentGroupMutationsCreateResponse;
  update: EquipmentGroupMutationsUpdateResponse;
};


export type EquipmentGroupMutationsCreateArgs = {
  data: EquipmentGroupDataCreateInput;
};


export type EquipmentGroupMutationsUpdateArgs = {
  data: EquipmentGroupDataUpdateInput;
  where: EquipmentGroupWhereUpdateUniqueInput;
};

export type EquipmentGroupMutationsCreateResponse = {
  __typename?: 'EquipmentGroupMutationsCreateResponse';
  equipmentGroup: EquipmentGroup;
};

export type EquipmentGroupMutationsUpdateResponse = {
  __typename?: 'EquipmentGroupMutationsUpdateResponse';
  equipmentGroup: EquipmentGroup;
};

export type EquipmentGroupWhereUpdateUniqueInput = {
  id: Scalars['Int'];
};

export type EquipmentInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  branchId: Scalars['Int'];
  cleanTime?: InputMaybe<Scalars['Int']>;
  defaultOperatorId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<DocumentsInput>;
  groupId?: InputMaybe<Scalars['Int']>;
  hourlyRate?: InputMaybe<Scalars['Float']>;
  invoiceTemplate?: InputMaybe<InvoiceTemplateWhereUniqueInput>;
  name: Scalars['String'];
  prepTime?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<ServiceWhereUniqueInput>;
  serviceCategory?: InputMaybe<ServiceCategoryWhereUniqueInput>;
  size?: InputMaybe<Scalars['Float']>;
  travelRate?: InputMaybe<Scalars['Float']>;
  type: Scalars['String'];
  yardageRate?: InputMaybe<Scalars['Float']>;
};

export type EquipmentIntegrations = {
  __typename?: 'EquipmentIntegrations';
  all: Array<KeyValue>;
  geotab?: Maybe<EquipmentGeotabIntegration>;
  quickbooks?: Maybe<EquipmentQuickbooksIntegration>;
  samsara?: Maybe<EquipmentSamsaraIntegration>;
};

export type EquipmentMutations = {
  __typename?: 'EquipmentMutations';
  cancelAvailability: EquipmentCancelAvailabilityResponse;
  scheduleAvailability: EquipmentScheduleAvailabilityResponse;
};


export type EquipmentMutationsCancelAvailabilityArgs = {
  data?: InputMaybe<EquipmentCancelAvailabilityDataInput>;
  where: EquipmentCancelAvailabilityWhereInput;
};


export type EquipmentMutationsScheduleAvailabilityArgs = {
  data: EquipmentScheduleAvailabilityDataInput;
  where: EquipmentScheduleAvailabilityWhereInput;
};

export type EquipmentQuickbooksIntegration = {
  __typename?: 'EquipmentQuickbooksIntegration';
  cancellationRate?: Maybe<QuickbooksItem>;
  flatRate?: Maybe<QuickbooksItem>;
  hourlyRate?: Maybe<QuickbooksItem>;
  travelRate?: Maybe<QuickbooksItem>;
  yardageRate?: Maybe<QuickbooksItem>;
};

export type EquipmentSamsaraIntegration = {
  __typename?: 'EquipmentSamsaraIntegration';
  vehicle?: Maybe<SamsaraVehicle>;
};

export type EquipmentScheduleAvailabilityDataInput = {
  addressId: Scalars['Int'];
  validFrom: Scalars['DateTime'];
  validTo?: InputMaybe<Scalars['DateTime']>;
};

export type EquipmentScheduleAvailabilityResponse = {
  __typename?: 'EquipmentScheduleAvailabilityResponse';
  equipmentAvailability: Array<EquipmentAvailability>;
};

export type EquipmentScheduleAvailabilityWhereInput = {
  equipmentId: Scalars['Int'];
};

export type EquipmentTelematics = {
  __typename?: 'EquipmentTelematics';
  gps?: Maybe<TelematicsGps>;
  stats?: Maybe<TelematicsStats>;
};

export type EquipmentType = {
  __typename?: 'EquipmentType';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type EquipmentTypeDataCreateInput = {
  active: Scalars['Boolean'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
};

export type EquipmentTypeDataUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
};

export type EquipmentTypeMutations = {
  __typename?: 'EquipmentTypeMutations';
  create: EquipmentTypeMutationsCreateResponse;
  update: EquipmentTypeMutationsUpdateResponse;
};


export type EquipmentTypeMutationsCreateArgs = {
  data: EquipmentTypeDataCreateInput;
};


export type EquipmentTypeMutationsUpdateArgs = {
  data: EquipmentTypeDataUpdateInput;
  where: EquipmentTypeWhereUpdateUniqueInput;
};

export type EquipmentTypeMutationsCreateResponse = {
  __typename?: 'EquipmentTypeMutationsCreateResponse';
  equipmentType: EquipmentType;
};

export type EquipmentTypeMutationsUpdateResponse = {
  __typename?: 'EquipmentTypeMutationsUpdateResponse';
  equipmentType: EquipmentType;
};

export type EquipmentTypeWhereUpdateUniqueInput = {
  id: Scalars['Int'];
};

export type EquipmentUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  branchId?: InputMaybe<Scalars['Int']>;
  cleanTime?: InputMaybe<Scalars['Int']>;
  defaultOperatorId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<DocumentsInput>;
  groupId?: InputMaybe<Scalars['Int']>;
  hourlyRate?: InputMaybe<Scalars['Float']>;
  invoiceTemplate?: InputMaybe<InvoiceTemplateWhereUniqueInput>;
  name?: InputMaybe<Scalars['String']>;
  prepTime?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<ServiceWhereUniqueInput>;
  serviceCategory?: InputMaybe<ServiceCategoryWhereUniqueInput>;
  size?: InputMaybe<Scalars['Float']>;
  travelRate?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
  yardageRate?: InputMaybe<Scalars['Float']>;
};

export type EquipmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Event = {
  __typename?: 'Event';
  active: Scalars['Boolean'];
  branch?: Maybe<Branch>;
  branchId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  id: Scalars['Int'];
  note?: Maybe<Note>;
  noteId?: Maybe<Scalars['Int']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['Int']>;
  route?: Maybe<Route>;
  routeId?: Maybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type EventInput = {
  branchId?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type EventsInput = {
  set?: InputMaybe<Array<EventInput>>;
};

export type FileMutations = {
  __typename?: 'FileMutations';
  presignUploadUrl?: Maybe<FileUploadUrlResponse>;
};


export type FileMutationsPresignUploadUrlArgs = {
  data?: InputMaybe<UploadTypesWhereInput>;
};

export type FileUploadUrlResponse = {
  __typename?: 'FileUploadUrlResponse';
  s3ObjectUrl: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type Geotab = {
  __typename?: 'Geotab';
  credentials: GeotabCredentials;
  settings: GeotabSettings;
  vehicles: Array<GeotabVehicle>;
};


export type GeotabCredentialsArgs = {
  forceRefresh?: InputMaybe<Scalars['Boolean']>;
};

export type GeotabConnectCredentialsInput = {
  database: Scalars['String'];
  password: Scalars['String'];
  server?: InputMaybe<Scalars['String']>;
  userName: Scalars['String'];
};

export type GeotabCredentials = {
  __typename?: 'GeotabCredentials';
  createdAt: Scalars['DateTime'];
  database: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  server: Scalars['String'];
  sessionId: Scalars['String'];
  userName: Scalars['String'];
};

export type GeotabMutationNamespace = {
  __typename?: 'GeotabMutationNamespace';
  connect: GenericResponse;
  linkVehicle: Equipment;
};


export type GeotabMutationNamespaceConnectArgs = {
  credentials: GeotabConnectCredentialsInput;
};


export type GeotabMutationNamespaceLinkVehicleArgs = {
  equipment: EquipmentWhereUniqueInput;
  vehicleId?: InputMaybe<Scalars['String']>;
};

export type GeotabSettings = {
  __typename?: 'GeotabSettings';
  database: Scalars['String'];
  enabled: Scalars['Boolean'];
  server: Scalars['String'];
};

export type GeotabVehicle = {
  __typename?: 'GeotabVehicle';
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type InsightsDashboard = {
  __typename?: 'InsightsDashboard';
  expires: Scalars['DateTime'];
  hidden: Scalars['Boolean'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type InsightsDashboardsInput = {
  title?: InputMaybe<StringFilter>;
};

export type InsightsUrl = {
  __typename?: 'InsightsUrl';
  expires: Scalars['DateTime'];
  url: Scalars['String'];
};

export type InsightsUrlInput = {
  dashboard: Scalars['String'];
};

export type IntArrayInput = {
  set?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntegrationToken = {
  __typename?: 'IntegrationToken';
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['Json']>;
  token: Scalars['String'];
};

export type Integrations = {
  __typename?: 'Integrations';
  aircall?: Maybe<Aircall>;
  dialpad?: Maybe<Dialpad>;
  geotab?: Maybe<Geotab>;
  quickbooks?: Maybe<Quickbooks>;
  samsara?: Maybe<Samsara>;
};

export type IntegrationsMutationNamespace = {
  __typename?: 'IntegrationsMutationNamespace';
  geotab?: Maybe<GeotabMutationNamespace>;
  quickbooks?: Maybe<QuickbooksMutationNamespace>;
  samsara?: Maybe<SamsaraMutationNamespace>;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  active: Scalars['Boolean'];
  branch: Branch;
  branchId: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
  consumable: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayMultiplier?: Maybe<Scalars['Float']>;
  displayUnit?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  visibility: InventoryItemVisibilities;
};

export type InventoryItemCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  branchId: Scalars['Int'];
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayMultiplier?: InputMaybe<Scalars['Float']>;
  displayUnit?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  quantity?: InputMaybe<Scalars['Float']>;
  visibility?: InputMaybe<InventoryItemVisibilitiesInput>;
};

export type InventoryItemUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  branchId?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayMultiplier?: InputMaybe<Scalars['Float']>;
  displayUnit?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  visibility?: InputMaybe<InventoryItemVisibilitiesInput>;
};

export type InventoryItemVisibilities = {
  __typename?: 'InventoryItemVisibilities';
  customerConfirmation: InventoryItemVisibility;
  customerWorkTicket: InventoryItemVisibility;
  operatorAssignment: InventoryItemVisibility;
  operatorWorkTicket: InventoryItemVisibility;
  /** @deprecated use orderReview instead */
  orderBilling: InventoryItemVisibility;
  orderForm: InventoryItemVisibility;
  orderReview: InventoryItemVisibility;
};

export type InventoryItemVisibilitiesInput = {
  customerConfirmation?: InputMaybe<InventoryItemVisibility>;
  customerWorkTicket?: InputMaybe<InventoryItemVisibility>;
  operatorAssignment?: InputMaybe<InventoryItemVisibility>;
  operatorWorkTicket?: InputMaybe<InventoryItemVisibility>;
  orderForm?: InputMaybe<InventoryItemVisibility>;
  orderReview?: InputMaybe<InventoryItemVisibility>;
};

export enum InventoryItemVisibility {
  Always = 'always',
  Auto = 'auto',
  Hidden = 'hidden'
}

export type InventoryItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  additionalIds?: Maybe<Array<KeyValue>>;
  amountDue: Scalars['Decimal'];
  amountPaid: Scalars['Decimal'];
  assetsUsed: Array<Equipment>;
  balance: Scalars['Decimal'];
  billTo?: Maybe<InvoiceBillTo>;
  billingProfile?: Maybe<BillingProfile>;
  branch: Branch;
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  customer?: Maybe<Customer>;
  details: Array<InvoiceDetail>;
  draftCreditMemo?: Maybe<Scalars['Decimal']>;
  dueDate?: Maybe<Scalars['Date']>;
  emails: Array<Scalars['String']>;
  id: Scalars['String'];
  integrations?: Maybe<InvoiceIntegrations>;
  invoiceDate?: Maybe<Scalars['Date']>;
  lines: Array<InvoiceLine>;
  links: Array<Link>;
  memo?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<KeyValue>>;
  number: Scalars['String'];
  orders: Array<Order>;
  priceList?: Maybe<PriceList>;
  purchaseOrder?: Maybe<Scalars['String']>;
  revision: Scalars['Int'];
  site?: Maybe<Site>;
  status: Scalars['String'];
  statusDetails: InvoiceStatus;
  tags: Array<Tag>;
  title?: Maybe<InvoiceTitle>;
  transactionHistory?: Maybe<Array<TransactionHistory>>;
  updatedAt: Scalars['DateTime'];
  version: Scalars['String'];
};

export type InvoiceBillTo = {
  __typename?: 'InvoiceBillTo';
  address: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type InvoiceBillToInput = {
  address?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type InvoiceDetail = {
  __typename?: 'InvoiceDetail';
  label: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type InvoiceDetailInput = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type InvoiceEstimate = {
  __typename?: 'InvoiceEstimate';
  id: Scalars['String'];
  total: Scalars['Float'];
};

export type InvoiceIntegrations = {
  __typename?: 'InvoiceIntegrations';
  all: Array<KeyValue>;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  active: Scalars['Boolean'];
  category?: Maybe<Scalars['String']>;
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  hasCustomDisplayName: Scalars['Boolean'];
  id: Scalars['Int'];
  integrations?: Maybe<InvoiceItemIntegrations>;
  name: Scalars['String'];
  quantityResolver?: Maybe<QuantityResolver>;
  revision: Scalars['Int'];
  step?: Maybe<Scalars['Decimal']>;
  updatedAt: Scalars['DateTime'];
};

export type InvoiceItemDataCreateInput = {
  active: Scalars['Boolean'];
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  quantityResolver?: InputMaybe<QuantityResolversItemInput>;
};

export type InvoiceItemDataUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  quantityResolver?: InputMaybe<QuantityResolversItemInput>;
};

export type InvoiceItemIntegrations = {
  __typename?: 'InvoiceItemIntegrations';
  all: Array<KeyValue>;
};

export type InvoiceItemMutations = {
  __typename?: 'InvoiceItemMutations';
  create: InvoiceItemMutationsCreateResponse;
  update: InvoiceItemMutationsUpdateResponse;
};


export type InvoiceItemMutationsCreateArgs = {
  data: InvoiceItemDataCreateInput;
};


export type InvoiceItemMutationsUpdateArgs = {
  data: InvoiceItemDataUpdateInput;
  where: InvoiceItemWhereUniqueInput;
};

export type InvoiceItemMutationsCreateResponse = {
  __typename?: 'InvoiceItemMutationsCreateResponse';
  invoiceItem: InvoiceItem;
};

export type InvoiceItemMutationsUpdateResponse = {
  __typename?: 'InvoiceItemMutationsUpdateResponse';
  invoiceItem: InvoiceItem;
};

export type InvoiceItemWhereUniqueInput = {
  id: Scalars['Int'];
};

export type InvoiceLine = {
  __typename?: 'InvoiceLine';
  additionalIds?: Maybe<Array<KeyValue>>;
  amount?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  customRate?: Maybe<Scalars['Decimal']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  item?: Maybe<InvoiceItem>;
  lineNumber: Scalars['Int'];
  metadata?: Maybe<Array<KeyValue>>;
  price?: Maybe<Price>;
  quantity?: Maybe<Scalars['Decimal']>;
  section: InvoiceLineSection;
  type: InvoiceLineType;
  updatedAt: Scalars['DateTime'];
};

export type InvoiceLineCreateInput = {
  additionalIds?: InputMaybe<KeyValuesInput>;
  amount?: InputMaybe<Scalars['Decimal']>;
  customRate?: InputMaybe<Scalars['Decimal']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  item?: InputMaybe<InvoiceItemWhereUniqueInput>;
  lineNumber: Scalars['Int'];
  metadata?: InputMaybe<KeyValuesInput>;
  price?: InputMaybe<PriceWhereUniqueInput>;
  quantity?: InputMaybe<Scalars['Decimal']>;
  section: InvoiceLineSection;
  type: InvoiceLineType;
};

export enum InvoiceLineSection {
  Summary = 'summary',
  Table = 'table'
}

export enum InvoiceLineType {
  DescriptionOnly = 'descriptionOnly',
  Discount = 'discount',
  Item = 'item',
  Subtotal = 'subtotal',
  Surcharge = 'surcharge'
}

export type InvoiceLinesInput = {
  set?: InputMaybe<Array<InvoiceLineCreateInput>>;
};

export type InvoiceMutations = {
  __typename?: 'InvoiceMutations';
  new: InvoiceMutationsNewResponse;
  sendEmail?: Maybe<SendEmailResponse>;
  sync: InvoiceMutationsSyncResponse;
  update: InvoiceMutationsUpdateResponse;
};


export type InvoiceMutationsNewArgs = {
  data?: InputMaybe<InvoiceNewInput>;
};


export type InvoiceMutationsSendEmailArgs = {
  data: SendEmailInput;
};


export type InvoiceMutationsSyncArgs = {
  where: InvoiceMutationsSyncWhereInput;
};


export type InvoiceMutationsUpdateArgs = {
  data: InvoiceUpdateInput;
  where: InvoiceMutationsUpdateWhereInput;
};

export type InvoiceMutationsNewResponse = {
  __typename?: 'InvoiceMutationsNewResponse';
  invoice: Invoice;
};

export type InvoiceMutationsSyncResponse = {
  __typename?: 'InvoiceMutationsSyncResponse';
  invoice?: Maybe<Invoice>;
};

export type InvoiceMutationsSyncWhereInput = {
  id: Scalars['String'];
};

export type InvoiceMutationsUpdateResponse = {
  __typename?: 'InvoiceMutationsUpdateResponse';
  invoice: Invoice;
};

export type InvoiceMutationsUpdateWhereInput = {
  id: Scalars['String'];
  revision: Scalars['Int'];
};

export type InvoiceNewInput = {
  orderIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type InvoiceStatus = {
  __typename?: 'InvoiceStatus';
  active: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type InvoiceTemplate = {
  __typename?: 'InvoiceTemplate';
  active: Scalars['Boolean'];
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  lines: Array<InvoiceTemplateLine>;
  metadata?: Maybe<Array<KeyValue>>;
  updatedAt: Scalars['DateTime'];
};

export type InvoiceTemplateInput = {
  lines?: InputMaybe<InvoiceTemplateLinesInput>;
};

export type InvoiceTemplateLine = {
  __typename?: 'InvoiceTemplateLine';
  createdAt: Scalars['DateTime'];
  displayIfZero: Scalars['Boolean'];
  group?: Maybe<InvoiceTemplateLineGroup>;
  id: Scalars['String'];
  item?: Maybe<InvoiceItem>;
  lineNumber: Scalars['Int'];
  section: InvoiceLineSection;
  type: InvoiceTemplateLineType;
  updatedAt: Scalars['DateTime'];
};

export type InvoiceTemplateLineGroup = {
  __typename?: 'InvoiceTemplateLineGroup';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  lines: Array<InvoiceTemplateLine>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type InvoiceTemplateLineGroupCreateInput = {
  lines?: InputMaybe<InvoiceTemplateLinesInput>;
  name: Scalars['String'];
};

export type InvoiceTemplateLineGroupMutations = {
  __typename?: 'InvoiceTemplateLineGroupMutations';
  create: InvoiceTemplateLineGroupMutationsCreateResponse;
  update: InvoiceTemplateLineGroupMutationsUpdateResponse;
};


export type InvoiceTemplateLineGroupMutationsCreateArgs = {
  data: InvoiceTemplateLineGroupCreateInput;
};


export type InvoiceTemplateLineGroupMutationsUpdateArgs = {
  data: InvoiceTemplateLineGroupUpdateInput;
  where: InvoiceTemplateLineGroupWhereUniqueInput;
};

export type InvoiceTemplateLineGroupMutationsCreateResponse = {
  __typename?: 'InvoiceTemplateLineGroupMutationsCreateResponse';
  invoiceTemplateLineGroup: InvoiceTemplateLineGroup;
};

export type InvoiceTemplateLineGroupMutationsUpdateResponse = {
  __typename?: 'InvoiceTemplateLineGroupMutationsUpdateResponse';
  invoiceTemplateLineGroup: InvoiceTemplateLineGroup;
};

export type InvoiceTemplateLineGroupRelationInput = {
  connect?: InputMaybe<InvoiceTemplateLineGroupWhereUniqueInput>;
};

export type InvoiceTemplateLineGroupUpdateInput = {
  lines?: InputMaybe<InvoiceTemplateLinesInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type InvoiceTemplateLineGroupWhereUniqueInput = {
  id: Scalars['Int'];
};

export type InvoiceTemplateLineInput = {
  displayIfZero?: InputMaybe<Scalars['Boolean']>;
  group?: InputMaybe<InvoiceTemplateLineGroupRelationInput>;
  id: Scalars['String'];
  item?: InputMaybe<InvoiceItemWhereUniqueInput>;
  lineNumber?: InputMaybe<Scalars['Int']>;
  section?: InputMaybe<InvoiceLineSection>;
  type?: InputMaybe<InvoiceTemplateLineType>;
};

export enum InvoiceTemplateLineType {
  Discount = 'discount',
  Group = 'group',
  Item = 'item',
  Subtotal = 'subtotal',
  Surcharge = 'surcharge'
}

export type InvoiceTemplateLineWhereUniqueInput = {
  id: Scalars['String'];
};

export type InvoiceTemplateLinesInput = {
  delete?: InputMaybe<Array<InvoiceTemplateLineWhereUniqueInput>>;
  set?: InputMaybe<Array<InvoiceTemplateLineInput>>;
  upsert?: InputMaybe<Array<InvoiceTemplateLineInput>>;
};

export type InvoiceTemplateRelationInput = {
  upsert?: InputMaybe<InvoiceTemplateInput>;
};

export type InvoiceTemplateWhereUniqueInput = {
  id: Scalars['Int'];
};

export type InvoiceTerm = {
  __typename?: 'InvoiceTerm';
  id: Scalars['Int'];
  name: Scalars['String'];
  sort: Scalars['Int'];
};

export type InvoiceTermWhereUniqueInput = {
  id: Scalars['Int'];
};

export type InvoiceTitle = {
  __typename?: 'InvoiceTitle';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Array<Scalars['String']>>;
};

export type InvoiceTitleInput = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Array<Scalars['String']>>;
};

export type InvoiceUpdateInput = {
  additionalIds?: InputMaybe<KeyValuesInput>;
  amountDue: Scalars['Decimal'];
  billTo?: InputMaybe<InvoiceBillToInput>;
  branch: BranchWhereUniqueInput;
  comments?: InputMaybe<CommentsInput>;
  currency: Scalars['String'];
  customer?: InputMaybe<CustomerWhereUniqueInput>;
  details?: InputMaybe<Array<InvoiceDetailInput>>;
  draftCreditMemo?: InputMaybe<Scalars['Decimal']>;
  dueDate: Scalars['Date'];
  emails: Array<Scalars['String']>;
  invoiceDate: Scalars['Date'];
  lines?: InputMaybe<InvoiceLinesInput>;
  memo?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<KeyValuesInput>;
  number: Scalars['String'];
  orders?: InputMaybe<Array<OrderWhereUniqueInput>>;
  priceList?: InputMaybe<PriceListWhereUniqueInput>;
  purchaseOrder?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<SiteWhereUniqueInput>;
  status: Scalars['String'];
  tags?: InputMaybe<TagsInput>;
  title?: InputMaybe<InvoiceTitleInput>;
};

export type InvoiceWhereUniqueInput = {
  id: Scalars['String'];
};

export type JobCostDimension = {
  __typename?: 'JobCostDimension';
  misc: Scalars['Float'];
  onSite: Scalars['Float'];
  total: Scalars['Float'];
  travelFrom: Scalars['Float'];
  travelTo: Scalars['Float'];
};

export type JobCostEstimate = {
  __typename?: 'JobCostEstimate';
  fuel: JobCostDimension;
  labor: JobCostDimension;
  total: Scalars['Float'];
};

export type KeyValue = {
  __typename?: 'KeyValue';
  createdAt?: Maybe<Scalars['DateTime']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Json']>;
};

export type KeyValueInput = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['Json']>;
};

export type KeyValuesInput = {
  create?: InputMaybe<Array<KeyValueInput>>;
  delete?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<KeyValueInput>>;
  update?: InputMaybe<Array<KeyValueInput>>;
  upsert?: InputMaybe<Array<KeyValueInput>>;
};

export type Link = {
  __typename?: 'Link';
  title: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type MergeCustomerResponse = {
  __typename?: 'MergeCustomerResponse';
  customer: Customer;
  deleted: Array<Scalars['Int']>;
};

export type Message = {
  __typename?: 'Message';
  ackedAt?: Maybe<Scalars['DateTime']>;
  additionalIds?: Maybe<Array<KeyValue>>;
  assignments: Array<Assignment>;
  body: Scalars['String'];
  changelog?: Maybe<Changelog>;
  contentType: Scalars['String'];
  createdAt: Scalars['DateTime'];
  extras?: Maybe<Scalars['Json']>;
  from: Scalars['String'];
  handler: Scalars['String'];
  id: Scalars['Int'];
  invoices: Array<Invoice>;
  orders: Array<Order>;
  recipient?: Maybe<Person>;
  recipientId?: Maybe<Scalars['Int']>;
  routes: Array<Route>;
  sender?: Maybe<Person>;
  senderId?: Maybe<Scalars['Int']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  status: MessageStatus;
  subject: Scalars['String'];
  to: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  typeDetails?: Maybe<MessageType>;
  updatedAt: Scalars['DateTime'];
};

export type MessagePreference = {
  __typename?: 'MessagePreference';
  description?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  optedIn?: Maybe<Scalars['Boolean']>;
  path: Scalars['String'];
};

export type MessagePreferenceInput = {
  optedIn: Scalars['Boolean'];
  path: Scalars['String'];
};

export type MessagePreferencesInput = {
  upsert?: InputMaybe<Array<MessagePreferenceInput>>;
};

export type MessageScope = {
  __typename?: 'MessageScope';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  path: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum MessageStatus {
  Cancelled = 'cancelled',
  Delivered = 'delivered',
  Failed = 'failed',
  Queued = 'queued',
  Scheduled = 'scheduled',
  Sending = 'sending',
  Sent = 'sent',
  Skipped = 'skipped'
}

export type MessageType = {
  __typename?: 'MessageType';
  active: Scalars['Boolean'];
  allowResend: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  defaultOptedIn: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MessageUpdateInput = {
  ackedAt?: InputMaybe<Scalars['DateTime']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  metadata?: InputMaybe<KeyValuesInput>;
  remoteId?: InputMaybe<Scalars['String']>;
  sentAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<MessageStatus>;
};

export type MessageWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  remoteId?: InputMaybe<Scalars['String']>;
};

export type Messaging = {
  __typename?: 'Messaging';
  dailyConfirmationTime: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acknowledgeAssignment?: Maybe<Assignment>;
  acknowledgeMessage?: Maybe<Message>;
  auth?: Maybe<AuthMutations>;
  /** Exchange auth.cretesuite.com JWT for an api access JWT */
  authorize?: Maybe<Authorization>;
  collectOrderSignature?: Maybe<Signature>;
  connectTags?: Maybe<UpdateTagsRelationsResponse>;
  contact?: Maybe<ContactMutations>;
  createBranch?: Maybe<Branch>;
  createContact?: Maybe<Contact>;
  createInventoryItem?: Maybe<InventoryItem>;
  createNote?: Maybe<Note>;
  createOneCustomer?: Maybe<Customer>;
  createOneEquipment?: Maybe<Equipment>;
  createOperator?: Maybe<Operator>;
  createOrder?: Maybe<Order>;
  createPourType?: Maybe<PourType>;
  createSite?: Maybe<Site>;
  createSupplier?: Maybe<Supplier>;
  createUser?: Maybe<User>;
  creditMemo?: Maybe<CreditMemoMutations>;
  disconnectTags?: Maybe<UpdateTagsRelationsResponse>;
  dispatchRoute?: Maybe<Route>;
  equipment?: Maybe<EquipmentMutations>;
  equipmentGroup?: Maybe<EquipmentGroupMutations>;
  equipmentType?: Maybe<EquipmentTypeMutations>;
  file?: Maybe<FileMutations>;
  integrations?: Maybe<IntegrationsMutationNamespace>;
  invoice?: Maybe<InvoiceMutations>;
  invoiceItem?: Maybe<InvoiceItemMutations>;
  invoiceTemplateLineGroup?: Maybe<InvoiceTemplateLineGroupMutations>;
  mergeCustomers: MergeCustomerResponse;
  notifyAssignment?: Maybe<Message>;
  operator?: Maybe<OperatorMutations>;
  payment?: Maybe<PaymentMutations>;
  person?: Maybe<PersonMutations>;
  priceList?: Maybe<PriceListMutations>;
  priceListSchedule?: Maybe<PriceListScheduleMutations>;
  requestOrderChange?: Maybe<RequestChangesResponse>;
  requestSiteLocation?: Maybe<Message>;
  route?: Maybe<RouteMutations>;
  sendOrderMessage?: Maybe<Message>;
  service?: Maybe<ServiceMutations>;
  site?: Maybe<SiteMutations>;
  statement?: Maybe<StatementMutations>;
  statements?: Maybe<StatementsMutations>;
  stripe?: Maybe<StripeMutations>;
  subRequest?: Maybe<SubRequestMutations>;
  tag?: Maybe<TagMutations>;
  timeClock?: Maybe<TimeClockMutations>;
  timeClockShift?: Maybe<TimeClockShiftMutations>;
  updateBranch?: Maybe<Branch>;
  updateContact?: Maybe<Contact>;
  updateInventoryItem?: Maybe<InventoryItem>;
  updateMessage?: Maybe<Message>;
  updateNote?: Maybe<Note>;
  updateOneCustomer?: Maybe<Customer>;
  updateOneEquipment?: Maybe<Equipment>;
  updateOperator?: Maybe<Operator>;
  updateOrder?: Maybe<Order>;
  updateOrganization?: Maybe<Organization>;
  updatePourType?: Maybe<PourType>;
  updateRoute?: Maybe<Route>;
  updateRouteWaypoint?: Maybe<RouteWaypoint>;
  updateSite?: Maybe<Site>;
  updateSupplier?: Maybe<Supplier>;
  updateTerms?: Maybe<Terms>;
  updateUser?: Maybe<User>;
  userRole?: Maybe<UserRoleMutations>;
};


export type MutationAcknowledgeAssignmentArgs = {
  where: AcknowledgeAssignmentInput;
};


export type MutationAcknowledgeMessageArgs = {
  where: AcknowledgeMessageInput;
};


export type MutationAuthorizeArgs = {
  jwt: Scalars['String'];
  scope?: InputMaybe<Scalars['String']>;
};


export type MutationCollectOrderSignatureArgs = {
  data: CollectSignatureInput;
  where: OrderWhereUniqueInput;
};


export type MutationConnectTagsArgs = {
  data: UpdateTagsRelationsDataInput;
  where: Array<TagWhereUniqueInput>;
};


export type MutationCreateBranchArgs = {
  data: BranchInput;
};


export type MutationCreateContactArgs = {
  data: ContactInput;
};


export type MutationCreateInventoryItemArgs = {
  data: InventoryItemCreateInput;
};


export type MutationCreateNoteArgs = {
  data: NoteInput;
};


export type MutationCreateOneCustomerArgs = {
  data: CustomerInput;
};


export type MutationCreateOneEquipmentArgs = {
  data: EquipmentInput;
};


export type MutationCreateOperatorArgs = {
  data: OperatorInput;
};


export type MutationCreateOrderArgs = {
  data: OrderCreateInput;
};


export type MutationCreatePourTypeArgs = {
  data: PourTypeInput;
};


export type MutationCreateSiteArgs = {
  data: SiteInput;
};


export type MutationCreateSupplierArgs = {
  data: SupplierInput;
};


export type MutationCreateUserArgs = {
  data: UserInput;
};


export type MutationDisconnectTagsArgs = {
  data: UpdateTagsRelationsDataInput;
  where: Array<TagWhereUniqueInput>;
};


export type MutationDispatchRouteArgs = {
  where: RouteWhereUniqueInput;
};


export type MutationMergeCustomersArgs = {
  customers: Array<CustomerWhereUniqueInput>;
};


export type MutationNotifyAssignmentArgs = {
  data: NotifyAssignmentInput;
};


export type MutationRequestOrderChangeArgs = {
  data: RequestOrderChangeInput;
  where: OrderWhereUniqueInput;
};


export type MutationRequestSiteLocationArgs = {
  data: RequestSiteLocationInput;
  where: SiteWhereUniqueInput;
};


export type MutationSendOrderMessageArgs = {
  data: SendOrderMessageInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateBranchArgs = {
  data: BranchUpdateInput;
  where: BranchWhereUniqueInput;
};


export type MutationUpdateContactArgs = {
  data: ContactUpdateInput;
  where: ContactWhereUniqueInput;
};


export type MutationUpdateInventoryItemArgs = {
  data: InventoryItemUpdateInput;
  where: InventoryItemWhereUniqueInput;
};


export type MutationUpdateMessageArgs = {
  data: MessageUpdateInput;
  where: MessageWhereUniqueInput;
};


export type MutationUpdateNoteArgs = {
  data: NoteUpdateInput;
  where: NoteWhereUniqueInput;
};


export type MutationUpdateOneCustomerArgs = {
  data: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
};


export type MutationUpdateOneEquipmentArgs = {
  data: EquipmentUpdateInput;
  where: EquipmentWhereUniqueInput;
};


export type MutationUpdateOperatorArgs = {
  data: OperatorUpdateInput;
  where: OperatorWhereUniqueInput;
};


export type MutationUpdateOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateOrganizationArgs = {
  data: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationUpdatePourTypeArgs = {
  data: PourTypeUpdateInput;
  where: PourTypeWhereUniqueInput;
};


export type MutationUpdateRouteArgs = {
  data: RouteUpdateInput;
  where: RouteWhereUniqueInput;
};


export type MutationUpdateRouteWaypointArgs = {
  data: RouteWaypointInput;
  where: RouteWaypointWhereUniqueInput;
};


export type MutationUpdateSiteArgs = {
  data: SiteUpdateInput;
  where: SiteWhereUniqueInput;
};


export type MutationUpdateSupplierArgs = {
  data: SupplierUpdateInput;
  where: SupplierWhereUniqueInput;
};


export type MutationUpdateTermsArgs = {
  data: TermsUpdateInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type NavigableAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedEnumPersonAvailabilityStatusFilter = {
  equals?: InputMaybe<PersonAvailabilityStatus>;
  in?: InputMaybe<Array<PersonAvailabilityStatus>>;
  not?: InputMaybe<NestedEnumPersonAvailabilityStatusFilter>;
  notIn?: InputMaybe<Array<PersonAvailabilityStatus>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Note = {
  __typename?: 'Note';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  comments: Array<Comment>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']>;
  equipment?: Maybe<Equipment>;
  equipmentId?: Maybe<Scalars['Int']>;
  event?: Maybe<Event>;
  id: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NoteInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  comments?: InputMaybe<CommentsInput>;
  contactId?: InputMaybe<Scalars['Int']>;
  customerId?: InputMaybe<Scalars['Int']>;
  equipmentId?: InputMaybe<Scalars['Int']>;
  event?: InputMaybe<EventInput>;
  title: Scalars['String'];
};

export type NoteUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  comments?: InputMaybe<CommentsInput>;
  contactId?: InputMaybe<Scalars['Int']>;
  customerId?: InputMaybe<Scalars['Int']>;
  equipmentId?: InputMaybe<Scalars['Int']>;
  event?: InputMaybe<EventInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type NoteWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type NotifyAssignmentInput = {
  id: Scalars['Int'];
};

export type Operator = PersonInterface & {
  __typename?: 'Operator';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  assignments: Array<Assignment>;
  availability?: Maybe<OperatorAvailability>;
  branches: Array<Branch>;
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  credentials: Array<Credential>;
  emails: Array<Scalars['String']>;
  equipment: Array<Equipment>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  integrations?: Maybe<OperatorIntegrations>;
  lastName?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  organization: Organization;
  phones: Array<Phone>;
  preferences: OperatorPreferences;
  timeClock?: Maybe<TimeClock>;
  timeSheet?: Maybe<TimeSheet>;
  updatedAt: Scalars['DateTime'];
};


export type OperatorAssignmentsArgs = {
  orderBy?: InputMaybe<Array<OperatorAssignmentsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OperatorAssignmentsWhereInput>;
};


export type OperatorCredentialsArgs = {
  orderBy?: InputMaybe<Array<OperatorCredentialsOrderByInput>>;
  where?: InputMaybe<OperatorCredentialsWhereInput>;
};


export type OperatorTimeSheetArgs = {
  where?: InputMaybe<PersonTimeSheetWhereInput>;
};

export type OperatorAssignmentsOrderByInput = {
  id?: InputMaybe<SortOrder>;
};

export type OperatorAssignmentsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type OperatorAvailability = PersonAvailability & {
  __typename?: 'OperatorAvailability';
  additionalInfo?: Maybe<Array<KeyValue>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  locations: Array<PersonAvailabilityLocation>;
  note?: Maybe<Scalars['String']>;
  operator: Operator;
  person: Person;
  personId: Scalars['Int'];
  status: PersonAvailabilityStatus;
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
};

export type OperatorCancelAvailabilityDataInput = {
  /** Indicates what timeslot will merge with the gap being created by canceling the person schedule */
  fillGap: OperatorCancelAvailabilityFillGapEnum;
};

export enum OperatorCancelAvailabilityFillGapEnum {
  Next = 'next',
  Previous = 'previous'
}

export type OperatorCancelAvailabilityResponse = {
  __typename?: 'OperatorCancelAvailabilityResponse';
  deleted: OperatorAvailability;
  updated?: Maybe<OperatorAvailability>;
};

export type OperatorCancelAvailabilityWhereInput = {
  id: Scalars['Int'];
};

export type OperatorCredentialsOrderByInput = {
  expirationDate?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
};

export type OperatorCredentialsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  expirationDate?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
};

export type OperatorInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  branchIds?: InputMaybe<IntArrayInput>;
  credentials?: InputMaybe<CredentialsInput>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<OperatorScheduleLocationInput>>;
  phones?: InputMaybe<Array<PhoneInput>>;
};

export type OperatorIntegrations = {
  __typename?: 'OperatorIntegrations';
  samsara?: Maybe<OperatorSamsaraIntegration>;
};

export type OperatorMutations = {
  __typename?: 'OperatorMutations';
  cancelAvailability: OperatorCancelAvailabilityResponse;
  scheduleAvailability: OperatorScheduleAvailabilityResponse;
};


export type OperatorMutationsCancelAvailabilityArgs = {
  data: OperatorCancelAvailabilityDataInput;
  where: OperatorCancelAvailabilityWhereInput;
};


export type OperatorMutationsScheduleAvailabilityArgs = {
  data: OperatorScheduleAvailabilityDataInput;
  where: OperatorScheduleAvailabilityWhereInput;
};

export type OperatorPreferences = {
  __typename?: 'OperatorPreferences';
  messages: Array<MessagePreference>;
};

export type OperatorSamsaraIntegration = {
  __typename?: 'OperatorSamsaraIntegration';
  driver?: Maybe<SamsaraDriver>;
};

export type OperatorScheduleAvailabilityDataInput = {
  locations: Array<OperatorScheduleLocationInput>;
  note?: InputMaybe<Scalars['String']>;
  selectedTimezone: Scalars['String'];
  status: PersonAvailabilityStatus;
  validFrom: Scalars['DateTime'];
  validTo?: InputMaybe<Scalars['DateTime']>;
};

export type OperatorScheduleAvailabilityResponse = {
  __typename?: 'OperatorScheduleAvailabilityResponse';
  operatorAvailability: Array<OperatorAvailability>;
};

export type OperatorScheduleAvailabilityWhereInput = {
  operatorId: Scalars['Int'];
};

export type OperatorScheduleLocationInput = {
  branchId: Scalars['Int'];
  yardIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type OperatorUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  branchIds?: InputMaybe<IntArrayInput>;
  credentials?: InputMaybe<CredentialsInput>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<OperatorScheduleLocationInput>>;
  phones?: InputMaybe<Array<PhoneInput>>;
};

export type OperatorWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Order = {
  __typename?: 'Order';
  active: Scalars['Boolean'];
  actuals: Array<OrderDetails>;
  additionalIds?: Maybe<Array<KeyValue>>;
  approximateTime: Scalars['Boolean'];
  assignments?: Maybe<Array<Assignment>>;
  billable?: Maybe<OrderDetails>;
  branch?: Maybe<Branch>;
  branchId?: Maybe<Scalars['Int']>;
  bulletinToCustomer?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancellationReasonDetails?: Maybe<OrderCancellationReason>;
  cashOnDelivery: Scalars['Boolean'];
  changelog?: Maybe<Changelog>;
  comments: Array<Comment>;
  commentsCount: Scalars['Int'];
  /** Indicates whether the order can be confirmed by the contact */
  confirmable: Scalars['Boolean'];
  contacts: Array<OrderContact>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']>;
  dateOfService?: Maybe<Scalars['DateTime']>;
  dateOfServiceLocal?: Maybe<Scalars['DateTime']>;
  estimatedJobCost?: Maybe<JobCostEstimate>;
  flatRate: Scalars['Boolean'];
  id: Scalars['Int'];
  instructions?: Maybe<Scalars['String']>;
  integrations?: Maybe<OrderIntegrations>;
  invoice?: Maybe<Invoice>;
  /** @deprecated use service instead */
  invoiceTemplate?: Maybe<Service>;
  /** @deprecated use serviceId instead */
  invoiceTemplateId?: Maybe<Scalars['Int']>;
  linesOnSite: Scalars['Boolean'];
  messages: Array<Message>;
  name: Scalars['String'];
  planned?: Maybe<OrderDetails>;
  purchaseOrder?: Maybe<Scalars['String']>;
  restrictions?: Maybe<OrderRestrictions>;
  revision: Scalars['Int'];
  route?: Maybe<Route>;
  routeWaypoint?: Maybe<RouteWaypoint>;
  routeWaypointId?: Maybe<Scalars['Int']>;
  service?: Maybe<Service>;
  /** @deprecated use service instead */
  serviceCategory?: Maybe<Service>;
  /** @deprecated use serviceId instead */
  serviceCategoryId?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  signature?: Maybe<Signature>;
  signatureId?: Maybe<Scalars['Int']>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  statusDetails: OrderStatus;
  subbingDetails?: Maybe<OrderSubDetail>;
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['Int']>;
  tags: Array<Tag>;
  terms: OrderTerms;
  updatedAt: Scalars['DateTime'];
};


export type OrderMessagesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type OrderActualsInput = {
  upsert?: InputMaybe<Array<OrderActualsUpsertInput>>;
};

export type OrderActualsUpsertInput = {
  data: OrderDetailsUpsertInput;
  where?: InputMaybe<OrderActualsWhereUniqueInput>;
};

export type OrderActualsWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  subType?: InputMaybe<Scalars['String']>;
};

export type OrderCancellationReason = {
  __typename?: 'OrderCancellationReason';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type OrderContact = {
  __typename?: 'OrderContact';
  contact: Contact;
  contactId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  default: Scalars['Boolean'];
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  role?: Maybe<ContactRole>;
  roleId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type OrderContactInput = {
  contactId: Scalars['Int'];
  default?: InputMaybe<Scalars['Boolean']>;
  roleId?: InputMaybe<Scalars['Int']>;
};

export type OrderContactsInput = {
  set?: InputMaybe<Array<OrderContactInput>>;
};

export type OrderCreateInput = {
  actuals?: InputMaybe<OrderActualsInput>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  approximateTime?: InputMaybe<Scalars['Boolean']>;
  billable?: InputMaybe<OrderDetailsUpsertInput>;
  branchId?: InputMaybe<Scalars['Int']>;
  bulletinToCustomer?: InputMaybe<Scalars['String']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  cashOnDelivery?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<CommentsInput>;
  contacts?: InputMaybe<OrderContactsInput>;
  customerId?: InputMaybe<Scalars['Int']>;
  flatRate?: InputMaybe<Scalars['Boolean']>;
  instructions?: InputMaybe<Scalars['String']>;
  invoiceTemplateId?: InputMaybe<Scalars['Int']>;
  linesOnSite?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  planned?: InputMaybe<OrderDetailsUpsertInput>;
  purchaseOrder?: InputMaybe<Scalars['String']>;
  restrictions?: InputMaybe<OrderRestrictionsInput>;
  route?: InputMaybe<OrderRouteInput>;
  serviceCategoryId?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  signatureId?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
  supplierId?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<TagsInput>;
};

export type OrderDetails = {
  __typename?: 'OrderDetails';
  createdAt: Scalars['DateTime'];
  equipment?: Maybe<Equipment>;
  equipmentId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  inventory: Array<OrderInventoryItem>;
  metrics: Array<OrderMetric>;
  schedule: Array<OrderScheduleEntry>;
  subType?: Maybe<Scalars['String']>;
  type: OrderDetailsType;
  updatedAt: Scalars['DateTime'];
};

export enum OrderDetailsType {
  Actual = 'actual',
  Billable = 'billable',
  Planned = 'planned'
}

export type OrderDetailsUpsertInput = {
  equipmentId?: InputMaybe<Scalars['Int']>;
  inventory?: InputMaybe<OrderInventoryInput>;
  metrics?: InputMaybe<OrderMetricsInput>;
  schedule?: InputMaybe<OrderScheduleInput>;
};

export type OrderIntegrations = {
  __typename?: 'OrderIntegrations';
  quickbooks?: Maybe<OrderQuickbooksIntegration>;
};

export type OrderInventoryInput = {
  set?: InputMaybe<Array<OrderInventoryItemInput>>;
};

export type OrderInventoryItem = {
  __typename?: 'OrderInventoryItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  item: InventoryItem;
  itemId: Scalars['Int'];
  orderDetails: OrderDetails;
  orderDetailsId: Scalars['Int'];
  quantity?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type OrderInventoryItemInput = {
  itemId: Scalars['Int'];
  quantity?: InputMaybe<Scalars['Float']>;
};

export type OrderMetric = {
  __typename?: 'OrderMetric';
  createdAt: Scalars['DateTime'];
  key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value?: Maybe<Scalars['MetricValue']>;
  valueFloat?: Maybe<Scalars['Float']>;
  valueString?: Maybe<Scalars['String']>;
};

export type OrderMetricInput = {
  key: Scalars['String'];
  value?: InputMaybe<Scalars['MetricValue']>;
};

export type OrderMetricsInput = {
  upsert?: InputMaybe<Array<OrderMetricInput>>;
};

export type OrderPour = {
  __typename?: 'OrderPour';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<PourType>;
  typeId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type OrderPourInput = {
  name?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

export type OrderQuickbooksIntegration = {
  __typename?: 'OrderQuickbooksIntegration';
  invoice?: Maybe<QuickbooksInvoice>;
};

export type OrderRestrictions = {
  __typename?: 'OrderRestrictions';
  maxSize?: Maybe<Scalars['Float']>;
  minSize?: Maybe<Scalars['Float']>;
};

export type OrderRestrictionsInput = {
  maxSize?: InputMaybe<Scalars['Float']>;
  minSize?: InputMaybe<Scalars['Float']>;
};

export type OrderRouteConnectInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
};

export type OrderRouteInput = {
  connect?: InputMaybe<OrderRouteConnectInput>;
  create?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type OrderScheduleEntry = {
  __typename?: 'OrderScheduleEntry';
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  /** Duration of this step in seconds */
  duration?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['DateTime']>;
  endTimezone?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  metrics: Array<OrderMetric>;
  orderDetails: OrderDetails;
  orderDetailsId: Scalars['Int'];
  pour?: Maybe<OrderPour>;
  scheduleIndex: Scalars['Int'];
  startTime?: Maybe<Scalars['DateTime']>;
  startTimezone?: Maybe<Scalars['String']>;
  step: Scalars['String'];
  stepDetails: ScheduleStep;
  updatedAt: Scalars['DateTime'];
};

export type OrderScheduleEntryInput = {
  address?: InputMaybe<AddressInput>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  metrics?: InputMaybe<OrderMetricsInput>;
  pour?: InputMaybe<OrderPourInput>;
  scheduleIndex?: InputMaybe<Scalars['Int']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  step?: InputMaybe<Scalars['String']>;
};

export type OrderScheduleInput = {
  /** Replace all OrderScheduleEntries with list provided here. */
  set?: InputMaybe<Array<OrderScheduleUpsertInput>>;
};

export type OrderScheduleUpsertInput = {
  data: OrderScheduleEntryInput;
  where?: InputMaybe<OrderScheduleWhereUniqueInput>;
};

export type OrderScheduleWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type OrderService = {
  __typename?: 'OrderService';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  branches: Array<Branch>;
  category: Service;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceTemplate?: Maybe<InvoiceTemplate>;
  metadata?: Maybe<Array<KeyValue>>;
  name: Scalars['String'];
  revision: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  active: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type OrderSubDetail = {
  __typename?: 'OrderSubDetail';
  request: SubRequest;
};

export type OrderTerms = {
  __typename?: 'OrderTerms';
  accepted: Scalars['Boolean'];
  content: Terms;
  id: Scalars['Int'];
  needsAcceptance: Scalars['Boolean'];
  signatures: Array<Signature>;
};

export type OrderUpdateInput = {
  actuals?: InputMaybe<OrderActualsInput>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  approximateTime?: InputMaybe<Scalars['Boolean']>;
  billable?: InputMaybe<OrderDetailsUpsertInput>;
  branchId?: InputMaybe<Scalars['Int']>;
  bulletinToCustomer?: InputMaybe<Scalars['String']>;
  cancellationReason?: InputMaybe<Scalars['String']>;
  cashOnDelivery?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<CommentsInput>;
  contacts?: InputMaybe<OrderContactsInput>;
  customerId?: InputMaybe<Scalars['Int']>;
  flatRate?: InputMaybe<Scalars['Boolean']>;
  instructions?: InputMaybe<Scalars['String']>;
  invoiceTemplateId?: InputMaybe<Scalars['Int']>;
  linesOnSite?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  planned?: InputMaybe<OrderDetailsUpsertInput>;
  purchaseOrder?: InputMaybe<Scalars['String']>;
  restrictions?: InputMaybe<OrderRestrictionsInput>;
  revision: Scalars['Int'];
  route?: InputMaybe<OrderRouteInput>;
  serviceCategoryId?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  signatureId?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<TagsInput>;
};

export type OrderWhereUniqueInput = {
  id: Scalars['Int'];
};

export type OrdersFitCheck = {
  __typename?: 'OrdersFitCheck';
  results?: Maybe<Array<OrdersFitCheckResult>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type OrdersFitCheckResult = {
  __typename?: 'OrdersFitCheckResult';
  incomingTravelDuration?: Maybe<Scalars['Int']>;
  invalidCode?: Maybe<Scalars['String']>;
  invalidDuration?: Maybe<Scalars['Int']>;
  invalidReason?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
  outgoingTravelDuration?: Maybe<Scalars['Int']>;
};

export type Organization = {
  __typename?: 'Organization';
  billingProfile?: Maybe<BillingProfile>;
  id: Scalars['Int'];
  isDemo: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  messaging: Messaging;
  name: Scalars['String'];
  phones: Array<Phone>;
  primaryColor?: Maybe<Scalars['String']>;
  subscription: Subscription;
};

export type OrganizationInput = {
  additionalIds?: InputMaybe<KeyValuesInput>;
  billingProfile?: InputMaybe<BillingProfileInput>;
  logo?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<KeyValuesInput>;
  name: Scalars['String'];
  phones?: InputMaybe<Array<PhoneInput>>;
  primaryColor?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type OrganizationUpdateInput = {
  additionalIds?: InputMaybe<KeyValuesInput>;
  billingProfile?: InputMaybe<BillingProfileInput>;
  logo?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<KeyValuesInput>;
  name?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  primaryColor?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type OrganizationWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  customer: Customer;
  date: Scalars['Date'];
  distributions: Array<PaymentDistribution>;
  id: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  processedDate: Scalars['DateTime'];
  reference?: Maybe<Scalars['String']>;
  type: PaymentType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
};

export type PaymentDistribution = {
  __typename?: 'PaymentDistribution';
  amount: Scalars['Decimal'];
  invoice: Invoice;
};

export type PaymentMutations = {
  __typename?: 'PaymentMutations';
  receiveInvoicePayment: ReceiveInvoicePaymentResponse;
};


export type PaymentMutationsReceiveInvoicePaymentArgs = {
  data: ReceivePaymentInput;
};

export type PaymentType = {
  __typename?: 'PaymentType';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionScope = {
  __typename?: 'PermissionScope';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Person = PersonInterface & {
  __typename?: 'Person';
  createdAt: Scalars['DateTime'];
  emails: Array<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  organization: Organization;
  phones: Array<Phone>;
  updatedAt: Scalars['DateTime'];
};

export type PersonAvailability = {
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  locations: Array<PersonAvailabilityLocation>;
  note?: Maybe<Scalars['String']>;
  person: Person;
  personId: Scalars['Int'];
  status: PersonAvailabilityStatus;
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
};

export type PersonAvailabilityLocation = {
  __typename?: 'PersonAvailabilityLocation';
  address?: Maybe<Address>;
  branch: Branch;
  branchId: Scalars['Int'];
  id: Scalars['Int'];
  yard?: Maybe<Yard>;
  yardId?: Maybe<Scalars['Int']>;
};

export enum PersonAvailabilityStatus {
  Available = 'available',
  Unavailable = 'unavailable'
}

export type PersonInterface = {
  createdAt: Scalars['DateTime'];
  emails: Array<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  messages: Array<Message>;
  organization: Organization;
  phones: Array<Phone>;
  updatedAt: Scalars['DateTime'];
};

export type PersonMutations = {
  __typename?: 'PersonMutations';
  merge: PersonMutationsMergeResponse;
};


export type PersonMutationsMergeArgs = {
  data: PersonMutationsMergeDataInput;
  where: PersonWhereUniqueInput;
};

export type PersonMutationsMergeDataInput = {
  with: Array<PersonWhereUniqueInput>;
};

export type PersonMutationsMergeResponse = {
  __typename?: 'PersonMutationsMergeResponse';
  deletedIds: Array<Scalars['Int']>;
  person: PersonInterface;
};

export type PersonTimeSheetWhereInput = {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type PersonWhereUniqueInput = {
  id: Scalars['Int'];
};

export type Phone = {
  __typename?: 'Phone';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  number: Scalars['String'];
  sms: Scalars['Boolean'];
  type: Scalars['String'];
  typeDetails: PhoneType;
  updatedAt: Scalars['DateTime'];
};

export type PhoneInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  number: Scalars['String'];
  sms?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PhoneType = {
  __typename?: 'PhoneType';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PourType = {
  __typename?: 'PourType';
  /** @deprecated Abbreviation on PourType is deprecated and will always return null */
  abbreviation?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  category?: Maybe<PourType>;
  categoryId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  durationMultiplier?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  isCategory: Scalars['Boolean'];
  name: Scalars['String'];
  pinned: Scalars['Boolean'];
  /** @deprecated Sort on PourType is deprecated and will always return 0 */
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PourTypeInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  categoryId?: InputMaybe<Scalars['Int']>;
  durationMultiplier?: InputMaybe<Scalars['Float']>;
  isCategory?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<KeyValuesInput>;
  name: Scalars['String'];
  pinned?: InputMaybe<Scalars['Boolean']>;
};

export type PourTypeUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  categoryId?: InputMaybe<Scalars['Int']>;
  durationMultiplier?: InputMaybe<Scalars['Float']>;
  isCategory?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<KeyValuesInput>;
  name?: InputMaybe<Scalars['String']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
};

export type PourTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Price = {
  __typename?: 'Price';
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  id: Scalars['Int'];
  inputMode?: Maybe<Scalars['String']>;
  rateDisplay?: Maybe<Scalars['String']>;
  rates: Array<PriceRate>;
  ratesMode: PriceRatesMode;
  taxable: Scalars['Boolean'];
};

export type PriceList = {
  __typename?: 'PriceList';
  active: Scalars['Boolean'];
  attachments: Array<Attachment>;
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  entries: Array<PriceListEntry>;
  id: Scalars['Int'];
  invoiceTerm?: Maybe<InvoiceTerm>;
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  parent?: Maybe<PriceList>;
  revision: Scalars['Int'];
  schedules: Array<PriceListSchedule>;
  tags: Array<Tag>;
  updatedAt: Scalars['DateTime'];
};


export type PriceListEntriesArgs = {
  where: PriceListEntriesWhereInput;
};

export type PriceListCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  attachments?: InputMaybe<AttachmentsInput>;
  description?: InputMaybe<Scalars['String']>;
  invoiceTerm?: InputMaybe<InvoiceTermWhereUniqueInput>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parent?: InputMaybe<PriceListWhereUniqueInput>;
  schedules?: InputMaybe<PriceListSchedulesInput>;
  tags?: InputMaybe<TagsInput>;
};

export type PriceListEntriesInput = {
  delete?: InputMaybe<Array<InvoiceItemWhereUniqueInput>>;
  set?: InputMaybe<Array<PriceListEntryUpsertInput>>;
  upsert?: InputMaybe<Array<PriceListEntryUpsertInput>>;
};

export type PriceListEntriesWhereInput = {
  date: Scalars['DateLike'];
};

export type PriceListEntry = {
  __typename?: 'PriceListEntry';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  item: InvoiceItem;
  price: Price;
  revision: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PriceListEntryUpsertInput = {
  item: InvoiceItemWhereUniqueInput;
  price: PriceUpsertInput;
};

export type PriceListMutations = {
  __typename?: 'PriceListMutations';
  create: PriceListMutationsCreateResponse;
  update: PriceListMutationsUpdateResponse;
};


export type PriceListMutationsCreateArgs = {
  data: PriceListCreateInput;
};


export type PriceListMutationsUpdateArgs = {
  data: PriceListUpdateInput;
  where: PriceListWhereUniqueInput;
};

export type PriceListMutationsCreateResponse = {
  __typename?: 'PriceListMutationsCreateResponse';
  priceList: PriceList;
};

export type PriceListMutationsUpdateResponse = {
  __typename?: 'PriceListMutationsUpdateResponse';
  priceList: PriceList;
};

export type PriceListSchedule = {
  __typename?: 'PriceListSchedule';
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  entries: Array<PriceListEntry>;
  id: Scalars['Int'];
  revision: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['Date'];
  validTo?: Maybe<Scalars['Date']>;
};

export type PriceListScheduleAvailabilityResponse = {
  __typename?: 'PriceListScheduleAvailabilityResponse';
  priceListSchedule: Array<PriceListSchedule>;
};

export type PriceListScheduleCreateInput = {
  entries?: InputMaybe<PriceListEntriesInput>;
  validFrom: Scalars['Date'];
  validTo?: InputMaybe<Scalars['Date']>;
};

export type PriceListScheduleMutations = {
  __typename?: 'PriceListScheduleMutations';
  scheduleAvailability: PriceListScheduleAvailabilityResponse;
};


export type PriceListScheduleMutationsScheduleAvailabilityArgs = {
  data: PriceListScheduleCreateInput;
  where: PriceListScheduleWhereUniqueInput;
};

export type PriceListScheduleUpdateArgs = {
  data: PriceListScheduleUpdateInput;
  where: PriceListScheduleWhereUniqueInput;
};

export type PriceListScheduleUpdateInput = {
  entries?: InputMaybe<PriceListEntriesInput>;
  validFrom?: InputMaybe<Scalars['Date']>;
  validTo?: InputMaybe<Scalars['Date']>;
};

export type PriceListScheduleWhereUniqueInput = {
  id: Scalars['Int'];
  revision?: InputMaybe<Scalars['Int']>;
};

export type PriceListSchedulesInput = {
  create?: InputMaybe<Array<PriceListScheduleCreateInput>>;
  update?: InputMaybe<Array<PriceListScheduleUpdateArgs>>;
};

export type PriceListUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  attachments?: InputMaybe<AttachmentsInput>;
  description?: InputMaybe<Scalars['String']>;
  invoiceTerm?: InputMaybe<InvoiceTermWhereUniqueInput>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<PriceListWhereUniqueInput>;
  schedules?: InputMaybe<PriceListSchedulesInput>;
  tags?: InputMaybe<TagsInput>;
};

export type PriceListWhereUniqueInput = {
  id: Scalars['Int'];
};

export type PriceRate = {
  __typename?: 'PriceRate';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  type: PriceRateType;
  upTo?: Maybe<Scalars['Decimal']>;
};

export enum PriceRateType {
  Flat = 'flat',
  Unit = 'unit'
}

export type PriceRateUpsertInput = {
  amount: Scalars['Decimal'];
  type: PriceRateType;
  upTo?: InputMaybe<Scalars['Decimal']>;
};

export enum PriceRatesMode {
  Graduated = 'graduated',
  Volume = 'volume'
}

export type PriceUpsertInput = {
  currency?: InputMaybe<Scalars['String']>;
  inputMode?: InputMaybe<Scalars['String']>;
  rateDisplay?: InputMaybe<Scalars['String']>;
  rates: Array<PriceRateUpsertInput>;
  ratesMode: PriceRatesMode;
  taxable?: InputMaybe<Scalars['Boolean']>;
};

export type PriceWhereUniqueInput = {
  id: Scalars['Int'];
};

export type QuantityResolver = {
  __typename?: 'QuantityResolver';
  config?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  default?: Maybe<Scalars['Decimal']>;
  id: Scalars['Int'];
  maximum?: Maybe<Scalars['Decimal']>;
  minimum?: Maybe<Scalars['Decimal']>;
  rounding?: Maybe<Rounding>;
  step?: Maybe<Scalars['Decimal']>;
  type: QuantityResolverType;
  updatedAt: Scalars['DateTime'];
};

export type QuantityResolverCreateInput = {
  config?: InputMaybe<Scalars['Json']>;
  default?: InputMaybe<Scalars['Decimal']>;
  maximum?: InputMaybe<Scalars['Decimal']>;
  minimum?: InputMaybe<Scalars['Decimal']>;
  rounding?: InputMaybe<Rounding>;
  step?: InputMaybe<Scalars['Decimal']>;
  type: QuantityResolverType;
};

export enum QuantityResolverType {
  Function = 'function',
  Static = 'static'
}

export type QuantityResolverUpdateInput = {
  config?: InputMaybe<Scalars['Json']>;
  default?: InputMaybe<Scalars['Decimal']>;
  maximum?: InputMaybe<Scalars['Decimal']>;
  minimum?: InputMaybe<Scalars['Decimal']>;
  rounding?: InputMaybe<Rounding>;
  step?: InputMaybe<Scalars['Decimal']>;
  type?: InputMaybe<QuantityResolverType>;
};

export type QuantityResolverUpdateRelationInput = {
  data: QuantityResolverUpdateInput;
  where: QuantityResolverWhereUniqueInput;
};

export type QuantityResolverWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QuantityResolversItemInput = {
  create?: InputMaybe<QuantityResolverCreateInput>;
  disconnect?: InputMaybe<QuantityResolverWhereUniqueInput>;
  update?: InputMaybe<QuantityResolverUpdateRelationInput>;
};

export type Query = {
  __typename?: 'Query';
  accountsReceivableReport?: Maybe<AccountsReceivableReport>;
  addresses: Array<Address>;
  assignment?: Maybe<Assignment>;
  assignmentRoles: Array<AssignmentRole>;
  assignments: Array<Assignment>;
  availableTeam: Array<TeamMemberAvailability>;
  branches: Array<Branch>;
  changelog?: Maybe<Changelog>;
  checkOrdersFitInRoute: OrdersFitCheck;
  checkWaypointsFitInRoute: WaypointsFitCheck;
  contact?: Maybe<Contact>;
  contactRoles: Array<ContactRole>;
  contacts: Array<Contact>;
  credentialTypes: Array<CredentialType>;
  credentials: Array<Credential>;
  customer?: Maybe<Customer>;
  customers: Array<Customer>;
  documentTypes: Array<DocumentType>;
  equipment: Array<Equipment>;
  /** Get a set of Availabilities for a equipment */
  equipmentAvailability: Array<EquipmentAvailabilitySnapshot>;
  /** Get one Equipment Availability by ID */
  equipmentAvailabilityRecord: EquipmentAvailability;
  equipmentGroup: EquipmentGroup;
  equipmentGroups: Array<EquipmentGroup>;
  equipmentType: EquipmentType;
  equipmentTypes: Array<EquipmentType>;
  estimateInvoice?: Maybe<InvoiceEstimate>;
  events: Array<Event>;
  insightsDashboards: Array<InsightsDashboard>;
  insightsUrl?: Maybe<InsightsUrl>;
  integrations?: Maybe<Integrations>;
  inventory: Array<InventoryItem>;
  invoice?: Maybe<Invoice>;
  invoiceByNumber?: Maybe<Invoice>;
  invoiceItem: InvoiceItem;
  invoiceItems: Array<InvoiceItem>;
  invoiceStatuses: Array<InvoiceStatus>;
  invoiceTemplateLineGroup: InvoiceTemplateLineGroup;
  invoiceTemplateLineGroups: Array<InvoiceTemplateLineGroup>;
  /** @deprecated use services instead */
  invoiceTemplates: Array<Service>;
  invoiceTerms: Array<InvoiceTerm>;
  invoices: Array<Invoice>;
  me?: Maybe<PersonInterface>;
  notes: Array<Note>;
  operator?: Maybe<Operator>;
  /** Get Availabilities for Operators */
  operatorAvailabilities: Array<OperatorAvailability>;
  /** Get a Operator Availability by ID */
  operatorAvailability: OperatorAvailability;
  operators: Array<Operator>;
  order?: Maybe<Order>;
  orderCancellationReasons: Array<OrderCancellationReason>;
  orderStatuses: Array<OrderStatus>;
  orders: Array<Order>;
  organization?: Maybe<Organization>;
  payment?: Maybe<Payment>;
  paymentTypes: Array<PaymentType>;
  payments: Array<Payment>;
  phoneTypes: Array<PhoneType>;
  pourTypes: Array<PourType>;
  priceList?: Maybe<PriceList>;
  priceLists: Array<PriceList>;
  relevantBranches: Array<Branch>;
  relevantPriceLists: Array<PriceList>;
  route?: Maybe<Route>;
  routeWaypoints: Array<RouteWaypoint>;
  routes: Array<Route>;
  scheduleSteps: Array<ScheduleStep>;
  /** @deprecated use services instead */
  serviceCategories?: Maybe<Array<Service>>;
  services: Array<Service>;
  shortLink?: Maybe<ShortLink>;
  site?: Maybe<Site>;
  sites: Array<Site>;
  statementBatch?: Maybe<StatementBatch>;
  stripe?: Maybe<Stripe>;
  subEntities: Array<SubEntity>;
  suppliers: Array<Supplier>;
  tag: Tag;
  tags: Array<Tag>;
  terms?: Maybe<Terms>;
  timeClock?: Maybe<TimeClock>;
  timeClockShift?: Maybe<TimeClockShift>;
  timeClockTasks: Array<TimeClockTask>;
  timeSheets: Array<TimeSheet>;
  user?: Maybe<User>;
  userPermissionScopes: Array<UserPermission>;
  userRole?: Maybe<UserRole>;
  userRoles: Array<UserRole>;
  users: Array<User>;
};


export type QueryAccountsReceivableReportArgs = {
  where?: InputMaybe<AccountsReceivableReportWhere>;
};


export type QueryAddressesArgs = {
  orderBy?: InputMaybe<Array<QueryAddressesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryAddressesWhereInput>;
};


export type QueryAssignmentArgs = {
  id: Scalars['Int'];
};


export type QueryAssignmentRolesArgs = {
  where?: InputMaybe<QueryAssignmentRolesWhereInput>;
};


export type QueryAssignmentsArgs = {
  orderBy?: InputMaybe<Array<QueryAssignmentsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryAssignmentsWhereInput>;
};


export type QueryAvailableTeamArgs = {
  where: AvailableTeamWhereInput;
};


export type QueryBranchesArgs = {
  where?: InputMaybe<QueryBranchesWhereInput>;
};


export type QueryChangelogArgs = {
  where: ChangelogWhereInput;
};


export type QueryCheckOrdersFitInRouteArgs = {
  where?: InputMaybe<CheckOrdersFitInRouteInput>;
};


export type QueryCheckWaypointsFitInRouteArgs = {
  where?: InputMaybe<CheckWaypointsFitInRouteInput>;
};


export type QueryContactArgs = {
  id: Scalars['Int'];
};


export type QueryContactRolesArgs = {
  where?: InputMaybe<QueryContactRolesWhereInput>;
};


export type QueryContactsArgs = {
  orderBy?: InputMaybe<Array<QueryContactsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryContactsWhereInput>;
};


export type QueryCredentialTypesArgs = {
  where?: InputMaybe<QueryCredentialTypesWhereInput>;
};


export type QueryCredentialsArgs = {
  orderBy?: InputMaybe<Array<QueryCredentialsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryCredentialsWhereInput>;
};


export type QueryCustomerArgs = {
  id: Scalars['Int'];
};


export type QueryCustomersArgs = {
  orderBy?: InputMaybe<Array<QueryCustomersOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryCustomersWhereInput>;
};


export type QueryDocumentTypesArgs = {
  where?: InputMaybe<DocumentTypesWhereInput>;
};


export type QueryEquipmentArgs = {
  orderBy?: InputMaybe<Array<QueryEquipmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryEquipmentWhereInput>;
};


export type QueryEquipmentAvailabilityArgs = {
  orderBy?: InputMaybe<Array<QueryEquipmentAvailabilitiesOrderByInput>>;
  where?: InputMaybe<QueryEquipmentAvailabilitiesWhereInput>;
};


export type QueryEquipmentAvailabilityRecordArgs = {
  id: Scalars['Int'];
};


export type QueryEquipmentGroupArgs = {
  id: Scalars['Int'];
};


export type QueryEquipmentGroupsArgs = {
  where?: InputMaybe<QueryEquipmentGroupsWhereInput>;
};


export type QueryEquipmentTypeArgs = {
  id: Scalars['Int'];
};


export type QueryEquipmentTypesArgs = {
  where?: InputMaybe<QueryEquipmentTypesWhereInput>;
};


export type QueryEstimateInvoiceArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryEventsArgs = {
  orderBy?: InputMaybe<Array<QueryEventsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryEventsWhereInput>;
};


export type QueryInsightsDashboardsArgs = {
  where?: InputMaybe<InsightsDashboardsInput>;
};


export type QueryInsightsUrlArgs = {
  where?: InputMaybe<InsightsUrlInput>;
};


export type QueryInventoryArgs = {
  where?: InputMaybe<QueryInventoryItemsWhereInput>;
};


export type QueryInvoiceArgs = {
  id: Scalars['String'];
};


export type QueryInvoiceByNumberArgs = {
  invoiceNumber: Scalars['String'];
};


export type QueryInvoiceItemArgs = {
  id: Scalars['Int'];
};


export type QueryInvoiceItemsArgs = {
  orderBy?: InputMaybe<Array<QueryInvoiceItemsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryInvoiceItemsWhereInput>;
};


export type QueryInvoiceStatusesArgs = {
  where?: InputMaybe<QueryInvoiceStatusesWhereInput>;
};


export type QueryInvoiceTemplateLineGroupArgs = {
  id: Scalars['Int'];
};


export type QueryInvoiceTemplateLineGroupsArgs = {
  orderBy?: InputMaybe<Array<QueryInvoiceTemplateLineGroupsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryInvoiceTemplateLineGroupsWhereInput>;
};


export type QueryInvoiceTemplatesArgs = {
  orderBy?: InputMaybe<Array<QueryInvoiceTemplatesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryInvoiceTemplatesWhereInput>;
};


export type QueryInvoicesArgs = {
  orderBy?: InputMaybe<Array<QueryInvoicesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryInvoicesWhereInput>;
};


export type QueryNotesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryNotesWhereInput>;
};


export type QueryOperatorArgs = {
  id: Scalars['Int'];
};


export type QueryOperatorAvailabilitiesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryPersonAvailabilitiesWhereInput>;
};


export type QueryOperatorAvailabilityArgs = {
  id: Scalars['Int'];
};


export type QueryOperatorsArgs = {
  orderBy?: InputMaybe<Array<QueryOperatorsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryOperatorsWhereInput>;
};


export type QueryOrderArgs = {
  id: Scalars['Int'];
};


export type QueryOrderCancellationReasonsArgs = {
  where?: InputMaybe<QueryOrderCancellationReasonsWhereInput>;
};


export type QueryOrderStatusesArgs = {
  where?: InputMaybe<QueryOrderStatusesWhereInput>;
};


export type QueryOrdersArgs = {
  orderBy?: InputMaybe<Array<QueryOrdersOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryOrdersWhereInput>;
};


export type QueryPaymentArgs = {
  id: Scalars['Int'];
};


export type QueryPaymentTypesArgs = {
  where?: InputMaybe<QueryPaymentTypesWhereInput>;
};


export type QueryPaymentsArgs = {
  orderBy?: InputMaybe<Array<QueryPaymentsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryPaymentsWhereInput>;
};


export type QueryPhoneTypesArgs = {
  where?: InputMaybe<QueryPhoneTypesWhereInput>;
};


export type QueryPourTypesArgs = {
  where?: InputMaybe<QueryPourTypesWhereInput>;
};


export type QueryPriceListArgs = {
  id: Scalars['Int'];
};


export type QueryPriceListsArgs = {
  orderBy?: InputMaybe<Array<QueryPriceListsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryPriceListsWhereInput>;
};


export type QueryRelevantPriceListsArgs = {
  where: RelevantPriceListWhereInput;
};


export type QueryRouteArgs = {
  id: Scalars['Int'];
};


export type QueryRouteWaypointsArgs = {
  orderBy?: InputMaybe<Array<QueryRouteWaypointsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryRouteWaypointsWhereInput>;
};


export type QueryRoutesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryRoutesWhereInput>;
};


export type QueryScheduleStepsArgs = {
  where?: InputMaybe<QueryScheduleStepsWhereInput>;
};


export type QueryServiceCategoriesArgs = {
  orderBy?: InputMaybe<Array<QueryServiceCategoriesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryServiceCategoriesWhereInput>;
};


export type QueryServicesArgs = {
  orderBy?: InputMaybe<Array<QueryServicesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryServicesWhereInput>;
};


export type QueryShortLinkArgs = {
  where: QueryShortLinkWhereInput;
};


export type QuerySiteArgs = {
  id: Scalars['Int'];
};


export type QuerySitesArgs = {
  orderBy?: InputMaybe<Array<QuerySitesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuerySitesWhereInput>;
};


export type QueryStatementBatchArgs = {
  id: Scalars['Int'];
};


export type QuerySuppliersArgs = {
  where?: InputMaybe<QuerySuppliersWhereInput>;
};


export type QueryTagArgs = {
  id: Scalars['Int'];
};


export type QueryTagsArgs = {
  orderBy?: InputMaybe<Array<QueryTagsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryTagsWhereInput>;
};


export type QueryTimeClockArgs = {
  id: Scalars['Int'];
};


export type QueryTimeClockShiftArgs = {
  id: Scalars['Int'];
};


export type QueryTimeClockTasksArgs = {
  orderBy?: InputMaybe<Array<QueryTimeClockTasksOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryTimeClockTasksWhereInput>;
};


export type QueryTimeSheetsArgs = {
  where: TimeSheetsWhereInput;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUserRoleArgs = {
  id: Scalars['Int'];
};


export type QueryUserRolesArgs = {
  orderBy?: InputMaybe<Array<QueryUserRolesOrderByInput>>;
  where?: InputMaybe<QueryUserRolesWhereInput>;
};


export type QueryUsersArgs = {
  orderBy?: InputMaybe<Array<QueryUsersOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryUsersWhereInput>;
};

export type QueryAddressesOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryAddressesWhereInput = {
  id?: InputMaybe<IntFilter>;
};

export type QueryAssignmentRolesWhereInput = {
  active?: InputMaybe<BoolFilter>;
};

export type QueryAssignmentsOrderByInput = {
  id?: InputMaybe<SortOrder>;
};

export type QueryAssignmentsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  visible?: InputMaybe<BoolFilter>;
};

export type QueryBranchesWhereInput = {
  id?: InputMaybe<IntFilter>;
};

export type QueryContactRolesWhereInput = {
  active?: InputMaybe<BoolFilter>;
};

export type QueryContactsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryContactsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  phone?: InputMaybe<StringFilter>;
};

export type QueryCredentialTypesWhereInput = {
  active?: InputMaybe<BoolFilter>;
};

export type QueryCredentialsOrderByInput = {
  expirationDate?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
};

export type QueryCredentialsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  expirationDate?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryCustomersOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryCustomersWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryEquipmentAvailabilitiesOrderByInput = {
  id?: InputMaybe<SortOrder>;
};

export type QueryEquipmentAvailabilitiesWhereInput = {
  branchId?: InputMaybe<IntFilter>;
  equipmentId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  validFrom?: InputMaybe<DateTimeFilter>;
  validTo?: InputMaybe<DateTimeNullableFilter>;
};

export type QueryEquipmentGroupsWhereInput = {
  name?: InputMaybe<StringFilter>;
};

export type QueryEquipmentOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sort?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryEquipmentTypesWhereInput = {
  active?: InputMaybe<BoolFilter>;
};

export type QueryEquipmentWhereInput = {
  active?: InputMaybe<BoolFilter>;
  branchId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  type?: InputMaybe<StringFilter>;
};

export type QueryEventsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryEventsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  branchId: Scalars['Int'];
  /** YYYY-MM-DD date string to view events on */
  date?: InputMaybe<Scalars['String']>;
};

export type QueryInventoryItemsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  branchId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryInvoiceItemsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryInvoiceItemsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  category?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type QueryInvoiceStatusesWhereInput = {
  active?: InputMaybe<BoolFilter>;
};

export type QueryInvoiceTemplateLineGroupsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryInvoiceTemplateLineGroupsWhereInput = {
  id?: InputMaybe<IntFilter>;
};

export type QueryInvoiceTemplatesOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type QueryInvoiceTemplatesWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryInvoicesOrderByInput = {
  amountDue?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dueDate?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  invoiceDate?: InputMaybe<SortOrder>;
};

export type QueryInvoicesWhereInput = {
  branchId?: InputMaybe<IntFilter>;
  customerId?: InputMaybe<IntNullableFilter>;
  dueDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoiceDate?: InputMaybe<DateTimeFilter>;
  linkedAccountId?: InputMaybe<Scalars['Int']>;
  number?: InputMaybe<StringFilter>;
  serviceId?: InputMaybe<IntFilter>;
  siteId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<StringFilter>;
  tagId?: InputMaybe<IntFilter>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type QueryNotesWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryOperatorsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryOperatorsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  branchId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryOrderCancellationReasonsWhereInput = {
  active?: InputMaybe<BoolFilter>;
};

export type QueryOrderStatusesWhereInput = {
  active?: InputMaybe<BoolFilter>;
};

export type QueryOrdersOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  dateOfService?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryOrdersWhereInput = {
  active?: InputMaybe<BoolFilter>;
  branchId?: InputMaybe<IntNullableFilter>;
  confirmable?: InputMaybe<BoolFilter>;
  contactId?: InputMaybe<IntFilter>;
  customerId?: InputMaybe<IntNullableFilter>;
  dateOfService?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  linkedAccountId?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<IntNullableFilter>;
  siteId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<StringFilter>;
  tagId?: InputMaybe<IntFilter>;
};

export type QueryPaymentTypesWhereInput = {
  active?: InputMaybe<BoolFilter>;
};

export type QueryPaymentsOrderByInput = {
  amount?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type QueryPaymentsWhereInput = {
  amount?: InputMaybe<DecimalFilter>;
  customerId?: InputMaybe<IntFilter>;
  date?: InputMaybe<DateTimeFilter>;
  method?: InputMaybe<StringFilter>;
  reference?: InputMaybe<StringNullableFilter>;
};

export type QueryPersonAvailabilitiesWhereInput = {
  personId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumPersonAvailabilityStatusFilter>;
  validFrom?: InputMaybe<DateTimeFilter>;
  validTo?: InputMaybe<DateTimeNullableFilter>;
};

export type QueryPhoneTypesWhereInput = {
  active?: InputMaybe<BoolFilter>;
};

export type QueryPourTypesWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  isCategory?: InputMaybe<BoolFilter>;
};

export type QueryPriceListsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryPriceListsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryRouteWaypointsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  scheduledArrivalTime?: InputMaybe<SortOrderInput>;
  scheduledDepartureTime?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryRouteWaypointsWhereInput = {
  addressId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryRoutesWhereInput = {
  active?: InputMaybe<BoolFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  equipmentId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  occursNearTime?: InputMaybe<Scalars['DateTime']>;
  startTime?: InputMaybe<DateTimeFilter>;
};

export type QueryScheduleStepsWhereInput = {
  active?: InputMaybe<BoolFilter>;
};

export type QueryServiceCategoriesOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type QueryServiceCategoriesWhereInput = {
  active?: InputMaybe<BoolFilter>;
  branchId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryServicesOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type QueryServicesWhereInput = {
  active?: InputMaybe<BoolFilter>;
  branchId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryShortLinkWhereInput = {
  slug: Scalars['String'];
};

export type QuerySitesOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuerySitesWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QuerySuppliersWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryTagsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  path?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryTagsWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  path?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
};

export type QueryTimeClockTasksOrderByInput = {
  id?: InputMaybe<SortOrder>;
};

export type QueryTimeClockTasksWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  scopes?: InputMaybe<EnumTimeClockTaskScopeNullableListFilter>;
};

export type QueryUserRolesOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryUserRolesWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
};

export type QueryUsersOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QueryUsersWhereInput = {
  active?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
};

export type Quickbooks = {
  __typename?: 'Quickbooks';
  customers?: Maybe<Array<QuickbooksCustomer>>;
  items?: Maybe<QuickbooksItems>;
  name?: Maybe<Scalars['String']>;
  realmId?: Maybe<Scalars['String']>;
  settings?: Maybe<QuickbooksSettings>;
};

export type QuickbooksCustomer = {
  __typename?: 'QuickbooksCustomer';
  active: Scalars['Boolean'];
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  revision: Scalars['String'];
};

export type QuickbooksInvoice = {
  __typename?: 'QuickbooksInvoice';
  id: Scalars['String'];
};

export type QuickbooksItem = {
  __typename?: 'QuickbooksItem';
  active: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  revision: Scalars['String'];
};

export type QuickbooksItems = {
  __typename?: 'QuickbooksItems';
  all: Array<QuickbooksItem>;
  linkable: Array<QuickbooksLinkableItem>;
};

export type QuickbooksLinkableItem = {
  __typename?: 'QuickbooksLinkableItem';
  itemId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type QuickbooksMutationNamespace = {
  __typename?: 'QuickbooksMutationNamespace';
  createCustomer?: Maybe<Customer>;
  createInvoice?: Maybe<GenericResponse>;
  generateInvoicePdfLink: Scalars['String'];
  linkCustomer: Customer;
  linkItem?: Maybe<QuickbooksLinkableItem>;
  syncCustomer?: Maybe<GenericResponse>;
};


export type QuickbooksMutationNamespaceCreateCustomerArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  where: CustomerWhereUniqueInput;
};


export type QuickbooksMutationNamespaceCreateInvoiceArgs = {
  forceRecreate?: InputMaybe<Scalars['Boolean']>;
  where: OrderWhereUniqueInput;
};


export type QuickbooksMutationNamespaceGenerateInvoicePdfLinkArgs = {
  where: OrderWhereUniqueInput;
};


export type QuickbooksMutationNamespaceLinkCustomerArgs = {
  customer: CustomerWhereUniqueInput;
  quickbooksId?: InputMaybe<Scalars['String']>;
};


export type QuickbooksMutationNamespaceLinkItemArgs = {
  itemId?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};


export type QuickbooksMutationNamespaceSyncCustomerArgs = {
  where: CustomerWhereUniqueInput;
};

export type QuickbooksSettings = {
  __typename?: 'QuickbooksSettings';
  customTxnNumberFormat?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  poNumberCustomField?: Maybe<Scalars['String']>;
  syncCustomers: Scalars['Boolean'];
  syncInvoices: Scalars['Boolean'];
  vulcanOrderLinkCustomField?: Maybe<Scalars['String']>;
};

export type ReceiveInvoicePaymentResponse = {
  __typename?: 'ReceiveInvoicePaymentResponse';
  payment: Payment;
};

export type ReceivePaymentInput = {
  amount: Scalars['Decimal'];
  applyAdjustment?: Scalars['Boolean'];
  currency: Scalars['String'];
  customerId: Scalars['Int'];
  date: Scalars['DateLike'];
  distributions: Array<DistributedPaymentInput>;
  reference?: InputMaybe<Scalars['String']>;
  txId?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

/** additionaly security mode for refresh token CSRF: can be https (httpOnly, secure), csrf (fingerprint), none */
export enum RefreshTokenCookieMode {
  Csrf = 'csrf',
  Https = 'https',
  None = 'none'
}

export type RelevantPriceListWhereInput = {
  branchId?: InputMaybe<Scalars['Int']>;
  customerId?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['Date']>;
  datetime?: InputMaybe<Scalars['DateTime']>;
  siteId?: InputMaybe<Scalars['Int']>;
};

export type RequestChangesResponse = {
  __typename?: 'RequestChangesResponse';
  order: Order;
  success?: Maybe<Scalars['Boolean']>;
};

export type RequestOrderChangeInput = {
  change: Scalars['String'];
};

export type RequestSiteLocationInput = {
  forceResend?: InputMaybe<Scalars['Boolean']>;
  personId?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
};

export enum Rounding {
  Ceil = 'ceil',
  Down = 'down',
  Floor = 'floor',
  HalfCeil = 'halfCeil',
  HalfDown = 'halfDown',
  HalfEven = 'halfEven',
  HalfFloor = 'halfFloor',
  HalfUp = 'halfUp',
  Up = 'up'
}

export type Route = {
  __typename?: 'Route';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  assignments: Array<Assignment>;
  branch: Branch;
  branchId: Scalars['Int'];
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  dispatched?: Maybe<Scalars['Boolean']>;
  dispatchedAt?: Maybe<Scalars['DateTime']>;
  endTime: Scalars['DateTime'];
  endTimeLocal: Scalars['DateTime'];
  endTimezone?: Maybe<Scalars['String']>;
  equipment?: Maybe<Equipment>;
  equipmentId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** @deprecated use Order.instructions instead */
  instructions?: Maybe<Scalars['String']>;
  integrations?: Maybe<RouteIntegrations>;
  revision: Scalars['Int'];
  startTime: Scalars['DateTime'];
  startTimeLocal: Scalars['DateTime'];
  startTimezone?: Maybe<Scalars['String']>;
  status: RouteStatus;
  updatedAt: Scalars['DateTime'];
  waypoints: Array<RouteWaypoint>;
};

export type RouteDispatchInput = {
  id: Array<Scalars['Int']>;
};

export type RouteIntegrations = {
  __typename?: 'RouteIntegrations';
  samsara?: Maybe<RouteSamsaraIntegration>;
};

export type RouteMutations = {
  __typename?: 'RouteMutations';
  dispatchMany?: Maybe<RouteMutationsDispatchResponse>;
  updateMany?: Maybe<RouteMutationsUpdateResponse>;
};


export type RouteMutationsDispatchManyArgs = {
  where: RouteDispatchInput;
};


export type RouteMutationsUpdateManyArgs = {
  input: Array<RouteUpdateManyInput>;
};

export type RouteMutationsDispatchResponse = {
  __typename?: 'RouteMutationsDispatchResponse';
  routes: Array<Route>;
};

export type RouteMutationsUpdateResponse = {
  __typename?: 'RouteMutationsUpdateResponse';
  routes: Array<Route>;
};

export type RouteSamsaraIntegration = {
  __typename?: 'RouteSamsaraIntegration';
  route?: Maybe<SamsaraRoute>;
};

export enum RouteStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  InProgress = 'inProgress',
  Scheduled = 'scheduled'
}

export type RouteUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  assignments?: InputMaybe<AssignmentsInput>;
  branchId?: InputMaybe<Scalars['Int']>;
  equipmentId?: InputMaybe<Scalars['Int']>;
  revision?: InputMaybe<Scalars['Int']>;
  waypoints?: InputMaybe<RouteWaypointsInput>;
};

export type RouteUpdateManyInput = {
  data: RouteUpdateInput;
  where: RouteWhereUniqueInput;
};

export type RouteWaypoint = {
  __typename?: 'RouteWaypoint';
  actualArrivalTime?: Maybe<Scalars['DateTime']>;
  actualArrivalTimeLocal?: Maybe<Scalars['DateTime']>;
  actualDepartureTime?: Maybe<Scalars['DateTime']>;
  actualDepartureTimeLocal?: Maybe<Scalars['DateTime']>;
  address: Address;
  addressId: Scalars['Int'];
  arrivalPerformance?: Maybe<RouteWaypointPerformance>;
  createdAt: Scalars['DateTime'];
  departurePerformance?: Maybe<RouteWaypointPerformance>;
  estimatedArrivalTime?: Maybe<Scalars['DateTime']>;
  estimatedArrivalTimeLocal?: Maybe<Scalars['DateTime']>;
  estimatedDepartureTime?: Maybe<Scalars['DateTime']>;
  estimatedDepartureTimeLocal?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  metadata?: Maybe<Array<KeyValue>>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  route: Route;
  routeId: Scalars['Int'];
  scheduledArrivalTime?: Maybe<Scalars['DateTime']>;
  scheduledArrivalTimeLocal?: Maybe<Scalars['DateTime']>;
  scheduledDepartureTime?: Maybe<Scalars['DateTime']>;
  scheduledDepartureTimeLocal?: Maybe<Scalars['DateTime']>;
  status: RouteWaypointStatus;
  updatedAt: Scalars['DateTime'];
};

export type RouteWaypointInput = {
  actualArrivalTime?: InputMaybe<Scalars['DateTime']>;
  actualDepartureTime?: InputMaybe<Scalars['DateTime']>;
  addressId?: InputMaybe<Scalars['Int']>;
  estimatedArrivalTime?: InputMaybe<Scalars['DateTime']>;
  metadata?: InputMaybe<KeyValuesInput>;
  orderId?: InputMaybe<Scalars['Int']>;
  scheduledArrivalTime?: InputMaybe<Scalars['DateTime']>;
  scheduledDepartureTime?: InputMaybe<Scalars['DateTime']>;
};

export enum RouteWaypointPerformance {
  Late = 'late',
  OnTime = 'onTime',
  VeryLate = 'veryLate'
}

export enum RouteWaypointStatus {
  Completed = 'completed',
  EnRoute = 'enRoute',
  InProgress = 'inProgress',
  Scheduled = 'scheduled'
}

export type RouteWaypointUpsertInput = {
  data?: InputMaybe<RouteWaypointInput>;
  where?: InputMaybe<RouteWaypointWhereUniqueInput>;
};

export type RouteWaypointWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type RouteWaypointsInput = {
  set?: InputMaybe<Array<RouteWaypointInput>>;
  upsert?: InputMaybe<Array<RouteWaypointUpsertInput>>;
};

export type RouteWhereUniqueInput = {
  id: Scalars['Int'];
  revision?: InputMaybe<Scalars['Int']>;
};

export type Samsara = {
  __typename?: 'Samsara';
  accessToken?: Maybe<IntegrationToken>;
  drivers: Array<SamsaraDriver>;
  settings?: Maybe<SamsaraSettings>;
  vehicles: Array<SamsaraVehicle>;
};

export type SamsaraDriver = {
  __typename?: 'SamsaraDriver';
  driverActivationStatus?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type SamsaraMutationNamespace = {
  __typename?: 'SamsaraMutationNamespace';
  connect: GenericResponse;
  linkDriver: Operator;
  linkRoute: Route;
  linkVehicle: Equipment;
};


export type SamsaraMutationNamespaceConnectArgs = {
  token: Scalars['String'];
};


export type SamsaraMutationNamespaceLinkDriverArgs = {
  driverId?: InputMaybe<Scalars['String']>;
  operator: OperatorWhereUniqueInput;
};


export type SamsaraMutationNamespaceLinkRouteArgs = {
  route: RouteWhereUniqueInput;
  samsaraRouteId?: InputMaybe<Scalars['String']>;
};


export type SamsaraMutationNamespaceLinkVehicleArgs = {
  equipment: EquipmentWhereUniqueInput;
  vehicleId?: InputMaybe<Scalars['String']>;
};

export type SamsaraRoute = {
  __typename?: 'SamsaraRoute';
  id: Scalars['String'];
};

export enum SamsaraRouteAssignmentTarget {
  Driver = 'driver',
  Vehicle = 'vehicle'
}

export type SamsaraSettings = {
  __typename?: 'SamsaraSettings';
  autoAssignDriversToVehicles: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  organizationId: Scalars['String'];
  routeAssignmentTarget: SamsaraRouteAssignmentTarget;
};

export type SamsaraVehicle = {
  __typename?: 'SamsaraVehicle';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  vin?: Maybe<Scalars['String']>;
};

export type ScheduleStep = {
  __typename?: 'ScheduleStep';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type SendEmailInput = {
  forceResend?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  url: Scalars['String'];
  urlWorkTicket?: InputMaybe<Scalars['String']>;
};

export type SendEmailResponse = {
  __typename?: 'SendEmailResponse';
  success: Scalars['Boolean'];
};

export type SendOrderMessageInput = {
  forceResend?: InputMaybe<Scalars['Boolean']>;
  type: Scalars['String'];
};

export type Service = {
  __typename?: 'Service';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  branches: Array<Branch>;
  category?: Maybe<Scalars['String']>;
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceTemplate?: Maybe<InvoiceTemplate>;
  metadata?: Maybe<Array<KeyValue>>;
  name: Scalars['String'];
  revision: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type ServiceCategoryWhereUniqueInput = {
  id: Scalars['Int'];
  revision?: InputMaybe<Scalars['Int']>;
};

export type ServiceCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  branches?: InputMaybe<Array<BranchWhereUniqueInput>>;
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  invoiceTemplate: InvoiceTemplateRelationInput;
  metadata?: InputMaybe<KeyValuesInput>;
  name: Scalars['String'];
};

export type ServiceMutations = {
  __typename?: 'ServiceMutations';
  create: ServiceMutationsCreateResponse;
  update: ServiceMutationsUpdateResponse;
};


export type ServiceMutationsCreateArgs = {
  data: ServiceCreateInput;
};


export type ServiceMutationsUpdateArgs = {
  data: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceMutationsCreateResponse = {
  __typename?: 'ServiceMutationsCreateResponse';
  service: Service;
};

export type ServiceMutationsUpdateResponse = {
  __typename?: 'ServiceMutationsUpdateResponse';
  service: Service;
};

export type ServiceUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  branches?: InputMaybe<Array<BranchWhereUniqueInput>>;
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  invoiceTemplate?: InputMaybe<InvoiceTemplateRelationInput>;
  metadata?: InputMaybe<KeyValuesInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type ServiceWhereUniqueInput = {
  id: Scalars['Int'];
  revision?: InputMaybe<Scalars['Int']>;
};

export type ShortLink = {
  __typename?: 'ShortLink';
  active: Scalars['Boolean'];
  authorization?: Maybe<Authorization>;
  createdAt: Scalars['DateTime'];
  expires?: Maybe<Scalars['DateTime']>;
  redirect: Scalars['String'];
  slug: Scalars['String'];
  sub?: Maybe<Scalars['Int']>;
  tid: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type Signature = {
  __typename?: 'Signature';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  image: Scalars['String'];
  metadata?: Maybe<Array<KeyValue>>;
  person?: Maybe<Person>;
  personId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type Site = {
  __typename?: 'Site';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  address: Address;
  addressId: Scalars['Int'];
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['Int'];
  defaultPriceList?: Maybe<PriceList>;
  emails: Array<Scalars['String']>;
  id: Scalars['Int'];
  jobId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phones: Array<Phone>;
  priceLists: Array<PriceList>;
  tags: Array<Tag>;
  updatedAt: Scalars['DateTime'];
  verified: Scalars['Boolean'];
};

export type SiteInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  address: NavigableAddressInput;
  customerId: Scalars['Int'];
  defaultPriceList?: InputMaybe<PriceListWhereUniqueInput>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  jobId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name2?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  priceLists?: InputMaybe<Array<PriceListWhereUniqueInput>>;
  tags?: InputMaybe<TagsInput>;
};

export type SiteMutations = {
  __typename?: 'SiteMutations';
  merge: SiteMutationsMergeResponse;
};


export type SiteMutationsMergeArgs = {
  data: SiteMutationsMergeDataInput;
  where: SiteWhereUniqueInput;
};

export type SiteMutationsMergeDataInput = {
  with: Array<SiteWhereUniqueInput>;
};

export type SiteMutationsMergeResponse = {
  __typename?: 'SiteMutationsMergeResponse';
  deletedIds: Array<Scalars['Int']>;
  site: Site;
};

export type SiteUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  address?: InputMaybe<AddressInput>;
  customerId?: InputMaybe<Scalars['Int']>;
  defaultPriceList?: InputMaybe<PriceListWhereUniqueInput>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  jobId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name2?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  priceLists?: InputMaybe<Array<PriceListWhereUniqueInput>>;
  tags?: InputMaybe<TagsInput>;
};

export type SiteWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum SortOrderInput {
  Asc = 'asc',
  Desc = 'desc'
}

export type Statement = {
  __typename?: 'Statement';
  amountDue?: Maybe<Scalars['Decimal']>;
  batch: StatementBatch;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  customer: Customer;
  date: Scalars['Date'];
  emails: Array<Scalars['String']>;
  id: Scalars['Int'];
  invoices: Array<Invoice>;
  sentAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Deprecated, use `date` */
  statementDate: Scalars['Date'];
  status: StatementStatus;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type StatementBatch = {
  __typename?: 'StatementBatch';
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  id: Scalars['Int'];
  statements: Array<Statement>;
  updatedAt: Scalars['DateTime'];
};


export type StatementBatchStatementsArgs = {
  cursor?: InputMaybe<StatementWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type StatementMutations = {
  __typename?: 'StatementMutations';
  generate?: Maybe<StatementMutationsGenerateResponse>;
  sendEmail?: Maybe<StatementMutationsSendEmailResponse>;
};


export type StatementMutationsGenerateArgs = {
  where: StatementWhereUniqueInput;
};


export type StatementMutationsSendEmailArgs = {
  where: StatementWhereUniqueInput;
};

export type StatementMutationsGenerateResponse = {
  __typename?: 'StatementMutationsGenerateResponse';
  statement: Statement;
};

export type StatementMutationsSendEmailResponse = {
  __typename?: 'StatementMutationsSendEmailResponse';
  statement: Statement;
  success: Scalars['Boolean'];
};

export enum StatementStatus {
  Generated = 'generated',
  Queued = 'queued',
  Sent = 'sent'
}

export type StatementWhereUniqueInput = {
  id: Scalars['Int'];
};

export type StatementsBatchInput = {
  customerIds: Array<Scalars['Int']>;
  date: Scalars['Date'];
};

export type StatementsMutations = {
  __typename?: 'StatementsMutations';
  batch?: Maybe<StatementsMutationsBatchResponse>;
};


export type StatementsMutationsBatchArgs = {
  data: StatementsBatchInput;
};

export type StatementsMutationsBatchResponse = {
  __typename?: 'StatementsMutationsBatchResponse';
  batch: StatementBatch;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Stripe = {
  __typename?: 'Stripe';
  account?: Maybe<StripeAccount>;
};

export type StripeAccount = {
  __typename?: 'StripeAccount';
  id: Scalars['String'];
  publishableKey: Scalars['String'];
};

export type StripeAccountSession = {
  __typename?: 'StripeAccountSession';
  account: StripeAccount;
  clientSecret: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  livemode: Scalars['Boolean'];
};

export type StripeMutations = {
  __typename?: 'StripeMutations';
  createSession?: Maybe<StripeMutationsCreateSessionResponse>;
};

export type StripeMutationsCreateSessionResponse = {
  __typename?: 'StripeMutationsCreateSessionResponse';
  session: StripeAccountSession;
};

export type SubEntity = {
  __typename?: 'SubEntity';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SubRequest = {
  __typename?: 'SubRequest';
  bookingBranch: Branch;
  bookingOrder: Order;
  bookingPerson: Person;
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  fulfillmentBranch: Branch;
  fulfillmentPerson?: Maybe<Person>;
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  proposedChanges?: Maybe<Scalars['Json']>;
  status: SubRequestStatus;
  updatedAt: Scalars['DateTime'];
};

export type SubRequestAcceptDataInput = {
  equipmentId?: InputMaybe<Scalars['Int']>;
};

export type SubRequestCancelAllWhereInput = {
  orderId: Scalars['Int'];
};

export type SubRequestCreateDataInput = {
  orderId: Scalars['Int'];
  sendTo: Array<SubRequestCreateDataSendToInput>;
};

export type SubRequestCreateDataSendToInput = {
  subEntityId: Scalars['String'];
};

export type SubRequestMutations = {
  __typename?: 'SubRequestMutations';
  accept: SubRequestMutationsAcceptResponse;
  /** Cancel all pending subs */
  cancelAll: SubRequestMutationsCancelAllResponse;
  create: SubRequestMutationsCreateResponse;
  decline: SubRequestMutationsDeclineResponse;
  /** Recover a previously subbed order */
  withdraw: SubRequestMutationsWithdrawResponse;
};


export type SubRequestMutationsAcceptArgs = {
  data?: InputMaybe<SubRequestAcceptDataInput>;
  where: SubRequestWhereUniqueInput;
};


export type SubRequestMutationsCancelAllArgs = {
  where: SubRequestCancelAllWhereInput;
};


export type SubRequestMutationsCreateArgs = {
  data: SubRequestCreateDataInput;
};


export type SubRequestMutationsDeclineArgs = {
  where: SubRequestWhereUniqueInput;
};


export type SubRequestMutationsWithdrawArgs = {
  where: SubRequestWhereUniqueInput;
};

export type SubRequestMutationsAcceptResponse = {
  __typename?: 'SubRequestMutationsAcceptResponse';
  subRequest: SubRequest;
};

export type SubRequestMutationsCancelAllResponse = {
  __typename?: 'SubRequestMutationsCancelAllResponse';
  subRequests: Array<SubRequest>;
};

export type SubRequestMutationsCreateResponse = {
  __typename?: 'SubRequestMutationsCreateResponse';
  subRequests: Array<SubRequest>;
};

export type SubRequestMutationsDeclineResponse = {
  __typename?: 'SubRequestMutationsDeclineResponse';
  subRequest: SubRequest;
};

export type SubRequestMutationsWithdrawResponse = {
  __typename?: 'SubRequestMutationsWithdrawResponse';
  subRequest: SubRequest;
};

export enum SubRequestStatus {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  ChangesRequested = 'changes_requested',
  Declined = 'declined',
  Pending = 'pending',
  Withdrawn = 'withdrawn'
}

export type SubRequestWhereUniqueInput = {
  id: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  currentPeriodEnd?: Maybe<Scalars['DateTime']>;
  currentPeriodStart?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  link: Scalars['String'];
  name: Scalars['String'];
  status: SubscriptionStatus;
  trialEnd?: Maybe<Scalars['DateTime']>;
};

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

export type Supplier = {
  __typename?: 'Supplier';
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  name2?: Maybe<Scalars['String']>;
  phones: Array<Phone>;
  updatedAt: Scalars['DateTime'];
};

export type SupplierInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  metadata?: InputMaybe<KeyValuesInput>;
  name: Scalars['String'];
  name2?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<PhoneInput>>;
};

export type SupplierUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  metadata?: InputMaybe<KeyValuesInput>;
  name?: InputMaybe<Scalars['String']>;
  name2?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<PhoneInput>>;
};

export type SupplierWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Tag = {
  __typename?: 'Tag';
  active: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  path: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type TagDataUpdateInput = {
  active: Scalars['Boolean'];
  color?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  path: Scalars['String'];
  slug: Scalars['String'];
  sort: Scalars['Int'];
};

export type TagMutations = {
  __typename?: 'TagMutations';
  create: TagMutationsCreateResponse;
  update: TagMutationsUpdateResponse;
};


export type TagMutationsCreateArgs = {
  data: TagDataUpdateInput;
};


export type TagMutationsUpdateArgs = {
  data: TagDataUpdateInput;
  where: TagWhereUpdateUniqueInput;
};

export type TagMutationsCreateResponse = {
  __typename?: 'TagMutationsCreateResponse';
  tag: Tag;
};

export type TagMutationsUpdateResponse = {
  __typename?: 'TagMutationsUpdateResponse';
  tag: Tag;
};

export type TagWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type TagWhereUpdateUniqueInput = {
  id: Scalars['Int'];
};

export type TagsInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  disconnect?: InputMaybe<Array<TagWhereUniqueInput>>;
};

export type TeamMemberAvailability = {
  __typename?: 'TeamMemberAvailability';
  assignments: Array<Assignment>;
  availabilities: Array<PersonAvailability>;
  id: Scalars['String'];
  person: PersonInterface;
};

export type TelematicsGps = {
  __typename?: 'TelematicsGPS';
  enabled: Scalars['Boolean'];
};

export type TelematicsStats = {
  __typename?: 'TelematicsStats';
  enabled: Scalars['Boolean'];
};

export type Terms = {
  __typename?: 'Terms';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validTo?: Maybe<Scalars['DateTime']>;
};

export type TermsUpdateInput = {
  body: Scalars['String'];
  title: Scalars['String'];
};

export type TimeClock = {
  __typename?: 'TimeClock';
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
  hours?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  shift: TimeClockShift;
  startTime: Scalars['DateTime'];
  task?: Maybe<TimeClockTask>;
  updatedAt: Scalars['DateTime'];
};

export type TimeClockIsNotRequiredWhereInput = {
  asOf?: InputMaybe<Scalars['DateTime']>;
  personId?: InputMaybe<Scalars['Int']>;
};

export type TimeClockMutations = {
  __typename?: 'TimeClockMutations';
  changeTask?: Maybe<TimeClockMutationsChangeTaskResponse>;
  clockIn?: Maybe<TimeClockMutationsClockInResponse>;
  clockOut?: Maybe<TimeClockMutationsClockOutResponse>;
  update: TimeClockMutationsUpdateResponse;
};


export type TimeClockMutationsChangeTaskArgs = {
  data: TimesheetChangeTaskInput;
};


export type TimeClockMutationsClockInArgs = {
  data?: InputMaybe<TimesheetClockInInput>;
};


export type TimeClockMutationsClockOutArgs = {
  data?: InputMaybe<TimesheetClockOutInput>;
};


export type TimeClockMutationsUpdateArgs = {
  data: TimeClockUpdateInput;
  where: TimeClockWhereUniqueInput;
};

export type TimeClockMutationsChangeTaskResponse = {
  __typename?: 'TimeClockMutationsChangeTaskResponse';
  timeClock: TimeClock;
};

export type TimeClockMutationsClockInResponse = {
  __typename?: 'TimeClockMutationsClockInResponse';
  timeClock: TimeClock;
};

export type TimeClockMutationsClockOutResponse = {
  __typename?: 'TimeClockMutationsClockOutResponse';
  timeClock: TimeClock;
};

export type TimeClockMutationsUpdateResponse = {
  __typename?: 'TimeClockMutationsUpdateResponse';
  timeClock: TimeClock;
};

export type TimeClockShift = {
  __typename?: 'TimeClockShift';
  comments: Array<Comment>;
  createdAt: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  startTime: Scalars['DateTime'];
  timeClocks: Array<TimeClock>;
  updatedAt: Scalars['DateTime'];
};

export type TimeClockShiftMutations = {
  __typename?: 'TimeClockShiftMutations';
  upsert: TimeClockShiftMutationsUpsertResponse;
};


export type TimeClockShiftMutationsUpsertArgs = {
  data: TimeClockShiftUpdateInput;
  where?: InputMaybe<TimeClockShiftWhereUniqueInput>;
};

export type TimeClockShiftMutationsUpsertResponse = {
  __typename?: 'TimeClockShiftMutationsUpsertResponse';
  shift: TimeClockShift;
};

export type TimeClockShiftUpdateInput = {
  comments?: InputMaybe<CommentsInput>;
  person?: InputMaybe<PersonWhereUniqueInput>;
  timeClocks?: InputMaybe<TimeClocksInput>;
};

export type TimeClockShiftWhereUniqueInput = {
  id: Scalars['Int'];
};

export type TimeClockTask = {
  __typename?: 'TimeClockTask';
  active: Scalars['Boolean'];
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  isNoteRequired: Scalars['Boolean'];
  name: Scalars['String'];
  noteRequired?: Maybe<TimeClockTaskNoteRequired>;
  payable: Scalars['Boolean'];
  sort: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type TimeClockTaskIsNoteRequiredArgs = {
  where?: InputMaybe<TimeClockIsNotRequiredWhereInput>;
};

export enum TimeClockTaskNoteRequired {
  IfNoGeolocation = 'ifNoGeolocation',
  IfOffAssignment = 'ifOffAssignment',
  Required = 'required'
}

export enum TimeClockTaskScope {
  Operator = 'operator',
  User = 'user'
}

export type TimeClockTaskWhereUniqueInput = {
  id: Scalars['Int'];
};

export type TimeClockUpdateInput = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  note?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  task?: InputMaybe<TimeClockTaskWhereUniqueInput>;
};

export type TimeClockUpsertInput = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  task?: InputMaybe<TimeClockTaskWhereUniqueInput>;
};

export type TimeClockWhereUniqueInput = {
  id: Scalars['Int'];
};

export type TimeClocksInput = {
  set?: InputMaybe<Array<TimeClockUpsertInput>>;
};

export type TimeSheet = {
  __typename?: 'TimeSheet';
  endTime: Scalars['DateTime'];
  id: Scalars['String'];
  person: PersonInterface;
  shifts: Array<TimeClockShift>;
  startTime: Scalars['DateTime'];
  timeClocks: Array<TimeClock>;
};

export type TimeSheetsWhereInput = {
  endTime: Scalars['DateTime'];
  personId?: InputMaybe<IntFilter>;
  startTime: Scalars['DateTime'];
};

export type TimesheetChangeTaskInput = {
  note?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<PersonWhereUniqueInput>;
  task: TimeClockTaskWhereUniqueInput;
};

export type TimesheetClockInInput = {
  note?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<PersonWhereUniqueInput>;
  task?: InputMaybe<TimeClockTaskWhereUniqueInput>;
};

export type TimesheetClockOutInput = {
  note?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<PersonWhereUniqueInput>;
};

export type TransactionHistory = {
  __typename?: 'TransactionHistory';
  amount: Scalars['Decimal'];
  currency: Scalars['String'];
  transactionDate: Scalars['DateTime'];
  transactionType: Scalars['String'];
};

export type TravelEstimate = {
  __typename?: 'TravelEstimate';
  distance?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  fromAddressId?: Maybe<Scalars['Int']>;
  toAddressId?: Maybe<Scalars['Int']>;
};

export type UpdateTagsRelationsDataInput = {
  contacts?: InputMaybe<Array<ContactWhereUniqueInput>>;
  customers?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  invoices?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  orders?: InputMaybe<Array<OrderWhereUniqueInput>>;
  priceLists?: InputMaybe<Array<PriceListWhereUniqueInput>>;
  sites?: InputMaybe<Array<SiteWhereUniqueInput>>;
};

export type UpdateTagsRelationsResponse = {
  __typename?: 'UpdateTagsRelationsResponse';
  contacts?: Maybe<Array<Contact>>;
  customers?: Maybe<Array<Customer>>;
  invoices?: Maybe<Array<Invoice>>;
  orders?: Maybe<Array<Order>>;
  priceLists?: Maybe<Array<PriceList>>;
  sites?: Maybe<Array<Site>>;
};

export type UploadTypesWhereInput = {
  acl?: InputMaybe<VisibilityEnum>;
  customKeyName?: InputMaybe<Scalars['String']>;
};

export type UseCreditBalanceResponse = {
  __typename?: 'UseCreditBalanceResponse';
  invoices: Array<Invoice>;
};

export type User = PersonInterface & {
  __typename?: 'User';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  emails: Array<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAdmin: Scalars['Boolean'];
  isOperator?: Maybe<Scalars['Boolean']>;
  isSupport: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  legacyRoles?: Maybe<Array<UserRoleLegacy>>;
  messages: Array<Message>;
  operator?: Maybe<Operator>;
  organization: Organization;
  phones: Array<Phone>;
  preferences: UserPreferences;
  roles: Array<UserRole>;
  timeClock?: Maybe<TimeClock>;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type UserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  credentials?: InputMaybe<CredentialsInput>;
  firstName?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isOperator?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyRoles?: InputMaybe<Array<UserRoleLegacy>>;
  phones?: InputMaybe<Array<PhoneInput>>;
  roles?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  username: Scalars['String'];
};

export type UserPermission = {
  __typename?: 'UserPermission';
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  messages: Array<MessagePreference>;
};

export type UserRole = {
  __typename?: 'UserRole';
  active: Scalars['Boolean'];
  changelog?: Maybe<Changelog>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<UserPermission>;
  updatedAt: Scalars['DateTime'];
};

export type UserRoleCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  permissionIds?: InputMaybe<Array<Scalars['String']>>;
};

export enum UserRoleLegacy {
  Admin = 'admin',
  Readonly = 'readonly'
}

export type UserRoleMutations = {
  __typename?: 'UserRoleMutations';
  create: UserRoleMutationsCreateResponse;
  update: UserRoleMutationsUpdateResponse;
};


export type UserRoleMutationsCreateArgs = {
  data: UserRoleCreateInput;
};


export type UserRoleMutationsUpdateArgs = {
  data: UserRoleUpdateInput;
  where: UserRoleWhereUniqueInput;
};

export type UserRoleMutationsCreateResponse = {
  __typename?: 'UserRoleMutationsCreateResponse';
  userRole: UserRole;
};

export type UserRoleMutationsUpdateResponse = {
  __typename?: 'UserRoleMutationsUpdateResponse';
  userRole: UserRole;
};

export type UserRoleUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissionIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UserRoleWhereUniqueInput = {
  id: Scalars['Int'];
};

export type UserUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  credentials?: InputMaybe<CredentialsInput>;
  firstName?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isOperator?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyRoles?: InputMaybe<Array<UserRoleLegacy>>;
  phones?: InputMaybe<Array<PhoneInput>>;
  roles?: InputMaybe<Array<UserRoleWhereUniqueInput>>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum VisibilityEnum {
  Private = 'private',
  PublicRead = 'publicRead'
}

export type WaypointsFitCheck = {
  __typename?: 'WaypointsFitCheck';
  results?: Maybe<Array<WaypointsFitCheckResult>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type WaypointsFitCheckResult = {
  __typename?: 'WaypointsFitCheckResult';
  incomingTravelDuration?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  invalidCode?: Maybe<Scalars['String']>;
  invalidDuration?: Maybe<Scalars['Int']>;
  invalidReason?: Maybe<Scalars['String']>;
  outgoingTravelDuration?: Maybe<Scalars['Int']>;
};

export type Yard = {
  __typename?: 'Yard';
  active: Scalars['Boolean'];
  additionalIds?: Maybe<Array<KeyValue>>;
  address: Address;
  branch: Branch;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  metadata?: Maybe<Array<KeyValue>>;
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type YardCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  address: NavigableAddressInput;
  metadata?: InputMaybe<KeyValuesInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type YardUpdateArgs = {
  data: YardUpdateInput;
  where: YardWhereUniqueInput;
};

export type YardUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additionalIds?: InputMaybe<KeyValuesInput>;
  address?: InputMaybe<AddressInput>;
  metadata?: InputMaybe<KeyValuesInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type YardWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type YardsInput = {
  create?: InputMaybe<Array<YardCreateInput>>;
  update?: InputMaybe<Array<YardUpdateArgs>>;
};
