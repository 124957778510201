import React from 'react'
import { SecondaryButton, ButtonIcon } from 'components/basic/Button'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { PhoneNumber } from 'components/contact/PhoneButton'
import { formatPhone } from 'helpers/formatPhone'
import { useFeature } from 'hooks/useFeatures'

export type SmsButtonProps = {
  phone: string
  message?: string
}

export const SmsButton: React.FC<SmsButtonProps>  = ({ phone, message }) => {
  const [hideSMS] = useFeature('support.hideSMS')
  if (hideSMS) return null

  const withBody = message ? `;?&body=${encodeURI(message)}` : ''

  return <a href={ `sms:${phone}${withBody}` } style={{ textDecoration: 'none' }}>
    <SecondaryButton><ButtonIcon icon={ faComment } />Text us <PhoneNumber> {formatPhone(phone)} </PhoneNumber></SecondaryButton>
  </a>
}
