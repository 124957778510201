import { Row, Col } from 'reactstrap'
import { PhoneButton } from 'components/contact/PhoneButton'
import { SmsButton } from 'components/contact/SmsButton'
import styled from 'styled-components'

const AutoHideCol = styled(Col)`
  &:empty {
    display: none;
  }
`

export const ContactButtons = (props: { phone: string, message?: string }) => (
  <Row>
    <AutoHideCol>
      <SmsButton {...props} />
    </AutoHideCol>
    <AutoHideCol>
      <PhoneButton {...props} />
    </AutoHideCol>
  </Row>
)
