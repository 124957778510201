import React from 'react'
import styled from 'styled-components'
import { SecondaryButton, ButtonIcon } from 'components/basic/Button'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { formatPhone } from 'helpers/formatPhone'

export const PhoneNumber = styled.span`
  display: none;
  font-size: 12px;
  font-weight: 500;
  margin-top: 3px;
`

export const PhoneButton = ({ phone }: { phone: string }) => {
  return <a href={`tel:${phone}`} style={{ textDecoration: 'none' }}>
    <SecondaryButton><ButtonIcon icon={ faPhone } />Call us <PhoneNumber> {formatPhone(phone)} </PhoneNumber></SecondaryButton>
  </a>
}
