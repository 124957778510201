import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './assets/app.css'
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <App />,
  document.querySelector('#root'),
)
