import styled from 'styled-components'
import { Button as RSButton } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Button = styled(RSButton)`
  display: block;
  width: 100%;
  font-family: Nunito Sans;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.17px;
  margin-bottom: 0;
  font-weight: 600;
  border-radius: 10px;
  padding: 18px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  border-style: none;
  text-decoration: none;
`

export const PrimaryButton = styled(Button)`
  background-color: #168a15;
`
export const SecondaryButton = styled(Button)`
  background-color: #153f8a;
`
export const TertiaryButton = styled(Button)`
  background-color: #da6806;
`
export const DangerButton = styled(Button)`
  background-color: #a41d36;
`

export const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`

export default Button
