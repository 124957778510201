import { organizationsHideOperatorInfo } from '../constants/organizationDefaults';

export const shouldShowOperatorInfo = (organizationId: number): boolean =>
{
  if (organizationId in organizationsHideOperatorInfo)
  {
    return !organizationsHideOperatorInfo[organizationId]
  }
return true
}
