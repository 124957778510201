import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import { Header } from 'components/Header'
import { LoadingProvider } from 'contexts/Loading'
import { ToastContainer, Slide as ToastTransitionSlide } from 'components/Toast'
import {
  BrowserRouter as Router,
  useLocation
} from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { client } from 'helpers/apollo'
import { Routes } from 'routes'
import { SessionProvider } from 'hooks/useSession'
import { Theme } from 'components/Theme'
import { getDeviceId } from 'helpers/getDeviceId'

const ScrollToTop = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return null
}

export default function App() {
  getDeviceId()

  return (
    <LoadingProvider>
      <ApolloProvider client={client}>
        <SessionProvider>
          <Theme>
            <ToastContainer position="top-center" transition={ ToastTransitionSlide } />
            <Router>
              <ScrollToTop />
              <Container>
                <Header />
                <Routes />
              </Container>
            </Router>
          </Theme>
        </SessionProvider>
      </ApolloProvider>
    </LoadingProvider>
  )
}
