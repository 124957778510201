import React, { useLayoutEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { useLoading } from 'contexts/Loading'
import { useQuery, useMutation, gql } from '@apollo/client'
import { StandardLayout } from 'components/StandardLayout'
import { useErrors } from 'hooks/useErrors'
import { Card } from 'components/basic/Card'
import { ContactButtons } from 'components/contact/ContactButtons'
import { PrimaryButton, ButtonIcon } from 'components/basic/Button'
import { OrderWhereUniqueInput, Query, Mutation, MutationRequestOrderChangeArgs } from 'schema'
import {
  FormGroup,
  Input,
  Row,
  Col
} from 'reactstrap'
import { Link, Redirect } from 'hooks/useStaticRouter'
import { OrderInfoSection, ORDER_QUERY } from 'pages/OrderPage'
import * as analytics from 'helpers/analytics'
import { useIsOrderChangeable } from 'hooks/useIsOrderChangeable'

const Title = styled.h1`
  font-family: Nunito Sans;
  font-size: 16px;
  color: #010101;
  letter-spacing: 0.46px;
  line-height: 1.4;
`

const ChangeRequestField = styled(Input)`
  font-family: Nunito Sans;
  font-size: 16px;
  color: #010101;
  letter-spacing: 0.46px;
  line-height: 1.4;
  min-height: 200px;
  margin-top: 20px;
`

const HorizontalRule = styled(Col)`
  border-bottom: 1px solid #989898;
  transform: translateY(-50%);
`

const GoBackLink = styled(Link)`
  font-family: Nunito Sans;
  font-size: 12px;
  text-align: center;
  display: block;
  margin-top: 20px;
  padding: 5px;
`

const Item = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;
`

const ItemTitle = styled.p`
  font-family: Nunito Sans;
  font-size: 14px;
  color: var(--vulcan-primary-color);
  letter-spacing: 0.17px;
  padding-bottom: 4px;
  margin-bottom: 0;
  font-weight: 600;
`

const ItemSubtitle = styled.p`
  font-family: Nunito Sans;
  font-size: 16px;
  color: #010101;
  letter-spacing: 0.22px;
  padding-bottom: 0;
  margin-bottom: 0;
  font-weight: 500;
`

const OrDivier = styled(({ className }) => (
  <Row className={className}>
    <HorizontalRule />
    <Col xs="auto"> OR </Col>
    <HorizontalRule />
  </Row>
))`
  width: 100%;
  padding: 0 5px;
  margin: 30px 0 10px 0;
  font-family: Nunito Sans;
  font-size: 16px;
`

type OrderChangeRequestPageProps = {
  orderId: string
}

const REQUEST_ORDER_CHANGE = gql`
  mutation RequestOrderChange($data: RequestOrderChangeInput!, $where: OrderWhereUniqueInput!) {
    requestOrderChange(data: $data, where: $where) {
      success
    }
  }
`

export const OrderChangeRequestPage = (props: OrderChangeRequestPageProps) => {
  return <StandardLayout>
    <OrderChangeRequestPageWrapped {...props} />
  </StandardLayout>
}

const OrderChangeRequestPageWrapped = ({ orderId }: OrderChangeRequestPageProps): JSX.Element | null => {
  const pageTopRef = useRef<HTMLDivElement>(null)
  const { withLoading } = useLoading()
  const { showError } = useErrors()
  const textAreaRef = React.createRef<HTMLTextAreaElement>()
  const [requestOrderChange] = useMutation<Mutation, MutationRequestOrderChangeArgs>(REQUEST_ORDER_CHANGE)
  const [showRequestReceived, setShowRequestReceived] = useState(false)
  const showRequestForm = !showRequestReceived

  useLayoutEffect(() => {
    pageTopRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }, [])

  const { loading, error, data } = useQuery<Query, OrderWhereUniqueInput>(ORDER_QUERY, {
    variables: {
      id: parseInt(orderId, 10)
    }
  })

  if (loading) {
    return <Card>Loading...</Card>
  }

  if (error) {
    console.log(error.message)
    return <Card>Sorry, an error occurred. Please refresh this page and try submitting your change request again.</Card>
  }

  const order = data?.order

  const isOrderChangeable = useIsOrderChangeable(order?.status)

  if (!order) {
    return <Card>Order not found</Card>
  }

  const planned = order?.planned

  if (!planned) {
    return <Card>Order not found</Card>
  }

  if (!isOrderChangeable) {
    return <Redirect to={`/orders/${orderId}`} />
  }

  const requestChange = withLoading(async () => {
    analytics.trackClick({ name: 'RequestChange' })

    const change = textAreaRef?.current?.value
    if (!change) {
      showError(`Please input your requested change below.`)
      return
    }

    try {
      await requestOrderChange({
        variables: {
          data: {
            change,
          },
          where: {
            id: order.id
          }
        }
      })
      setShowRequestReceived(true)
    } catch (err) {
      showError(`An error occured, please try again: ${err.message}`)
    }
  })

  const mainlinePhone = order?.branch?.mainlinePhone

  const contactJSX = mainlinePhone ?
    <ContactButtons phone={mainlinePhone} message={order?.name ? `Change request for ${order.name}:\n\n` : undefined} />
    : null

  return <>
    <div ref={pageTopRef} />
    <StandardLayout>
      <Card>
        {showRequestForm && <>
          <Title>Please detail the requested changes for <strong>{order?.name}</strong> below and one of our team members will work to confirm your request.</Title>
          <OrderInfoSection order={order} />
          <FormGroup>
            <ChangeRequestField innerRef={textAreaRef} type="textarea" name="text" id="requestChange" placeholder="Input description of requested changes here" />
          </FormGroup>
          <PrimaryButton onClick={requestChange}><ButtonIcon icon={faEdit} />Submit Change Request</PrimaryButton>
        </>}
        {showRequestReceived && <>
          Your change request for <strong>{order?.name}</strong> has been submitted. One of our team members will contact you shortly to confirm your change.
        </>}
        <GoBackLink to={`/orders/${orderId}`}>
          Return to order confirmation
        </GoBackLink>
      </Card>
      <OrDivier />
      {contactJSX}
    </StandardLayout>
  </>
}
